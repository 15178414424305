import { useEffect, useRef, useState } from "react";
import { tv } from "tailwind-variants";

const styles = tv({
  base: "absolute left-0 top-0 z-20 h-1 bg-emerald-600 transition-[width,opacity] ease-out",
  variants: {
    status: {
      none: "invisible w-0 opacity-100 duration-[0s]",
      loading: "w-[90%] opacity-100 duration-[10s]",
      loaded:
        "w-full opacity-0 [transition:width_300ms_ease-out,_opacity_200ms_ease-in_300ms]",
    },
  },
});

export const LoadingBar = ({
  loading,
  className,
  as = "div",
}: {
  loading: boolean;
  className?: string;
  as?: keyof JSX.IntrinsicElements;
}) => {
  const Component = as;
  const [status, setStatus] = useState<"none" | "loading" | "loaded">(
    loading ? "loading" : "none"
  );
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!loading && status === "loading") {
      setStatus("loaded");
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // 500ms = 300ms for the width transform + 200ms for the fade out
      timeoutRef.current = setTimeout(() => setStatus("none"), 500);
    } else if (loading) {
      setStatus("loading");
    }
  }, [loading, status]);

  return <Component className={styles({ status, className })} />;
};
