import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import cn from "classnames";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import { tv } from "tailwind-variants";

export type NavDropdownProps = {
  label: string;
  enabled: boolean;
  expand?: boolean;
} & ComponentPropsWithoutRef<"button">;

const variants = tv({
  slots: {
    base: "flex h-full min-w-[80px] grow cursor-pointer items-center justify-between border-r border-anvil-100 pl-2",
  },
});

export const NavDropdown = forwardRef<HTMLButtonElement, NavDropdownProps>(
  (props, ref) => {
    const { label, enabled, expand, className, ...buttonProps } = props;
    const styles = variants();

    return (
      <button
        className={styles.base({ class: className })}
        ref={ref}
        {...buttonProps}
      >
        <span className="truncate">{label}</span>
        <div className="flex w-7 items-center justify-center">
          <Icon
            path={mdiChevronDown}
            className={`h-5 w-5 ${enabled ? "rotate-180" : ""}`}
          />
        </div>
      </button>
    );
  }
);
NavDropdown.displayName = "NavDropdown";
