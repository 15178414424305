import { ComponentPropsWithRef, Ref, forwardRef } from "react";
import { tv } from "tailwind-variants";

export type ButtonProps = {
  size?: "sm" | "md" | "lg";
  variant?: "primary" | "neutral" | "outline" | "subtle" | "danger";
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  iconButton?: boolean;
} & ComponentPropsWithRef<"button">;

const styles = tv({
  base:
    "inline-flex items-center justify-center rounded border px-6 py-2" +
    " focus:outline-1 focus:outline-offset-4 focus:outline-blue-700" +
    " disabled:pointer-events-none disabled:border-transparent disabled:bg-carbon-200 disabled:text-carbon-400",
  variants: {
    size: {
      sm: "h-6 py-1 text-xs",
      md: "h-9 text-sm",
      lg: "h-10 text-base",
    },
    variant: {
      primary:
        "border-transparent bg-carbon-800 text-carbon-50" +
        " hover:bg-carbon-700" +
        " focus-visible:bg-carbon-700" +
        " active:bg-carbon-900" +
        " data-[state=open]:bg-carbon-900",
      neutral:
        "border-transparent bg-carbon-100 text-carbon-900" +
        " hover:bg-carbon-200" +
        " focus-visible:bg-carbon-200" +
        " active:bg-carbon-300" +
        " data-[state=open]:bg-carbon-300",
      outline:
        "border border-carbon-800 bg-carbon-100 text-carbon-800" +
        " hover:border-carbon-800 hover:bg-carbon-200" +
        " focus-visible:border-carbon-800 focus-visible:bg-carbon-200" +
        " active:border-carbon-800 active:bg-carbon-100" +
        " data-[state=open]:border-carbon-800 data-[state=open]:bg-carbon-100" +
        " disabled:border-carbon-200 disabled:bg-transparent disabled:text-carbon-400",
      subtle:
        "border border-transparent text-carbon-500" +
        " hover:bg-carbon-200" +
        " focus-visible:bg-carbon-200" +
        " active:bg-transparent" +
        " data-[state=open]:bg-transparent" +
        " disabled:bg-transparent disabled:text-carbon-400",
      danger:
        "border border-transparent text-forge-500" +
        " hover:bg-red-50" +
        " focus-visible:bg-red-50" +
        " active:bg-transparent" +
        " data-[state=open]:bg-transparent" +
        " disabled:bg-transparent disabled:text-carbon-400",
    },
    hasStartDecorator: {
      true: "pl-4",
    },
    hasEndDecorator: {
      true: "pr-4",
    },
    iconButton: {
      true: "",
    },
  },
  compoundVariants: [
    {
      iconButton: true,
      size: "sm",
      class: "p-1",
    },
    {
      iconButton: true,
      size: "md",
      class: "p-2",
    },
    {
      iconButton: true,
      size: "lg",
      class: "p-2",
    },
  ],
});

export const Button = forwardRef(function Button(
  {
    size = "md",
    variant = "primary",
    startDecorator,
    endDecorator,
    children,
    className,
    iconButton,
    ...buttonProps
  }: ButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      className={styles({
        size,
        variant,
        iconButton,
        hasStartDecorator: !!startDecorator,
        hasEndDecorator: !!endDecorator,
        className,
      })}
      {...buttonProps}
    >
      {startDecorator ? (
        <span className="mr-2 [display:inherit]">{startDecorator}</span>
      ) : null}

      {children}

      {endDecorator ? (
        <span className="ml-2 [display:inherit]">{endDecorator}</span>
      ) : null}
    </button>
  );
});
