import { useEffect, useState } from "react";

/**
 * The useDebounce hook is useful for delaying the execution of functions or state updates until a specified time period has passed without any further changes to the input value.
 * This is especially useful in scenarios such as handling user input or triggering network requests, where it effectively reduces unnecessary computations and ensures that resource-intensive operations are only performed after a pause in the input activity.
 * @param value The value that you want to debounce. This can be of any type.
 * @param delay The delay time in milliseconds. After this amount of time, the latest value is used.
 */
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
