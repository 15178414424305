import { Tooltip } from "$components/shared/Tooltip";
import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import cn from "classnames";
import { ComponentPropsWithoutRef, forwardRef, useRef, useState } from "react";
import { useLayoutEffect } from "react";
import { tv } from "tailwind-variants";
import useResizeObserver from "use-resize-observer";
import { portalContainer } from "./portalContainer";

export type NavDropdownProps = {
  label: string;
  enabled: boolean;
  expand?: boolean;
} & ComponentPropsWithoutRef<"button">;

const variants = tv({
  slots: {
    base: "flex h-full min-w-[80px] grow cursor-pointer items-center justify-between border-r border-anvil-100 pl-2",
  },
});

export const NavDropdown = forwardRef<HTMLButtonElement, NavDropdownProps>(
  (props, ref) => {
    const spanRef = useRef<HTMLTableCellElement>(null);
    const { label, enabled, expand, className, ...buttonProps } = props;
    const styles = variants();
    const [isTruncated, setIsTruncated] = useState(false);
    const { width } = useResizeObserver({ ref: spanRef });
    useLayoutEffect(() => {
      if (spanRef.current) {
        setIsTruncated(
          spanRef.current.scrollWidth > spanRef.current.clientWidth
        );
      }
    }, [width]);

    return (
      <Tooltip
        size="sm"
        title={isTruncated ? label : null}
        portalProps={{ container: portalContainer() }}
      >
        <button
          className={styles.base({ class: className })}
          ref={ref}
          {...buttonProps}
        >
          <span className="truncate" ref={spanRef}>
            {label}
          </span>
          <div className="flex w-7 items-center justify-center">
            <Icon
              path={mdiChevronDown}
              className={`h-5 w-5 ${enabled ? "rotate-180" : ""}`}
            />
          </div>
        </button>
      </Tooltip>
    );
  }
);
NavDropdown.displayName = "NavDropdown";
