import { DEFAULT_STREAM_COURSE_TYPE } from "$components/features/nav/NavBar/NavCourseNavigation/CourseLevelNavigation";
import { portalContainer } from "$components/features/nav/NavBar/portalContainer";
import { SwitchIcon } from "$components/shared/Icons/SwitchIcon";
import { Tooltip } from "$components/shared/Tooltip";
import { CourseNavigationItem } from "$hooks/useCourseNavigation";
import { api } from "$lib/api";
import { Course } from "@prisma/client";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import cn from "classnames";

export type NavCourseVersionSwitchProps = {
  item: CourseNavigationItem;
  open: boolean;
  onOpenChange?: () => void;
  alternateCourseId: number | null;
  setAlternateCourse?: (
    courseId: CourseNavigationItem & Pick<Course, "region_id" | "course_type">
  ) => void;
};

export const NavCourseVersionSwitch = ({
  alternateCourseId,
  item,
  open,
  onOpenChange,
  setAlternateCourse,
}: NavCourseVersionSwitchProps) => {
  const hasOtherVersions =
    item.type === "course" &&
    Array.isArray(item.subItems) &&
    item.subItems.length > 0;
  const otherVersions = item.type === "course" ? item.subItems ?? [] : [];
  const updatedItemsList = (() => {
    const alternateCourse = otherVersions.find(
      (v) => v.id === alternateCourseId
    );

    if (!alternateCourse) {
      return otherVersions;
    }

    return otherVersions.filter((v) => v.id !== alternateCourseId);
  })();

  const { data, isInitialLoading } = api.course.getCourseSources.useQuery(
    {
      courseIds: otherVersions
        .filter((v) => v.course_type !== DEFAULT_STREAM_COURSE_TYPE)
        .map((v) => v.id),
    },
    {
      enabled: open && hasOtherVersions && otherVersions.length > 0,
      staleTime: Infinity,
    }
  );

  if (!hasOtherVersions) {
    return null;
  }

  return (
    <DropdownMenu.Root open={open} onOpenChange={onOpenChange}>
      <Tooltip
        title={"Switch to another version"}
        size="sm"
        contentProps={{ side: "top" }}
        portalProps={{
          container: portalContainer(),
        }}
      >
        <DropdownMenu.Trigger
          onSelect={onOpenChange}
          onClick={onOpenChange}
          className={cn(
            "flex h-full w-6 items-center justify-center hover:text-black",
            open && "text-black"
          )}
        >
          <SwitchIcon width="14" height="14" />
        </DropdownMenu.Trigger>
      </Tooltip>

      <DropdownMenu.Portal container={portalContainer()}>
        <DropdownMenu.Content
          onEscapeKeyDown={onOpenChange}
          className={`nav-item-actions open z-40 flex flex-col whitespace-nowrap bg-white text-black shadow`}
          align="start"
          loop
          sticky="always"
          style={{
            maxHeight: "var(--radix-dropdown-menu-content-available-height)",
            overflowY: "auto",
          }}
        >
          {updatedItemsList.map((version) => {
            let subTitle = isInitialLoading ? "..." : data?.[version.id] ?? "";
            if (version.course_type === DEFAULT_STREAM_COURSE_TYPE) {
              subTitle = "Default Stream Course";
            }

            return (
              <DropdownMenu.Item
                key={version.id}
                className="cursor-pointer py-2 px-6 text-left text-sm focus-within:bg-carbon-100 hover:bg-carbon-100"
                onSelect={() => setAlternateCourse?.(version)}
              >
                {version.name}
                {subTitle && (
                  <p className="text-xs italic text-carbon-600">{subTitle}</p>
                )}
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
