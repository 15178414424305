import { CourseNavigationItem } from "$hooks/useCourseNavigation";
import {
  UseComboboxProps,
  UseComboboxStateChange,
  useCombobox,
} from "downshift";

export const comboboxStateReducerWithShallowCopy: UseComboboxProps<any>["stateReducer"] =
  (state, { type, changes }) => {
    switch (type) {
      // When selecting the same item, we need to change its reference (shallow copy) so
      // "onStateChange" callback gets called with the selectedItem object
      case useCombobox.stateChangeTypes.ItemClick:
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
        return {
          ...changes,
          selectedItem: changes.selectedItem
            ? { ...changes.selectedItem }
            : null,
          isOpen: true,
          inputValue: "",
        };
      case useCombobox.stateChangeTypes.InputKeyDownEscape: {
        return {
          highlightedIndex: -1,
          isOpen: false,
          inputValue: "",
          selectedItem: null,
        };
      }
      case useCombobox.stateChangeTypes.InputBlur:
        return state;

      case useCombobox.stateChangeTypes.InputChange:
        return changes;

      default:
        return { ...changes, isOpen: true };
    }
  };

export function onNavItemHighlightedIndexChanged<
  T extends CourseNavigationItem,
>(
  changes: UseComboboxStateChange<T>,
  setHighlightedIndex: (index: number) => void,
  otherOptionsOpenId: number | null,
  otherVersionsOpenId?: number | null
) {
  if (
    typeof changes.highlightedIndex === "number" &&
    otherOptionsOpenId === null &&
    (otherVersionsOpenId === null || otherVersionsOpenId === undefined)
  ) {
    setHighlightedIndex(changes.highlightedIndex);
  }
}
