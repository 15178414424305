import { EllipsisIcon } from "$components/shared/Icons/EllipsisIcon";
import { Tooltip } from "$components/shared/Tooltip";
import { ComponentProps, forwardRef } from "react";

export const OtherOptionsButton = forwardRef<
  HTMLButtonElement,
  Pick<ComponentProps<"button">, "onClick">
>(({ onClick }, ref) => (
  <Tooltip title="More options" size="sm" contentProps={{ side: "top" }}>
    <button
      type="button"
      className={"flex h-full w-6 items-center justify-center hover:text-black"}
      onClick={onClick}
      ref={ref}
    >
      <EllipsisIcon width="14" height="14" />
    </button>
  </Tooltip>
));
OtherOptionsButton.displayName = "OtherOptionsButton";
