import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement> & { orientation?: "vertical" | "horizontal" },
  ref: Ref<SVGSVGElement>
) => {
  const orientation = props.orientation ?? "vertical";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 512"
      width={24}
      height={24}
      fill="currentColor"
      ref={ref}
      transform={orientation === "horizontal" ? `rotate(90)` : undefined}
      {...props}
    >
      <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
    </svg>
  );
};

export const EllipsisIcon = forwardRef(SvgComponent);
