"use client";

import { portalContainer } from "$components/features/nav/NavBar/portalContainer";
import { useAppStore } from "$hooks/useAppStore";
import { CourseNavigationItem } from "$hooks/useCourseNavigation";
import { api } from "$lib/api";
import { AppState } from "$store/index";
import * as Popover from "@radix-ui/react-popover";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { NavDropdown } from "../NavDropdown";
import { ChapterLevelNavigation } from "./ChapterLevelNavigation";
import { CourseLevelNavigation } from "./CourseLevelNavigation";
import { LessonsLevelNavigation } from "./LessonsLevelNavigation";

export function NavCourseNavigation() {
  const region = useAppStore((state) => state.region);
  const stream = useAppStore((state) => state.stream);
  const course = useAppStore((state) => state.course);
  const chapter = useAppStore((state) => state.chapter);
  const lesson = useAppStore((state) => state.lesson);
  const quiz = useAppStore((state) => state.quiz);
  const setSelectedContent = useAppStore((state) => state.setSelectedContent);

  const initialRegionId = useUserRegionIdAsDefault(region?.id);

  const [open, setOpen] = useState<NavigationOpen>(NavigationOpen.Closed);
  const [warningTooltip, setWarningTooltip] = useState({
    open: false,
    message: "",
  });
  const courseTriggerRef = useRef<HTMLButtonElement>(null);
  const chapterTriggerRef = useRef<HTMLButtonElement>(null);
  const lessonTriggerRef = useRef<HTMLButtonElement>(null);

  const handleInteractOutsidePopover: Popover.PopoverContentProps["onInteractOutside"] =
    (e) => {
      const target = e.target as Node;

      // If clicking directly in the HTML tag, it means that a Dropdown Menu is open
      if (target === document.querySelector("html")) {
        return;
      }

      // Prevent the action when clicking in one of the triggers
      if (
        courseTriggerRef.current?.contains(target) ||
        chapterTriggerRef.current?.contains(target) ||
        lessonTriggerRef.current?.contains(target)
      ) {
        return;
      }
      setOpen(NavigationOpen.Closed);
    };

  // Close the warning tooltip after 2.5 seconds
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (warningTooltip.open) {
      timeout = setTimeout(
        () =>
          setWarningTooltip({ open: false, message: warningTooltip.message }),
        2500
      );
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [warningTooltip]);

  const openWarningTooltip = (message: string) => {
    setWarningTooltip({ open: true, message });
  };

  const onRegionUpdate = (newRegion: AppState["region"]) => {
    if (newRegion) {
      setSelectedContent({
        region: newRegion,
      });
    }
  };

  const onStreamUpdate = (newStream: AppState["stream"]) => {
    if (newStream) {
      setSelectedContent({
        stream: newStream,
      });
    }
  };

  const onCourseUpdate = (newCourse: CourseNavigationItem | null) => {
    if (newCourse) {
      setOpen(NavigationOpen.Chapter);
      if (newCourse?.id !== course?.id) {
        setSelectedContent({
          course: newCourse,
          chapter: null,
          lesson: null,
          quiz: null,
        });
      }
    }
  };

  const onChapterUpdate = (newChapter: CourseNavigationItem | null) => {
    if (newChapter) {
      setOpen(NavigationOpen.Lesson);
      if (newChapter?.id !== chapter?.id) {
        setSelectedContent({
          chapter: {
            id: newChapter.id,
            name: String(newChapter.displayName),
          },
          lesson: null,
          quiz: null,
        });
      }
    }
  };

  const onLessonUpdate = (newLesson: CourseNavigationItem | null) => {
    if (newLesson && newLesson.id !== lesson?.id) {
      setSelectedContent({
        lesson: {
          id: newLesson.id,
          name: String(newLesson.displayName),
        },
        quiz: null,
      });
    }
  };

  return (
    <Popover.Root open={open > 0}>
      <Popover.Trigger asChild>
        <div className="relative flex w-[510px] shrink grow-0 items-center">
          <div
            className={cn(
              "pointer-events-none absolute inset-0 left-1/2 top-7 h-fit -translate-x-1/2 rounded bg-carbon-900 px-3 py-2 transition-opacity duration-300",
              warningTooltip.open ? "opacity-100" : "opacity-0"
            )}
          >
            <p className="text-center text-white">{warningTooltip.message}</p>
          </div>

          <NavDropdown
            ref={courseTriggerRef}
            className="nav-control"
            data-testid="nav-course-dropdown-trigger"
            enabled={open === 1}
            label={course?.name ?? "Course"}
            onClick={() => {
              setOpen(
                open === NavigationOpen.Course
                  ? NavigationOpen.Closed
                  : NavigationOpen.Course
              );
            }}
            onKeyDownCapture={(e) => {
              if (
                (e.key === "Enter" || e.key === "ArrowDown") &&
                open !== NavigationOpen.Course
              ) {
                setOpen(NavigationOpen.Course);
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          />

          <NavDropdown
            ref={chapterTriggerRef}
            className="nav-control"
            data-testid="nav-chapter-dropdown-trigger"
            enabled={open === 2}
            label={
              chapter
                ? chapter.name.split(" - ")[0]
                : quiz
                  ? quiz.name
                  : "Chapter"
            }
            onClick={() => {
              if (!course) {
                openWarningTooltip("Please choose a course first");
                setOpen(NavigationOpen.Course);
              } else {
                setOpen(
                  open === NavigationOpen.Chapter
                    ? NavigationOpen.Closed
                    : NavigationOpen.Chapter
                );
              }
            }}
            onKeyDownCapture={(e) => {
              if (
                (e.key === "Enter" || e.key === "ArrowDown") &&
                open !== NavigationOpen.Chapter
              ) {
                if (!course) {
                  openWarningTooltip("Please choose a course first");
                  setOpen(NavigationOpen.Course);
                } else {
                  setOpen(NavigationOpen.Chapter);
                }
              }
            }}
          />

          <NavDropdown
            ref={lessonTriggerRef}
            className="nav-control"
            data-testid="nav-lesson-dropdown-trigger"
            enabled={open === 3}
            label={
              lesson
                ? lesson.name.split(" - ")[0]
                : chapter && quiz
                  ? quiz.name
                  : "Lesson"
            }
            onClick={() => {
              if (!course) {
                openWarningTooltip("Please choose a course first");
                setOpen(NavigationOpen.Course);
              } else if (!chapter) {
                openWarningTooltip("Please choose a chapter first");
                setOpen(NavigationOpen.Chapter);
              } else {
                setOpen(
                  open === NavigationOpen.Lesson
                    ? NavigationOpen.Closed
                    : NavigationOpen.Lesson
                );
              }
            }}
            onKeyDownCapture={(e) => {
              if (
                (e.key === "Enter" || e.key === "ArrowDown") &&
                open !== NavigationOpen.Lesson
              ) {
                if (!course) {
                  openWarningTooltip("Please choose a course first");
                  setOpen(NavigationOpen.Course);
                } else if (!chapter) {
                  openWarningTooltip("Please choose a chapter first");
                  setOpen(NavigationOpen.Chapter);
                } else {
                  setOpen(NavigationOpen.Lesson);
                }
              }
            }}
          />
        </div>
      </Popover.Trigger>
      <Popover.Portal container={portalContainer()}>
        <Popover.Content
          className="relative z-20 flex max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popper-anchor-width)] items-stretch"
          onInteractOutside={handleInteractOutsidePopover}
        >
          <div
            className={cn(
              "z-30 w-full flex-shrink-0 bg-white text-sm shadow",
              open > NavigationOpen.Course ? "overflow-hidden" : "overflow-auto"
            )}
            onClick={() => setOpen(NavigationOpen.Course)}
          >
            <CourseLevelNavigation
              onRegionUpdate={onRegionUpdate}
              onCourseUpdate={onCourseUpdate}
              onStreamUpdate={onStreamUpdate}
              disabled={open > 1}
              streamId={typeof stream === "string" ? stream : stream?.id}
              regionId={initialRegionId}
              courseId={course?.id}
              closeMenu={() => {
                setOpen(NavigationOpen.Closed);
                setTimeout(() => courseTriggerRef.current?.focus(), 0);
              }}
            />
          </div>

          {open > NavigationOpen.Course && (
            <div
              className={cn(
                "z-40 -ml-[88%] w-[88%] flex-shrink-0 bg-white text-sm shadow",
                open > NavigationOpen.Chapter
                  ? "overflow-hidden"
                  : "overflow-auto"
              )}
              onClick={() => setOpen(NavigationOpen.Chapter)}
            >
              {course && (
                <ChapterLevelNavigation
                  courseId={course.id}
                  selected={{
                    chapterId: chapter?.id,
                    quizId: quiz?.id,
                  }}
                  onChapterUpdate={onChapterUpdate}
                  disabled={open > 2}
                  closeMenu={() => {
                    setOpen(NavigationOpen.Closed);
                    setTimeout(() => chapterTriggerRef.current?.focus(), 0);
                  }}
                  onGoBack={() => setOpen(NavigationOpen.Course)}
                />
              )}
            </div>
          )}

          {open === NavigationOpen.Lesson && (
            <div className="relative z-50 -ml-[74%] w-[74%] flex-shrink-0 overflow-auto bg-white text-sm shadow">
              {chapter && (
                <LessonsLevelNavigation
                  chapterId={chapter.id}
                  selected={{
                    lessonId: lesson?.id,
                    quizId: quiz?.id,
                  }}
                  onLessonUpdate={onLessonUpdate}
                  closeMenu={() => {
                    setOpen(NavigationOpen.Closed);
                    setTimeout(() => lessonTriggerRef.current?.focus(), 0);
                  }}
                  onGoBack={() => setOpen(NavigationOpen.Chapter)}
                />
              )}
            </div>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

function useUserRegionIdAsDefault(regionId: number | undefined) {
  const { data } = api.region.findUserRegion.useQuery(undefined, {
    initialData: { id: regionId } as any,
    staleTime: Infinity,
  });
  return data?.id;
}

enum NavigationOpen {
  Closed = 0,
  Course = 1,
  Chapter = 2,
  Lesson = 3,
}
