import { Ref, forwardRef } from "react";
import { IconDirection, IconProps, IconWeight } from "./index";

const SvgComponent = (
  {
    title,
    weight = "regular",
    direction = "down",
    ...svgProps
  }: IconProps & { weight?: IconWeight; direction?: IconDirection },
  ref: Ref<SVGSVGElement>
) => {
  let transform = "";
  switch (direction) {
    case "up": {
      transform = "rotate(180)";
      break;
    }
    case "left": {
      transform = "rotate(90)";
      break;
    }
    case "right": {
      transform = "rotate(-90)";
      break;
    }
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      ref={ref}
      transform={transform}
      {...svgProps}
    >
      {title && <title>{title}</title>}
      <Content weight={weight} />
    </svg>
  );
};

const Content = ({ weight }: { weight: IconWeight }) => {
  switch (weight) {
    case "regular":
      return (
        <path d="M20.0306 9.53068L12.5306 17.0307C12.461 17.1004 12.3782 17.1557 12.2872 17.1935C12.1961 17.2312 12.0986 17.2506 12 17.2506C11.9014 17.2506 11.8038 17.2312 11.7128 17.1935C11.6217 17.1557 11.539 17.1004 11.4694 17.0307L3.96936 9.53068C3.82863 9.38995 3.74957 9.19907 3.74957 9.00005C3.74957 8.80103 3.82863 8.61016 3.96936 8.46943C4.1101 8.32869 4.30097 8.24963 4.49999 8.24963C4.69901 8.24963 4.88988 8.32869 5.03061 8.46943L12 15.4397L18.9694 8.46943C19.039 8.39974 19.1218 8.34447 19.2128 8.30676C19.3039 8.26904 19.4014 8.24963 19.5 8.24963C19.5985 8.24963 19.6961 8.26904 19.7872 8.30676C19.8782 8.34447 19.9609 8.39974 20.0306 8.46943C20.1003 8.53911 20.1556 8.62183 20.1933 8.71288C20.231 8.80392 20.2504 8.9015 20.2504 9.00005C20.2504 9.0986 20.231 9.19618 20.1933 9.28722C20.1556 9.37827 20.1003 9.46099 20.0306 9.53068Z" />
      );
    case "thin":
      return (
        <path d="M19.7653 9.26528L12.2653 16.7653C12.2305 16.8001 12.1892 16.8278 12.1436 16.8467C12.0981 16.8655 12.0493 16.8753 12 16.8753C11.9507 16.8753 11.9019 16.8655 11.8564 16.8467C11.8109 16.8278 11.7695 16.8001 11.7347 16.7653L4.23471 9.26528C4.19987 9.23044 4.17223 9.18907 4.15338 9.14355C4.13452 9.09803 4.12482 9.04924 4.12482 8.99996C4.12482 8.95069 4.13452 8.9019 4.15338 8.85638C4.17223 8.81086 4.19987 8.76949 4.23471 8.73465C4.30508 8.66429 4.40051 8.62476 4.50003 8.62476C4.5493 8.62476 4.59809 8.63446 4.64361 8.65332C4.68913 8.67217 4.7305 8.69981 4.76534 8.73465L12 15.9693L19.2347 8.73465C19.2696 8.69981 19.3109 8.67217 19.3564 8.65332C19.402 8.63446 19.4508 8.62476 19.5 8.62476C19.5493 8.62476 19.5981 8.63446 19.6436 8.65332C19.6891 8.67217 19.7305 8.69981 19.7653 8.73465C19.8002 8.76949 19.8278 8.81086 19.8467 8.85638C19.8655 8.9019 19.8752 8.95069 19.8752 8.99996C19.8752 9.04924 19.8655 9.09803 19.8467 9.14355C19.8278 9.18907 19.8002 9.23044 19.7653 9.26528Z" />
      );
    case "light":
      return (
        <path d="M19.8975 9.39751L12.3975 16.8975C12.292 17.0028 12.1491 17.062 12 17.062C11.8509 17.062 11.708 17.0028 11.6025 16.8975L4.10251 9.39751C4.00315 9.29088 3.94905 9.14984 3.95163 9.00412C3.9542 8.85839 4.01323 8.71935 4.11629 8.61629C4.21935 8.51323 4.35839 8.4542 4.50411 8.45163C4.64984 8.44905 4.79088 8.50315 4.89751 8.60251L12 15.7041L19.1025 8.60251C19.2091 8.50315 19.3502 8.44905 19.4959 8.45163C19.6416 8.4542 19.7807 8.51323 19.8837 8.61629C19.9868 8.71935 20.0458 8.85839 20.0484 9.00412C20.051 9.14984 19.9969 9.29088 19.8975 9.39751Z" />
      );
    case "bold":
      return (
        <path d="M20.296 9.79598L12.796 17.296C12.6914 17.4009 12.5673 17.4841 12.4305 17.5409C12.2938 17.5976 12.1471 17.6269 11.9991 17.6269C11.851 17.6269 11.7044 17.5976 11.5677 17.5409C11.4309 17.4841 11.3067 17.4009 11.2022 17.296L3.70221 9.79598C3.49086 9.58464 3.37213 9.29799 3.37213 8.9991C3.37213 8.70022 3.49086 8.41357 3.70221 8.20223C3.91355 7.99089 4.2002 7.87215 4.49908 7.87215C4.79797 7.87215 5.08461 7.99089 5.29596 8.20223L12 14.9063L18.7041 8.20129C18.9154 7.98995 19.2021 7.87122 19.501 7.87122C19.7998 7.87122 20.0865 7.98995 20.2978 8.20129C20.5092 8.41264 20.6279 8.69928 20.6279 8.99817C20.6279 9.29705 20.5092 9.5837 20.2978 9.79504L20.296 9.79598Z" />
      );
    case "fill":
      return (
        <path d="M20.0306 9.53063L12.5306 17.0306C12.461 17.1004 12.3782 17.1557 12.2872 17.1934C12.1961 17.2312 12.0985 17.2506 12 17.2506C11.9014 17.2506 11.8038 17.2312 11.7128 17.1934C11.6217 17.1557 11.539 17.1004 11.4694 17.0306L3.96936 9.53063C3.86435 9.42573 3.79282 9.29204 3.76383 9.14648C3.73485 9.00092 3.7497 8.85002 3.80651 8.71291C3.86333 8.57579 3.95955 8.45861 4.08299 8.37621C4.20644 8.29381 4.35156 8.24988 4.49998 8.25H19.5C19.6484 8.24988 19.7935 8.29381 19.917 8.37621C20.0404 8.45861 20.1366 8.57579 20.1934 8.71291C20.2503 8.85002 20.2651 9.00092 20.2361 9.14648C20.2071 9.29204 20.1356 9.42573 20.0306 9.53063Z" />
      );
    case "duotone":
      return (
        <>
          <path opacity="0.2" d="M19.5 9L12 16.5L4.5 9H19.5Z" />
          <path d="M20.1928 8.71313C20.1361 8.57607 20.04 8.45892 19.9166 8.37648C19.7933 8.29405 19.6483 8.25003 19.5 8.25H4.49998C4.35156 8.24988 4.20644 8.29381 4.08299 8.37621C3.95955 8.45861 3.86333 8.57579 3.80651 8.71291C3.7497 8.85002 3.73485 9.00092 3.76383 9.14648C3.79282 9.29204 3.86435 9.42573 3.96936 9.53063L11.4694 17.0306C11.539 17.1004 11.6217 17.1557 11.7128 17.1934C11.8038 17.2312 11.9014 17.2506 12 17.2506C12.0985 17.2506 12.1961 17.2312 12.2872 17.1934C12.3782 17.1557 12.461 17.1004 12.5306 17.0306L20.0306 9.53063C20.1355 9.42568 20.2068 9.29199 20.2357 9.14648C20.2646 9.00096 20.2496 8.85015 20.1928 8.71313ZM12 15.4397L6.31029 9.75H17.6897L12 15.4397Z" />
        </>
      );
  }
};

export const CaretIcon = forwardRef(SvgComponent);
