export const EMAIL_SUBSCRIPTION_LEVEL = {
  0: "never",
  1: "immediate",
  2: "daily",
  3: "weekly",
} as const;
export const EMAIL_SUBSCRIPTION_TYPES = [
  "all",
  "attempt_submitted",
  "attempt_graded",
  "low_credits",
  "lms_version_change",
  "share_work_link",
  "force",
] as const;
export type EmailSubscriptionType = (typeof EMAIL_SUBSCRIPTION_TYPES)[number];

export const DEFAULT_EMAIL_SUBSCRIPTION_LEVEL = 1;
export const DEFAULT_EMAIL_SUBSCRIPTION_LEVEL_NAME =
  EMAIL_SUBSCRIPTION_LEVEL[DEFAULT_EMAIL_SUBSCRIPTION_LEVEL];

export type EmailSubscriptionLevel = keyof typeof EMAIL_SUBSCRIPTION_LEVEL;

export type EmailSubscriptionLevelName =
  (typeof EMAIL_SUBSCRIPTION_LEVEL)[EmailSubscriptionLevel];
