import { ComponentPropsWithRef, Ref, forwardRef } from "react";
import { tv } from "tailwind-variants";

export type LabelProps = {
  disabled?: boolean;
  required?: boolean;
} & ComponentPropsWithRef<"label">;

const style = tv({
  base: "text-sm text-carbon-900",
  variants: {
    disabled: {
      true: "text-carbon-400 pointer-events-none",
    },
  },
});

export const Label = forwardRef(function Label(
  { disabled, required, children, className, ...labelProps }: LabelProps,
  ref: Ref<HTMLLabelElement>
) {
  return (
    <label ref={ref} className={style({ className, disabled })} {...labelProps}>
      {children}
      {required && <span className="ml-0.5 text-red-800">*</span>}
    </label>
  );
});
