import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 21"
    fill="currentColor"
    ref={ref}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29979 17.7926C3.94394 17.7926 3.63257 17.6591 3.36568 17.3922C3.09879 17.1253 2.96535 16.814 2.96535 16.4581V3.04697H4.29979V16.4581H14.8419V17.7926H4.29979ZM6.96867 15.1237C6.61282 15.1237 6.30145 14.9902 6.03456 14.7233C5.76768 14.4564 5.63423 14.1451 5.63423 13.7892V1.33444C5.63423 0.97859 5.76768 0.667221 6.03456 0.400332C6.30145 0.133444 6.61282 0 6.96867 0H16.7546C17.1104 0 17.4218 0.133444 17.6887 0.400332C17.9556 0.667221 18.089 0.97859 18.089 1.33444V6.72599H21.3837L20.6916 6.04963C20.5197 5.88249 20.4251 5.65572 20.4251 5.418C20.4251 5.18042 20.5197 4.9576 20.6916 4.79059L21.2388 4.25869C21.4107 4.09182 21.6399 4 21.8846 4C22.1293 4 22.3587 4.09182 22.5306 4.25869L25.7336 7.37068C25.906 7.53821 26.0007 7.76195 26 8.00072C26.0007 8.23871 25.906 8.46244 25.7336 8.62998L22.5319 11.7408C22.36 11.9078 22.1307 12.0003 21.886 12C21.6414 12 21.4121 11.9086 21.2402 11.7414L20.693 11.211C20.5209 11.044 20.4263 10.8233 20.4263 10.5857C20.4263 10.348 20.5209 10.1295 20.693 9.96237L21.4235 9.26122H18.089V13.7892C18.089 14.1451 17.9556 14.4564 17.6887 14.7233C17.4218 14.9902 17.1104 15.1237 16.7546 15.1237H6.96867ZM16.7546 6.72599H14.9146C14.411 6.72599 14 7.11594 14 7.6051V8.35704C14 8.8462 14.411 9.26122 14.9146 9.26122H16.7546V13.7892H6.96867V1.33444H16.7546V6.72599ZM1.33444 20.7952C0.97859 20.7952 0.667221 20.6617 0.400332 20.3948C0.133444 20.128 0 19.8166 0 19.4607V6.04959H1.33444V19.4607H11.8765V20.7952H1.33444Z"
    />
  </svg>
);

const RemoveQuestionIcon = forwardRef(SvgComponent);
export { RemoveQuestionIcon };
