import { CheckCircleIcon } from "$components/shared/Icons/CheckCircleIcon";
import { ErrorIcon } from "$components/shared/Icons/ErrorIcon";
import { WarningFilledIcon } from "$components/shared/Icons/WarningFilledIcon";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

export type HelperTextProps = {
  color: "base" | "success" | "warning" | "error";
  text: string | undefined;
  disabled?: boolean;
  className?: string;
};

const variants = tv({
  slots: {
    root: "flex items-center gap-1",
    text: "text-xs text-carbon-600",
    icon: "w-4",
  },
  variants: {
    color: {
      base: { text: "text-carbon-600" },
      success: { text: "text-emerald-800", icon: "text-emerald-500" },
      warning: { text: "text-yellow-900", icon: "text-vulcan-300" },
      error: { text: "text-red-800", icon: "text-red-500" },
    },
    disabled: {
      true: {
        text: "text-carbon-400",
        icon: "opacity-70",
      },
    },
  },
});

export const HelperText = ({
  color,
  text,
  disabled,
  className,
}: HelperTextProps) => {
  const styles = variants({ color, disabled });

  if (!text) {
    return null;
  }

  return (
    <div className={styles.root({ className })}>
      <HelperTextIcon color={color} className={styles.icon()} />
      <p className={styles.text()}>{text}</p>
    </div>
  );
};

const HelperTextIcon = ({
  color,
  className,
}: Pick<HelperTextProps, "color"> & { className: string }) => {
  switch (color) {
    case "base":
      return null;

    case "success":
      return <CheckCircleIcon className={twMerge(className, "w-4")} />;

    case "warning":
      return <WarningFilledIcon className={twMerge(className, "w-4")} />;

    case "error":
      return <ErrorIcon className={twMerge(className, "w-4")} />;
  }
};
