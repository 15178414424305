import { useModal } from "$context/modal/ModalContext";
import { env } from "$env";
import { useAppStore } from "$hooks/useAppStore";
import { api } from "$lib/api";
import {
  copyToClipboard,
  generateLTILink,
  getRootSF3URL,
  isIframe,
} from "$utils/appUtil";
import toast from "react-hot-toast";
import { PrintNotesModalContent } from "./PrintNotes/PrintNotesModalContent";

type OnThisPageMenuItem = {
  name: string;
  href?: string;
  openInNewPage?: boolean;
  onClick?: () => void;
};

export function useOnThisPageMenuItems() {
  const viewAsScopes = useAppStore((state) => state.viewAsScopes);
  const viewAsUser = useAppStore((state) => state.viewAsUser);
  const viewAsGroup = useAppStore((state) => state.viewAsGroup);
  const currentUser = useAppStore((state) => state.currentUser);
  const isViewingProgress = useAppStore((state) => state.isViewingProgress);
  const course = useAppStore((state) => state.course);
  const chapter = useAppStore((state) => state.chapter);
  const lesson = useAppStore((state) => state.lesson);
  const quiz = useAppStore((state) => state.quiz);
  const editingLessonId = useAppStore((state) => state.editingLessonId);
  const isStudent = useAppStore((state) => state.isStudent);
  const courseIdsUserCanEdit = useAppStore(
    (state) => state.courseIdsUserCanEdit
  );
  const setIsViewingProgress = useAppStore(
    (state) => state.setIsViewingProgress
  );
  const setViewAsUser = useAppStore((state) => state.setViewAsUser);
  const userCanEditCourse = course
    ? courseIdsUserCanEdit.has(course.id)
    : false;
  const { createModal, remove } = useModal();

  return (() => {
    const items: OnThisPageMenuItem[] = [];

    if (currentUser.canSeeLTILinks) {
      items.push({
        name: "Copy LTI Link",
        onClick: async () => {
          try {
            await copyToClipboard(generateLTILink());
            toast.success("Link copied to clipboard");
          } catch {
            toast.error("Something went wrong, please try again");
          }
        },
      });
    }

    if (isIframe()) {
      items.push({
        name: "Open In New Tab",
        href: window.location.href,
        openInNewPage: true,
      });
    }

    if (!!course && !!chapter) {
      items.push({
        name: "Print Notes",
        onClick: () => {
          const modalId = createModal({
            title: "Print Notes",
            content: (
              <PrintNotesModalContent
                isStudent={isStudent}
                courseId={course.id}
                chapterId={chapter.id}
                lessonId={lesson?.id}
                onCancelClick={() => remove(modalId)}
              />
            ),
            showClose: true,
          });
        },
      });
    }

    if (
      isStudent &&
      ["none", "users"].every((scope) => viewAsScopes.includes(scope))
    ) {
      items.push({
        name: isViewingProgress ? "Stop Viewing Progress" : "View Progress",
        onClick: () => {
          setIsViewingProgress(!isViewingProgress);
          if (
            !isViewingProgress &&
            !viewAsUser &&
            !viewAsGroup &&
            currentUser
          ) {
            setViewAsUser({
              user: {
                id: currentUser.id,
                name: currentUser.firstname + " " + currentUser.lastname,
              },
            });
          }
        },
      });
    }

    if (course && userCanEditCourse) {
      items.push({
        name: "Edit This Course",
        href: `${getRootSF3URL()}/construct/course/${course.id}`,
      });
    }

    if (lesson && userCanEditCourse && editingLessonId !== lesson.id) {
      items.push({
        name: "Edit This Lesson",
        href: `${getRootSF3URL()}/lesson/${lesson.id}?edit=${lesson.id}`,
      });
    }

    if (quiz && (userCanEditCourse || viewAsGroup)) {
      items.push({
        name: "Edit This Quiz",
        href: `${env.NEXT_PUBLIC_APP_URL}/construct/quiz/${quiz.id}${
          viewAsGroup ? `?group=${viewAsGroup.id}` : ""
        }`,
      });
    }

    return items;
  })();
}
