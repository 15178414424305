import { UserIcon } from "$components/shared/Icons/UserIcon";
import { UsersThreeIcon } from "$components/shared/Icons/UsersThreeIcon";
import { Tooltip } from "$components/shared/Tooltip";
import { useAppStore } from "$hooks/useAppStore";
import { useUserSelection } from "$hooks/useUserSelection";
import { useViewingAs } from "$hooks/useUserSelection/useViewingAs";
import { ViewAsScopes } from "$store/index";
import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import * as Popover from "@radix-ui/react-popover";
import isEqual from "fast-deep-equal";
import { useEffect, useRef, useState } from "react";
import { portalContainer } from "../portalContainer";
import { ViewAsDropdown } from "./ViewAsDropdown";

export function NavViewAsNavigation() {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const viewAsScopes = useAppStore((state) => state.viewAsScopes);
  const isViewingProgress = useAppStore((state) => state.isViewingProgress);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const viewingAs = useViewingAs();

  // Define the user selection state up here so the level is kept when opening / closing the dropdown
  const userSelection = useUserSelection({
    viewingAs,
    searchTerm,
  });

  const viewingAlternateProgress = viewingAs
    ? viewingAs.group || (viewingAs.id !== 1 && !viewingAs.group)
    : false;

  // Close the dropdown if viewing as changes
  useEffect(() => {
    setOpen(false);
    setSearchTerm("");
  }, [viewingAs]);

  const handleInteractiveOutsidePopover: Popover.PopoverContentProps["onInteractOutside"] =
    (e) => {
      const target = e.target as Node;

      // If clicking directly in the HTML tag, it means that a Dropdown Menu is open
      if (target === document.querySelector("html")) {
        return;
      }

      setOpen(false);
    };

  return (
    <Popover.Root open={open}>
      <Tooltip
        size="sm"
        title={
          isEqual(viewAsScopes, ["none"])
            ? "This selector has no effect on this page"
            : null
        }
        delayDuration={0}
        portalProps={{
          container: portalContainer(),
        }}
      >
        <Popover.Trigger
          ref={triggerRef}
          onClick={() => setOpen(!open)}
          onKeyDownCapture={(e) => {
            if ((e.key === "Enter" || e.key === "ArrowDown") && !open) {
              setOpen(true);
            }
          }}
          className="nav-control flex w-[270px] shrink grow cursor-pointer items-center border-r border-anvil-100 px-2 disabled:cursor-default disabled:opacity-70"
          disabled={isEqual(viewAsScopes, ["none"])}
        >
          <span className="whitespace-nowrap pr-2 text-xs italic">
            {getTriggerLabel({ viewAsScopes, viewingAs })}
          </span>
          <div className="flex flex-1 items-center justify-end overflow-hidden">
            {isViewingProgress && viewingAs && (
              <>
                <div className="pr-1.5">
                  {viewingAs.group ? (
                    <UsersThreeIcon width="18" weight="fill" />
                  ) : (
                    <UserIcon
                      className="h-4 w-4"
                      weight={viewingAlternateProgress ? "fill" : "regular"}
                    />
                  )}
                </div>
                <span className="truncate pr-1 tracking-tight">
                  {isViewingProgress
                    ? `${viewingAs.name}${
                        viewingAlternateProgress ? "" : " (Me)"
                      }`
                    : ""}
                </span>
              </>
            )}
            <Icon className="h-5 w-5" path={mdiChevronDown} />
          </div>
        </Popover.Trigger>
      </Tooltip>
      <Popover.Portal container={portalContainer()}>
        <Popover.Content
          onInteractOutside={handleInteractiveOutsidePopover}
          className="relative z-30 flex max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popper-anchor-width)] min-w-[315px] flex-col items-stretch overflow-auto bg-white py-2 shadow"
        >
          <ViewAsDropdown
            viewingAs={viewingAs}
            userSelection={userSelection}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            closeMenu={() => {
              setOpen(false);
              setTimeout(() => triggerRef.current?.focus(), 0);
            }}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

function getTriggerLabel({
  viewAsScopes,
  viewingAs,
}: {
  viewAsScopes: ViewAsScopes;
  viewingAs: { id: number } | null;
}) {
  if (
    !viewingAs &&
    viewAsScopes.includes("groups") &&
    viewAsScopes.includes("users")
  ) {
    return "Choose a group or user";
  }

  if (viewAsScopes.includes("groups") && !viewingAs) {
    return "Choose a group";
  }

  if (viewAsScopes.includes("users") && !viewingAs) {
    return "Choose a user";
  }

  return "Viewing as";
}
