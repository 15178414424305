import Link from "next/link";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import { checkIsSf3App, getAbsoluteHref } from "./lib";

export type NavLinkProps = {
  href: string;
  /**
   * If true, the link represents a link to the sf3 app
   */
  isSf3Href?: boolean;
} & ComponentPropsWithoutRef<"a">;

export const NavLink = forwardRef<ElementRef<"a">, NavLinkProps>(
  ({ isSf3Href, href, ...rest }, ref) => {
    const isSf3App = checkIsSf3App();

    if (isSf3Href || isSf3App) {
      // Either an sf4 link being rendered in the sf3 app, or an sf3 link being rendered in either apps
      return (
        <a ref={ref} href={getAbsoluteHref(href, !!isSf3Href)} {...rest} />
      );
    } else {
      // The link goes to an sf4 page, and the <NavLink /> component is being rendered in the sf4 app
      return <Link ref={ref} href={href} {...rest} />;
    }
  }
);
NavLink.displayName = "NavLink";
