import { useAppStore } from "$hooks/useAppStore";
import type { UserSelection, UserSelectionItem } from "$hooks/useUserSelection";

export const BACK_ID = -1;
export const VIEW_AS_ENTIRE_GROUP_ID = -2;
export const VIEW_AS_MYSELF_ID = -3;
export const STOP_VIEWING_AS_ID = -4;

export function useViewAsExtraItems({
  level,
  back,
  viewingAs,
}: {
  level: UserSelection["level"];
  back: string | null;
  viewingAs: { id: number; group: boolean } | null;
}): Record<
  "BACK" | "STOP_VIEWING_AS" | "VIEW_AS_ENTIRE_GROUP" | "VIEW_AS_MYSELF",
  { show: boolean; index: number; item: UserSelectionItem }
> {
  const currentUser = useAppStore((state) => state.currentUser);
  const viewAsScopes = useAppStore((state) => state.viewAsScopes);

  const showBackItem = !!back;
  const showViewAsEntireGroup =
    level === "students" && viewAsScopes.includes("groups");
  const showStopViewAs = viewAsScopes.includes("none") && !!viewingAs;

  const viewAsEntireGroupIndex = (() => {
    let index = 0;
    if (showBackItem) index++;
    if (showStopViewAs) index++;
    return index;
  })();
  const viewAsMyselfIndex = (() => {
    let index = 0;
    if (showBackItem) index++;
    if (showStopViewAs) index++;
    if (showViewAsEntireGroup) index++;
    return index;
  })();

  return {
    BACK: {
      show: showBackItem,
      index: 0,
      item: {
        id: BACK_ID,
        name: back ?? "",
        type: "Extra",
        fromSearch: false,
      },
    },
    STOP_VIEWING_AS: {
      show: showStopViewAs,
      index: showBackItem ? 1 : 0,
      item: {
        id: STOP_VIEWING_AS_ID,
        name: "Stop Viewing as ",
        type: "Extra",
        fromSearch: false,
      },
    },
    VIEW_AS_ENTIRE_GROUP: {
      show: showViewAsEntireGroup,
      index: viewAsEntireGroupIndex,
      item: {
        id: VIEW_AS_ENTIRE_GROUP_ID,
        name: "View As Entire Group",
        type: "Extra",
        fromSearch: false,
      },
    },
    VIEW_AS_MYSELF: {
      show:
        viewAsScopes.includes("users") &&
        (viewingAs?.group === true || viewingAs?.id !== currentUser.id),
      index: viewAsMyselfIndex,
      item: {
        id: VIEW_AS_MYSELF_ID,
        name: "View As Myself",
        type: "Extra",
        fromSearch: false,
      },
    },
  };
}
