import { AppStoreContext } from "$context/AppStoreProvider";
import { type AppStore } from "$store/index";
import { useContext } from "react";
import { useStore } from "zustand";

export const useAppStore = <T>(selector: (store: AppStore) => T): T => {
  const appStoreContext = useContext(AppStoreContext);

  if (!appStoreContext) {
    throw new Error(`useAppStore must be use within AppStoreProvider`);
  }

  return useStore(appStoreContext, selector);
};
