"use client";

import { AccessibilityIcon } from "$components/shared/Icons/AccessibilityIcon";
import { Tooltip } from "$components/shared/Tooltip";
import { useAppStore } from "$hooks/useAppStore";
import { api } from "$lib/api";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import isEqual from "fast-deep-equal";
import { useEffect, useRef, useState } from "react";
import { portalContainer } from "../portalContainer";
import { AccessibilityOptions } from "./AccessibilityOptions";

export const NavAccessibilityMenu = ({ isPreview }: { isPreview: boolean }) => {
  if (isPreview) {
    return (
      <div className="flex w-9 min-w-7 items-center justify-center border-l border-anvil-100">
        <AccessibilityIcon weight="regular" />
      </div>
    );
  }

  return <NavAccessibilityMenuContent />;
};

const NavAccessibilityMenuContent = () => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const selectVideoReadingPreferenceRef = useRef<HTMLButtonElement>(null);
  const {
    id: userId,
    preferences: { acc_settings: accSettings },
  } = useAppStore((state) => state.currentUser);
  const setAccSettings = useAppStore((state) => state.setAccessibilitySettings);
  const [localAccSettings, setLocalAccSettings] = useState(accSettings);
  const [open, setOpen] = useState(false);
  const { mutate: updateAccessibilitySettings, isLoading } =
    api.user.updateAccessibilitySettings.useMutation({
      onSuccess: (data) => {
        setAccSettings(data);
        if (window.inSF3) {
          window.location.reload();
        }
      },
    });

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (contentRef.current) {
      const selectOpen =
        selectVideoReadingPreferenceRef.current?.getAttribute("data-state") ===
          "open" || false;
      if (!selectOpen) {
        const controls = Array.from(
          contentRef.current?.querySelectorAll(
            ".acc-control input, .acc-control button"
          ) as NodeListOf<HTMLElement>
        );
        const activeElement = document.activeElement as HTMLElement;
        const currIndex = controls.indexOf(activeElement);

        if (e.key === "ArrowUp") {
          e.preventDefault();
          if (currIndex <= 0) {
            setOpen(false);
          } else {
            controls[currIndex - 1].focus();
          }
        } else if (e.key === "ArrowDown") {
          e.preventDefault();
          if (currIndex < controls.length - 1) {
            controls[currIndex + 1].focus();
          }
        } else if (e.key === "Enter") {
          controls[currIndex].click();
        } else if (e.key === "Escape") {
          setOpen(false);
        }
      }
    }
  };

  useEffect(() => {
    if (!open && !isEqual(localAccSettings, accSettings)) {
      updateAccessibilitySettings({
        userId,
        settings: localAccSettings,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dropdown.Root open={open} modal={false}>
      <Tooltip
        title={`Accessibility mode is ${
          localAccSettings.accessibilityMode ? "enabled" : "disabled"
        }`}
        portalProps={{
          container: portalContainer(),
        }}
        size="sm"
      >
        <Dropdown.Trigger
          ref={triggerRef}
          onClick={() => setOpen(!open)}
          onKeyDownCapture={(e) => {
            if (
              (e.key === "Enter" || e.key === "ArrowDown") &&
              !open &&
              !isLoading
            ) {
              setOpen(true);
            }
          }}
          className="nav-control flex w-9 min-w-7 cursor-pointer items-center justify-center border-l border-anvil-100"
        >
          <AccessibilityIcon
            weight={localAccSettings.accessibilityMode ? "fill" : "regular"}
          />
        </Dropdown.Trigger>
      </Tooltip>
      <Dropdown.Content
        ref={contentRef}
        onInteractOutside={() => setOpen(false)}
        align="end"
        className="relative z-30 flex max-h-[var(--radix-dropdown-menu-content-available-height)] w-[var(--radix-dropdown-menu-content-available-width)] max-w-[600px] flex-col items-stretch bg-white py-2 shadow-md"
        onKeyDownCapture={handleKeyDown}
      >
        <AccessibilityOptions
          accSettings={localAccSettings}
          updateSettings={setLocalAccSettings}
          selectRef={selectVideoReadingPreferenceRef}
        />
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
