import { NavBar } from "$components/features/nav/NavBar";
import { AppStoreProvider } from "$context/AppStoreProvider";
import { TRPCProvider } from "$context/TRPCProvider";
import { ModalContextProvider } from "$context/modal/ModalContext";
import {
  AccessibilitySettingsSchema,
  DEFAULT_ACC_SETTINGS,
} from "$lib/accessibilitySettings";
import { EMAIL_SUBSCRIPTION_TYPES } from "$lib/emailSubscriptions";
import { createAppStore } from "$store/index";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import React from "react";
import { createRoot } from "react-dom/client";
import { z } from "zod";

const NavBarCustomizationSchema = z
  .union([
    z.object({
      hidden: z.boolean().catch(false),
      logoUrl: z.string().nullable(),
      includeSearch: z.boolean(),
      includeCourseNav: z.boolean(),
      includePrintNotes: z.boolean(),
      includeNextLessonBtn: z.boolean(),
    }),
    z.object({ hidden: z.literal(true) }),
  ])
  .nullable();

const NavBarPropsSchema = z.object({
  user: z
    .object({
      id: z.number(),
      firstname: z.string(),
      lastname: z.string(),
      institution_id: z.number().int().nullable(),
      isSFLite: z.boolean(),
      preferences: z.object({
        is_beta_user: z.boolean(),
        email_subscriptions: z
          .record(
            z.enum(EMAIL_SUBSCRIPTION_TYPES),
            z.enum(["never", "immediate", "daily", "weekly"])
          )
          .catch({}),
        acc_settings: AccessibilitySettingsSchema,
      }),
    })
    .nullable(),
  canSeeLTILinks: z.boolean(),
  loginVia: z.string().optional(),
  viewAsScopes: z.array(z.enum(["none", "groups", "users"])),
  viewAsUser: z
    .object({
      id: z.number().int(),
      name: z.string(),
    })
    .nullable(),
  viewAsGroup: z
    .object({
      id: z.number().int(),
      name: z.string(),
      course_id: z.number().int().nullable(),
      institution: z
        .object({
          id: z.number().int(),
          name: z.string(),
          partner: z
            .object({ id: z.number().int(), name: z.string() })
            .nullable(),
          region_id: z.number().int().nullable(),
        })
        .optional()
        .nullable(),
    })
    .nullable(),
  isViewingProgress: z.boolean(),
  isViewAsClientSide: z.boolean(),
  isStudent: z.boolean().nullable(),
  isPreview: z.boolean(),
  customization: NavBarCustomizationSchema,
  pageWithoutNavigation: z.boolean().optional(),
});
type NavBarProps = z.infer<typeof NavBarPropsSchema>;

let reactRoot: ReturnType<typeof createRoot> | undefined;
export function render(element: HTMLElement, unparsedProps: unknown) {
  const PropsSchema = z
    .object({
      isPreview: z.boolean().catch(false),
      customization: NavBarCustomizationSchema,
    })
    .passthrough()
    .transform((value) => {
      if (value.isPreview) {
        return {
          isPreview: true,
          customization: value.customization,
          user: {
            id: 1,
            firstname: "First",
            lastname: "Last",
            institution_id: null,
            isSFLite: false,
            preferences: {
              is_beta_user: false,
              email_subscriptions: {},
              acc_settings: DEFAULT_ACC_SETTINGS,
            },
          },
          isStudent: true,
          canSeeLTILinks: false,
          isViewAsClientSide: true,
          isViewingProgress: false,
          loginVia: "lti",
          viewAsGroup: { id: 1, name: "Group", course_id: null },
          viewAsScopes: ["none", "users"],
          viewAsUser: { id: 1, name: "Jane Doe" },
          pageWithoutNavigation: false,
        } satisfies NavBarProps;
      } else {
        return value;
      }
    })
    .pipe(NavBarPropsSchema);

  const result = PropsSchema.safeParse(unparsedProps);
  if (!result.success) {
    console.error(
      "Error initializing SF4NavBar",
      unparsedProps,
      result.error.errors
    );
    throw result.error;
  }
  const props = result.data;

  if (!props.user) return;

  if (!reactRoot) {
    reactRoot = createRoot(element);
  }

  // The Zustand AppStore must be created before rendering NavBar to ensure that React's scheduler
  // doesn't make this async. When it is async some things in SF3 break.
  const appStore = createAppStore({
    currentUser: {
      ...props.user,
      canSeeLTILinks: props.canSeeLTILinks,
    },
    isStudent: !!props.isStudent,
    viewAsUser: props.viewAsUser,
    viewAsGroup: props.viewAsGroup,
    isViewingProgress: props.isViewingProgress,
    timeZone: null,
    viewAsScopes: props.viewAsScopes ?? ["none"],
    isViewAsClientSide: props.isViewAsClientSide ?? false,
    isPreview: props.isPreview,
    pageWithoutNavigation: props.pageWithoutNavigation,
  });

  reactRoot.render(
    <div
      id="sf4-nav-bar"
      style={{ position: "relative", zIndex: props.isPreview ? "auto" : 200 }}
    >
      <TRPCProvider>
        <AppStoreProvider
          appStore={appStore}
          changeUrl={(url, { type }) => {
            if (url.startsWith("http")) {
              url = new URL(url).pathname + new URL(url).search;
            }
            if (url.startsWith(urlPrefix())) {
              url = url.slice(urlPrefix().length);
            }
            if (type === "push") {
              window.location.href = urlPrefix() + url;
            } else {
              window.history.replaceState(null, "", urlPrefix() + url);
            }
          }}
          isPreview={props.isPreview}
        >
          <ModalContextProvider>
            <TooltipProvider>
              <NavBar
                isStudent={!!props.isStudent}
                loginVia={props.loginVia}
                isPreview={props.isPreview}
                customization={props.customization}
                pageWithoutNavigation={props.pageWithoutNavigation}
              />
            </TooltipProvider>
          </ModalContextProvider>
        </AppStoreProvider>
      </TRPCProvider>
    </div>
  );
}

function urlPrefix(): string {
  let sci = document.location.href.search("sci") != -1 ? "/sci" : "";
  let release =
    window.location.pathname.match(/^\/release-[^\/]*(?=\/|$)/)?.[0] || "";
  let dev =
    document.location.href.search("app_dev.php") != -1 ? "/app_dev.php" : "";
  return sci + release + dev;
}
