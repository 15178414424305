"use client";

import {
  ComponentPropsWithoutRef,
  Ref,
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
} from "react";
import { tv } from "tailwind-variants";

export type CheckboxProps = {
  label?: string;
  indeterminate?: boolean;
  color?: "base" | "error";
  error?: string;
} & ComponentPropsWithoutRef<"input">;

const variants = tv({
  slots: {
    root: "relative flex items-center",
    input:
      "peer h-6 w-6 shrink-0 cursor-pointer appearance-none rounded border" +
      " disabled:cursor-default disabled:border-carbon-200 disabled:bg-carbon-200",
    label:
      "cursor-pointer pl-3 text-sm peer-disabled:cursor-default peer-disabled:text-carbon-400",
    icon: "pointer-events-none absolute left-3 top-2/4 -translate-x-2/4 -translate-y-2/4 opacity-0 peer-disabled:text-carbon-400",
  },
  variants: {
    color: {
      base: {
        input:
          "border-carbon-600 bg-carbon-50 checked:border-carbon-600 checked:bg-steel-900 hover:border-steel-900" +
          " focus:outline-1 focus:outline-offset-4 focus:outline-blue-700",
        icon: "text-steel-900",
      },
      error: {
        input: "border-red-300 bg-red-50 checked:bg-red-500",
        icon: "text-red-500",
      },
    },
  },
});

export const Checkbox = forwardRef(function Checkbox(
  {
    id: propsId,
    label,
    indeterminate = false,
    color: propsColor = "base",
    className,
    error,
    ...inputProps
  }: CheckboxProps,
  ref: Ref<HTMLInputElement>
) {
  const color = error ? "error" : propsColor;
  const styles = variants({ color: color });
  const checkboxRef = useRef<HTMLInputElement>(null);
  const fallbackId = useId();
  const id = propsId ?? fallbackId;

  // Exposing the checkbox's ref set internally
  useImperativeHandle(ref, () => {
    return checkboxRef.current as HTMLInputElement;
  });

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <div className={styles.root({ className })}>
      <input
        ref={checkboxRef}
        id={id}
        type="checkbox"
        className={styles.input()}
        {...inputProps}
      />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={styles.icon({
          className:
            "h-5 w-5 text-carbon-50 peer-checked:opacity-100 peer-indeterminate:opacity-0",
        })}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M17.9422 6.06717L7.94217 16.0672C7.88412 16.1253 7.81519 16.1714 7.73932 16.2028C7.66344 16.2343 7.58212 16.2505 7.49998 16.2505C7.41785 16.2505 7.33652 16.2343 7.26064 16.2028C7.18477 16.1714 7.11584 16.1253 7.05779 16.0672L2.68279 11.6922C2.56552 11.5749 2.49963 11.4158 2.49963 11.25C2.49963 11.0841 2.56552 10.9251 2.68279 10.8078C2.80007 10.6905 2.95913 10.6246 3.12498 10.6246C3.29083 10.6246 3.44989 10.6905 3.56717 10.8078L7.49998 14.7414L17.0578 5.18279C17.1751 5.06552 17.3341 4.99963 17.5 4.99963C17.6658 4.99963 17.8249 5.06552 17.9422 5.18279C18.0594 5.30007 18.1253 5.45913 18.1253 5.62498C18.1253 5.79083 18.0594 5.94989 17.9422 6.06717Z" />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={styles.icon({
          className: "h-[22px] w-[22px] peer-indeterminate:opacity-100",
        })}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M16.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM14.375 10.625H5.625C5.45924 10.625 5.30027 10.5592 5.18306 10.4419C5.06585 10.3247 5 10.1658 5 10C5 9.83424 5.06585 9.67527 5.18306 9.55806C5.30027 9.44085 5.45924 9.375 5.625 9.375H14.375C14.5408 9.375 14.6997 9.44085 14.8169 9.55806C14.9342 9.67527 15 9.83424 15 10C15 10.1658 14.9342 10.3247 14.8169 10.4419C14.6997 10.5592 14.5408 10.625 14.375 10.625Z" />
      </svg>

      {label && (
        <label htmlFor={id} className={styles.label()}>
          {label}
        </label>
      )}
    </div>
  );
});
