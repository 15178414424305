import { createOnKeyDownHandler } from "$components/features/nav/NavBar/lib";
import {
  EmptyList,
  ListItem,
} from "$components/shared/CourseNavigation/CourseNavigationComponents";
import { CourseNavigationList } from "$components/shared/CourseNavigation/CourseNavigationList";
import {
  CourseNavigationItem,
  useCourseNavigation,
} from "$hooks/useCourseNavigation";
import {
  addHrefOnNavigationItems,
  filterDataBySearchTerm,
  getFlatNavigationData,
} from "$lib/courseNavigation";
import { getRootSF3URL } from "$utils/appUtil";
import { useCombobox } from "downshift";
import { RefObject, useMemo, useState } from "react";
import { NavSearch } from "./NavCourseNavigationComponents";
import { NavItemActions } from "./NavItemActions";
import {
  comboboxStateReducerWithShallowCopy,
  onNavItemHighlightedIndexChanged,
} from "./lib";

export const LessonsLevelNavigation = ({
  chapterId,
  selected,
  onLessonUpdate,
  closeMenu,
  onGoBack,
}: {
  chapterId: number;
  selected?: { lessonId?: number; quizId?: number };
  onLessonUpdate: (item: CourseNavigationItem | null) => void;
  closeMenu: () => void;
  onGoBack: () => void;
}) => {
  const { data, level, selectedLevels } = useCourseNavigation({
    initial: { level: "lessons", chapterId },
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [otherOptionsOpenId, setOtherOptionsOpenId] = useState<number | null>(
    null
  );
  const completeData = useMemo(() => {
    const itemsWithHref = addHrefOnNavigationItems({ data, level });

    return filterDataBySearchTerm(itemsWithHref, searchTerm);
  }, [data, level, searchTerm]);
  const flattenedData = useMemo(
    () => getFlatNavigationData({ data: completeData }),
    [completeData]
  );
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const canEditCourse = (() => {
    if (selectedLevels.courses?.type === "course") {
      return selectedLevels.courses.canEdit;
    }
    return false;
  })();
  const combobox = useCombobox<CourseNavigationItem>({
    id: "nav-lesson-search",
    isOpen: true,
    items: flattenedData,
    highlightedIndex,

    onSelectedItemChange({ selectedItem }) {
      if (!selectedItem) return;

      if (selectedItem.type === "lesson") {
        onLessonUpdate(selectedItem);
      } else if (selectedItem.type === "quiz") {
        window.open(`${getRootSF3URL()}/assess/${selectedItem.id}`, "_self");
      }
    },
    onInputValueChange(changes) {
      setSearchTerm(changes.inputValue ?? "");
    },
    onHighlightedIndexChange: (changes) =>
      onNavItemHighlightedIndexChanged<CourseNavigationItem>(
        changes,
        setHighlightedIndex,
        otherOptionsOpenId
      ),
    onIsOpenChange(changes) {
      if (!changes.isOpen) {
        closeMenu();
      }
    },
    stateReducer: comboboxStateReducerWithShallowCopy,
  });
  const selectedItemIndex = flattenedData.findIndex(
    (item) =>
      item.type === combobox.selectedItem?.type &&
      item.id === combobox.selectedItem?.id
  );

  const inputProps = combobox.getInputProps();
  const originalOnKeyDown = inputProps.onKeyDown;
  inputProps.onKeyDown = undefined;
  const onKeyDown = createOnKeyDownHandler({
    subMenuOpen: !!otherOptionsOpenId,
    closeSubMenu: () => setOtherOptionsOpenId(null),
    onGoBack,
    closeMenu,
    getItemProps: combobox.getItemProps,
    totalItems: flattenedData.length,
    highlightedIndex,
    setHighlightedIndex,
    selectedItemIndex,
    originalOnKeyDown,
  });

  return (
    <div
      className={`nav-dropdown outline-0`}
      data-testid="nav-lesson-navigation-dropdown"
      onKeyDownCapture={onKeyDown}
      tabIndex={-1}
    >
      {combobox.isOpen && <NavSearch {...inputProps} />}
      <ul {...combobox.getMenuProps()}>
        <CourseNavigationList
          data={completeData}
          emptyListNode={<EmptyList data={data} level={level} />}
          listItemNode={(item, index) => {
            const highlighted = highlightedIndex === index;

            return (
              <li className="relative">
                <ListItem
                  highlighted={highlighted}
                  displayName={item.displayName}
                  searchTerm={searchTerm}
                  href={(item as any).href}
                  selected={
                    (item.type === "lesson" &&
                      item.id === selected?.lessonId) ||
                    (item.type === "quiz" && item.id === selected?.quizId)
                  }
                  {...combobox.getItemProps({ item, index })}
                />
                <NavItemActions
                  item={item}
                  level={level}
                  isHighlighted={highlighted}
                  otherOptionsOpen={otherOptionsOpenId === item.id}
                  setOtherOptionsOpen={setOtherOptionsOpenId}
                  canEditCourse={canEditCourse}
                />
              </li>
            );
          }}
        />
      </ul>
    </div>
  );
};
