import {
  CourseNavigation,
  CourseNavigationData,
  CourseNavigationItem,
} from "$hooks/useCourseNavigation";
import { Fragment, ReactNode } from "react";

export type CourseNavigationListProps = {
  data: CourseNavigation["data"];
  listItemNode: (item: CourseNavigationItem, index: number) => ReactNode;
  backNode?: ReactNode;
  emptyListNode?: ReactNode;
};

export const CourseNavigationList = ({
  data,
  listItemNode,
  backNode,
  emptyListNode,
}: CourseNavigationListProps) => {
  if (!data) {
    return (
      <div className="py-2">
        <div className="h-36 pt-5 pl-8">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (listIsEmpty(data)) {
    return (
      <div className="py-2">
        {backNode}
        {emptyListNode}
      </div>
    );
  }

  return (
    <div className="py-2">
      {backNode}
      {
        data.reduce(
          (results, section, index) => {
            if (!Array.isArray(results.sections) || section.list.length === 0) {
              return results;
            }

            results.sections.push(
              <div key={section.title + index}>
                <p className="px-8 py-2 text-base font-semibold text-carbon-900">
                  {section.title}
                </p>

                {section.list.map((item) => {
                  const resultIndex = results.itemIndex++;

                  return (
                    <Fragment key={item.id}>
                      {listItemNode(item, resultIndex)}
                    </Fragment>
                  );
                })}
              </div>
            );

            return results;
          },
          {
            sections: [] as ReactNode,
            itemIndex: !!backNode ? 1 : 0,
          }
        ).sections
      }
    </div>
  );
};

function listIsEmpty(data: CourseNavigationData) {
  return data.reduce((acc, cur) => acc + cur.list.length, 0) === 0;
}
