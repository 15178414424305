import { Checkbox } from "$components/shared/Form/Checkbox";
import { Select } from "$components/shared/Form/Select";
import {
  AccessibilitySettings,
  VideoReadingPreference,
} from "$lib/accessibilitySettings";
import { tv } from "tailwind-variants";

const accOptionsVariants = tv({
  slots: {
    sectionHeading: "px-8 py-2 text-base font-semibold text-carbon-900",
    controlGroupContainer:
      "flex w-full flex-row flex-nowrap justify-between px-8",
    label: "max-w-[70%]",
    select:
      "acc-control z-50 appearance-none overflow-hidden rounded border border-carbon-200 bg-carbon-50 shadow-md",
  },
});

export const AccessibilityOptions = ({
  accSettings,
  selectRef,
  updateSettings,
}: {
  accSettings: AccessibilitySettings;
  selectRef: React.RefObject<HTMLButtonElement>;
  updateSettings: (settings: AccessibilitySettings) => void;
}) => {
  const styles = accOptionsVariants();

  const handleAccessibilityModeOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateSettings({
      ...accSettings,
      accessibilityMode: e.target.checked,
    });
  };

  const handleHighContrastModeOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateSettings({
      ...accSettings,
      highContrastMode: e.target.checked,
    });
  };

  const handleVideoReadingPreferenceOnChange = (newVal: string) => {
    const selected = Object.entries(VIDEO_READING_PREFERENCE_OPTIONS).find(
      ([key, option]) => option.value === newVal
    );
    updateSettings({
      ...accSettings,
      videoReadingPreference: (selected
        ? selected[1].label.toLowerCase()
        : "default") as VideoReadingPreference,
    });
  };

  return (
    <>
      <p className={styles.sectionHeading()}>Accessibility Mode</p>
      <div className={styles.controlGroupContainer()}>
        <span className={styles.label()}>
          Disables all themes and additional styles for learning objects
        </span>
        <Checkbox
          className="acc-control"
          checked={accSettings.accessibilityMode}
          onChange={handleAccessibilityModeOnChange}
        />
      </div>

      <p className={styles.sectionHeading()}>White Text on Black Background</p>
      <div className={styles.controlGroupContainer()}>
        <span className={styles.label()}>
          Changes text in reading learning objects to white text on a black
          background
        </span>
        <Checkbox
          className="acc-control"
          checked={accSettings.highContrastMode}
          onChange={handleHighContrastModeOnChange}
        />
      </div>

      <p className={styles.sectionHeading()}>
        Video/Reading Alternate Versions
      </p>
      <div className={styles.controlGroupContainer()}>
        <span className={styles.label()}>
          When the same content is available as either a reading or a video,
          which version would you like to see?
        </span>
        <Select
          ref={selectRef}
          className="acc-control"
          options={Object.entries(VIDEO_READING_PREFERENCE_OPTIONS).map(
            ([key, value]) => value
          )}
          value={
            VIDEO_READING_PREFERENCE_OPTIONS[accSettings.videoReadingPreference]
              .value
          }
          onChange={handleVideoReadingPreferenceOnChange}
        />
      </div>
    </>
  );
};

const VIDEO_READING_PREFERENCE_OPTIONS = {
  default: { label: "Default", value: "1" },
  video: { label: "Video", value: "2" },
  reading: { label: "Reading", value: "3" },
} as const;
