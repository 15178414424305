import { RouterInputs, RouterOutputs, api } from "$lib/api";
import { transformViewAsSearchData } from "$lib/navbar";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "../useDebounce";
import { useInitialSetup } from "./dataHooks";
import { useViewingAs } from "./useViewingAs";

type ViewAsSearchResult = RouterOutputs["navbar"]["searchViewAs"][number];
export type UserSelectionData = Array<{
  title: string;
  list: UserSelectionItem[];
}>;

export type UserSelectionItem =
  | {
      id: number;
      name: string;
      type: Exclude<ViewAsSearchResult["type"], "Group"> | "Extra";
      fromSearch: false;
    }
  | {
      id: number;
      name: string;
      course_id: number | null;
      type: "Group";
      fromSearch: false;
    }
  | (ViewAsSearchResult & { fromSearch: true });
export type UserSelection = {
  level: "partners" | "institutions" | "groups" | "students" | "search" | null;
  selectedLevels: {
    partner?: {
      id: number;
      name: string;
    };
    institution?: {
      id: number;
      name: string;
    };
    group?: {
      id: number;
      name: string;
      course_id: number | null;
    };
  };
  higherLevel: "partners" | "institutions" | "groups";
  data: UserSelectionData | null;
  onBack: () => void;
  onItemSelected: (args: UserSelectionItem) => void;
};

export type Initial = number | null;

export function useUserSelection({
  viewingAs,
  searchTerm = "",
}: {
  searchTerm?: string;
  viewingAs: ReturnType<typeof useViewingAs>;
}): UserSelection {
  const debouncedSearchTerm = useDebounce(searchTerm ?? "", 500);
  const [queryInput, setQueryInput] =
    useState<RouterInputs["navbar"]["loadViewAsContent"]>();
  const [higherLevel, setHigherLevel] =
    useState<UserSelection["higherLevel"]>("groups");
  const [level, setLevel] = useState<UserSelection["level"]>(null);
  const levelBeforeSearchRef = useRef(level);
  const [selectedLevels, setSelectedLevels] = useState<
    UserSelection["selectedLevels"]
  >({});

  const { data: initialData } = api.navbar.loadViewAsContent.useQuery(
    undefined,
    { staleTime: Infinity }
  );
  const { data: userContexts } = api.navbar.loadViewAsContent.useQuery(
    queryInput,
    { staleTime: Infinity }
  );
  // searchTerm is sure to be a valid string because of the enabled flag
  const { data: searchData } = api.navbar.searchViewAs.useQuery(
    { query: debouncedSearchTerm! },
    { enabled: !!debouncedSearchTerm }
  );

  useInitialSetup(
    higherLevel,
    viewingAs,
    setSelectedLevels,
    setQueryInput,
    setLevel
  );

  useEffect(() => {
    if (searchTerm.length > 0 && level !== "search") {
      levelBeforeSearchRef.current = level;
      setLevel("search");
    } else if (searchTerm.length === 0 && level === "search") {
      setLevel(levelBeforeSearchRef.current);
    }
  }, [searchTerm, level, higherLevel]);

  useEffect(() => {
    if (!initialData) return;

    // Set the higher level the user has access to
    const higherLevel =
      initialData.level !== "students" ? initialData.level : "groups";
    setHigherLevel(higherLevel);
    setLevel((prevLevel) => {
      return prevLevel ?? higherLevel;
    });
  }, [initialData]);

  const data = useMemo(() => {
    if (!userContexts) {
      return null;
    }

    if (!!searchTerm) {
      return transformViewAsSearchData(searchData);
    }

    return [
      {
        title: "Level Results",
        list: userContexts.entities.map((e) => ({
          ...e,
          type: getTypeFromLevel(level),
          fromSearch: false,
        })),
      },
    ] as UserSelection["data"];
  }, [userContexts, searchTerm, searchData, level]);

  const onBack = useCallback(() => {
    const previousLevel = getPreviousLevel(level, higherLevel);
    if (!previousLevel) return;

    setLevel(previousLevel);
    setQueryInput(() => {
      switch (previousLevel) {
        case "partners":
          return undefined;

        case "institutions":
          return selectedLevels.partner?.id
            ? { partnerId: selectedLevels.partner.id }
            : undefined;

        case "groups":
          return selectedLevels.institution?.id
            ? { institutionId: selectedLevels.institution.id }
            : undefined;

        case "students":
          return selectedLevels.group?.id
            ? { groupId: selectedLevels.group.id }
            : undefined;
      }
    });
  }, [level, higherLevel, selectedLevels]);

  const onItemSelected = useCallback(
    (args: UserSelectionItem) => {
      if (args.fromSearch) {
        switch (args.type) {
          case "Partner":
            setQueryInput({ partnerId: args.id });
            setSelectedLevels({ partner: { id: args.id, name: args.name } });
            setLevel("institutions");
            break;

          case "Institution":
            setQueryInput({ institutionId: args.id });
            setSelectedLevels({
              partner: args.breadcrumbs.partner,
              institution: { id: args.id, name: args.name },
            });
            setLevel("groups");
            break;

          case "Group":
            setQueryInput({ groupId: args.id });
            setSelectedLevels({
              partner: args.breadcrumbs.partner,
              institution: args.breadcrumbs.institution,
              group: {
                id: args.id,
                name: args.name,
                course_id: args.course_id,
              },
            });
            setLevel("students");
            break;
        }
      } else {
        const nextLevel = getNextLevel(level);
        if (!nextLevel) return;

        setLevel(nextLevel);
        switch (nextLevel) {
          case "institutions":
            setQueryInput({ partnerId: args.id });
            setSelectedLevels((prev) =>
              args.id !== prev.partner?.id ? { partner: args } : prev
            );
            break;

          case "groups":
            setQueryInput({ institutionId: args.id });
            setSelectedLevels((prev) =>
              args.id !== prev.institution?.id
                ? {
                    partner: prev.partner,
                    institution: args,
                  }
                : prev
            );
            break;

          case "students":
            setQueryInput({ groupId: args.id });
            setSelectedLevels((prev) =>
              args.id !== prev.group?.id
                ? {
                    partner: prev.partner,
                    institution: prev.institution,
                    group: {
                      id: args.id,
                      name: args.name,
                      course_id: args.type === "Group" ? args.course_id : null,
                    },
                  }
                : prev
            );
            break;
        }
      }
    },
    [level]
  );

  return {
    level,
    selectedLevels,
    higherLevel,
    data,
    onBack,
    onItemSelected,
  };
}

function getNextLevel(
  level: UserSelection["level"]
): UserSelection["level"] | null {
  switch (level) {
    case "partners":
      return "institutions";

    case "institutions":
      return "groups";

    case "groups":
      return "students";

    default:
      return null;
  }
}

export function getPreviousLevel(
  level: UserSelection["level"],
  higherLevel: UserSelection["higherLevel"]
): UserSelection["level"] | null {
  switch (level) {
    case "students":
      return "groups";

    case "groups":
      if (higherLevel !== "groups") {
        return "institutions";
      }
      return null;

    case "institutions":
      if (higherLevel === "partners") {
        return "partners";
      }
      return null;

    default:
      return null;
  }
}

function getTypeFromLevel(
  level: UserSelection["level"]
): UserSelectionItem["type"] {
  switch (level) {
    case "partners":
      return "Partner";

    case "institutions":
      return "Institution";

    case "groups":
      return "Group";

    case "students":
      return "User";

    default:
      return "Extra";
  }
}
