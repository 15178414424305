import { Ref, SVGProps, forwardRef } from "react";


const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M-2.84124e-07 6.5L6.67592 -5.78773e-08L8 1.28919L2.62099 6.5L7.97283 11.7108L6.64875 13L-2.84124e-07 6.5Z"
      fill="currentColor"
    />
  </svg>
);

const LessThanIcon = forwardRef(SvgComponent);
export { LessThanIcon };