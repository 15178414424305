import { portalContainer } from "$components/features/nav/NavBar/portalContainer";
import { useAppStore } from "$hooks/useAppStore";
import { api } from "$lib/api";
import { getBaseMenuItems } from "$lib/navbar";
import { STALE_TIME } from "$lib/reactQuery";
import { getRootSF3URL } from "$utils/appUtil";
import { mdiClose, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import cn from "classnames";
import React, { forwardRef, useMemo, useState } from "react";
import { NavLink } from "./NavLink";
import { useOnThisPageMenuItems } from "./useOnThisPageMenuItems";

const rootSF3URL = getRootSF3URL();

export const NavMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useAppStore((state) => state.currentUser);
  const isStudent = useAppStore((state) => state.isStudent);
  const { data: basePermissions } = api.navbar.loadPermissions.useQuery(
    undefined,
    { staleTime: STALE_TIME.INFINITE }
  );
  const onThisPageItems = useOnThisPageMenuItems();

  // Remove items from the menuItems if the list has zero items
  const filteredBaseList = useMemo(() => {
    if (!basePermissions || !user) return [];
    const baseMenuItems = getBaseMenuItems({
      institutionId: user.institution_id,
    });

    const clone: typeof baseMenuItems = [];
    for (const menuItem of baseMenuItems) {
      const filteredList = menuItem.list.filter((listItem) => {
        if (listItem.permission === null) {
          return isStudent ? listItem.studentOnly : !listItem.nonStudentOnly;
        }
        return (
          basePermissions.permissions.includes(listItem.permission) &&
          (isStudent ? listItem.studentOnly : !listItem.nonStudentOnly)
        );
      });
      if (filteredList.length > 0) {
        clone.push({ ...menuItem, list: filteredList });
      }
    }
    return clone;
  }, [basePermissions, isStudent, user]);

  return (
    <DropdownMenu.Root modal={false} open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenu.Trigger asChild>
        <button
          aria-label="open main navigation"
          className="nav-control flex w-9 min-w-7 cursor-pointer items-center justify-center"
          onKeyDownCapture={(e) => {
            if (e.key === "Enter" || e.key === "ArrowDown") {
              setMenuOpen(false);
            }
          }}
        >
          <Icon path={menuOpen ? mdiClose : mdiMenu} className="h-5 w-5" />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal container={portalContainer()}>
        <DropdownMenu.Content
          align="end"
          className="z-20 flex max-h-[var(--radix-dropdown-menu-content-available-height)] flex-col overflow-auto bg-neutral-700 pb-2 pt-5 text-white"
          onKeyDownCapture={(e) => {
            if (e.key === "Escape" || e.key === "Tab") {
              setMenuOpen(false);
            }
          }}
        >
          {onThisPageItems.length > 0 && (
            <>
              <DropdownMenu.Label className="pb-1.5 pl-7 text-lg">
                On This Page
              </DropdownMenu.Label>
              {onThisPageItems.map((item) => {
                if (item.href) {
                  return (
                    <MenuItem key={item.name} asChild>
                      <NavLink
                        href={item.href}
                        target={item.openInNewPage ? "_blank" : "_self"}
                      >
                        {item.name}
                      </NavLink>
                    </MenuItem>
                  );
                }

                return (
                  <MenuItem key={item.name} onClick={item.onClick}>
                    {item.name}
                  </MenuItem>
                );
              })}
              <DropdownMenu.Separator className="mx-7 my-4 min-h-px bg-white" />
            </>
          )}
          {filteredBaseList.map(({ title, list }, index) => {
            return (
              <React.Fragment key={title}>
                <DropdownMenu.Label className="pb-1.5 pl-7 text-lg">
                  {title}
                </DropdownMenu.Label>
                {list.map((item) => (
                  <MenuItem
                    key={item.permission}
                    asChild
                    btnStyle={item.btnStyle}
                  >
                    <NavLink href={item.href} target="_self">
                      {item.name}
                    </NavLink>
                  </MenuItem>
                ))}
                {isStudent && index === filteredBaseList.length - 1 ? null : (
                  <DropdownMenu.Separator className="mx-7 my-4 min-h-px bg-white" />
                )}
              </React.Fragment>
            );
          })}
          {!isStudent && (
            <>
              <MenuItem asChild btnStyle>
                <a href="https://help.studyforge.net/knowledge" target="_blank">
                  Support Hub
                </a>
              </MenuItem>
              <MenuItem asChild>
                <a
                  href={rootSF3URL + "/accountsettings/profile"}
                  target="_self"
                >
                  Account Settings
                </a>
              </MenuItem>
            </>
          )}

          {!isStudent && (
            <MenuItem asChild btnStyle={isStudent}>
              <a href={rootSF3URL + "/logout"} target="_self">
                Logout
              </a>
            </MenuItem>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

const MenuItem = forwardRef<
  HTMLDivElement,
  DropdownMenu.DropdownMenuItemProps & { btnStyle?: boolean }
>(({ btnStyle, ...itemProps }, ref) => (
  <DropdownMenu.Item
    ref={ref}
    className={cn(
      "cursor-pointer select-none px-12 py-1.5 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-neutral-500",
      btnStyle &&
        "mx-7 mb-4 flex h-10 items-center justify-center border border-white"
    )}
    {...itemProps}
  />
));
MenuItem.displayName = "MenuItem";
