import { ArticleIcon } from "$components/shared/Icons/ArticleIcon";
import { UserIcon } from "$components/shared/Icons/UserIcon";
import { UsersThreeIcon } from "$components/shared/Icons/UsersThreeIcon";
import { Tooltip } from "$components/shared/Tooltip";
import { useAppStore } from "$hooks/useAppStore";
import { portalContainer } from "./portalContainer";

export function NavViewingAsToggle() {
  const currentUser = useAppStore((state) => state.currentUser);
  const isViewingProgress = useAppStore((state) => state.isViewingProgress);
  const viewAsScopes = useAppStore((state) => state.viewAsScopes);
  const viewAsGroup = useAppStore((state) => state.viewAsGroup);
  const viewAsUser = useAppStore((state) => state.viewAsUser);
  const setIsViewingProgress = useAppStore(
    (state) => state.setIsViewingProgress
  );
  const setViewAsUser = useAppStore((state) => state.setViewAsUser);

  const tooltipTitle = (() => {
    if (isViewingProgress) return "View Content";

    if (viewAsUser && viewAsScopes.includes("users")) {
      if (viewAsUser.id === currentUser?.id) {
        return "View your progress";
      } else {
        return `View as ${viewAsUser.name}`;
      }
    }
    if (viewAsGroup && viewAsScopes.includes("groups"))
      return `View as ${viewAsGroup.name}`;
    if (viewAsScopes.includes("users")) return "View your progress";

    return "";
  })();

  const Icon = () => {
    if (isViewingProgress) return <ArticleIcon className="w-5" />;
    if (viewAsGroup && viewAsScopes.includes("groups"))
      return <UsersThreeIcon className="mx-px w-[18px]" />;

    return <UserIcon className="mx-px w-[18px]" />;
  };

  const handleClick = () => {
    setIsViewingProgress(!isViewingProgress);
    if (!isViewingProgress && !viewAsUser && !viewAsGroup && currentUser) {
      setViewAsUser({
        user: {
          id: currentUser.id,
          name: currentUser.firstname + " " + currentUser.lastname,
        },
      });
    }
  };

  // If there is no recent "view as" selection and the view as scopes does not include "users",
  // then the button should be hidden, forcing the user to select a group via the view as menu
  if (!tooltipTitle) {
    return null;
  }

  // Page scope must include "none" plus at least one other scope
  if (!viewAsScopes.includes("none") || viewAsScopes.length <= 1) {
    return null;
  }

  return (
    <div className="border-r border-anvil-100">
      <Tooltip
        title={tooltipTitle}
        portalProps={{
          container: portalContainer(),
        }}
        size="sm"
      >
        <button className="nav-control h-full px-2" onClick={handleClick}>
          <Icon />
        </button>
      </Tooltip>
    </div>
  );
}
