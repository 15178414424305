import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 27"
      width="27"
      fill="none"
      stroke="currentColor"
      ref={ref}
      {...props}
    >
      <circle strokeWidth={2} cx="13.5" cy="13.5" r="12.5" />
      <path d="M13.5,16a1.32,1.32,0,0,1-1.3-1.3V13.2a1.32,1.32,0,0,1,1.3-1.3,1.75,1.75,0,0,0,1.7-1.7,1.7,1.7,0,0,0-3.4,0,1.3,1.3,0,0,1-2.6,0,4.3,4.3,0,1,1,8.6,0,4.42,4.42,0,0,1-3,4.1v.4A1.26,1.26,0,0,1,13.5,16Z" />
      <path d="M13.5,20.9a1.32,1.32,0,0,1-1.3-1.3v-.8a1.3,1.3,0,0,1,2.6,0v.8A1.26,1.26,0,0,1,13.5,20.9Z" />
    </svg>
  );
};

export const SearchQuestionIcon = forwardRef(SvgComponent);
