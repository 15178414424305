import {
  CourseNavigation,
  CourseNavigationData,
  CourseNavigationItem,
} from "$hooks/useCourseNavigation";
import { checkIsAssessment } from "$hooks/useCourseNavigation/lib";
import { getRootSF3URL } from "$utils/appUtil";
import {
  UseComboboxState,
  UseComboboxStateChangeOptions,
  useCombobox,
} from "downshift";

export function getFlatNavigationData({
  data,
  back,
  excludeTypes,
}: {
  back?: string;
  excludeTypes?: CourseNavigationItem["type"][];
} & Pick<CourseNavigation, "data">) {
  if (!data) return [];

  let list = data.reduce(
    (prev, cur) => {
      return [...prev, ...cur.list];
    },
    [] as CourseNavigationData[number]["list"]
  );
  if (Array.isArray(excludeTypes)) {
    list = list.filter((item) => !excludeTypes.includes(item.type));
  }
  if (back) {
    list.unshift({
      type: "back",
      id: -1,
      name: back,
      displayName: back,
    });
  }

  return list;
}

export function getBackTitle(
  level: CourseNavigation["level"],
  selectedLevels: CourseNavigation["selectedLevels"]
) {
  switch (level) {
    case "course_streams":
      return selectedLevels.regions
        ? `Region: ${selectedLevels.regions.name}`
        : "Regions";

    case "courses":
    case "sample_courses":
    case "private_courses":
      return "Course Streams";

    case "chapters":
      return selectedLevels.courses ? selectedLevels.courses.name : "Course";

    case "lessons":
      return selectedLevels.chapters
        ? (selectedLevels.chapters.displayName as string)
        : "Chapter";

    case "search":
      return "Back";

    default:
      return "";
  }
}

export function filterDataBySearchTerm(
  data: CourseNavigationData | null,
  searchTerm: string
) {
  if (!data) return null;
  const getItemTerm = (item: CourseNavigationItem) => {
    if (Array.isArray(item.displayName)) return item.displayName.join(" ");
    return item.displayName;
  };

  const clone: typeof data = [];
  for (const group of data) {
    clone.push({
      ...group,
      list: group.list.filter((item) => {
        const itemTerm = getItemTerm(item);
        return itemTerm.toLowerCase().includes(searchTerm.toLowerCase());
      }),
    });
  }
  return clone;
}

export function getNavigationComboboxStateReducer({
  multiple,
  onSelectItem,
}: {
  multiple?: boolean;
  onSelectItem?: (item: CourseNavigationItem) => void;
} = {}) {
  return (
    state: UseComboboxState<any>,
    options: UseComboboxStateChangeOptions<any>
  ) => {
    const { type, changes } = options;
    switch (type) {
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick:
        onSelectItem?.(changes.selectedItem);
        return {
          ...changes,
          // keep menu open after selection
          isOpen: true,
          // If the selected item is an assessment, prevent the dropdown from scrolling
          highlightedIndex: checkIsAssessment(changes.selectedItem)
            ? state.highlightedIndex
            : 1,
          // clear the query when the user selects an item, unless it's a multiple select combobox
          inputValue: multiple ? state.inputValue : "",
        };
      case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        return {
          ...changes,
          inputValue: multiple ? state.inputValue : changes.inputValue,
        };
      case useCombobox.stateChangeTypes.InputBlur:
        return { ...changes, inputValue: "" };
      default:
        return changes;
    }
  };
}

export function addHrefOnNavigationItems({
  data,
  level,
}: Pick<CourseNavigation, "data" | "level">): Array<{
  title: string;
  list: Array<CourseNavigationItem & { href?: string }>;
}> | null {
  if (!data || (level !== "chapters" && level !== "lessons")) {
    return data;
  }

  const clone = [];
  for (const section of data) {
    clone.push({
      ...section,
      list: section.list.map<CourseNavigationItem & { href?: string }>(
        (item) => {
          switch (item.type) {
            case "project":
              return { ...item, href: `${getRootSF3URL()}/project/${item.id}` };

            case "lesson":
              return { ...item, href: `${getRootSF3URL()}/lesson/${item.id}` };

            case "quiz":
              return { ...item, href: `${getRootSF3URL()}/assess/${item.id}` };

            default:
              return item;
          }
        }
      ),
    });
  }
  return clone;
}
