import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 27"
      width="27"
      fill="currentColor"
      stroke="currentColor"
      ref={ref}
      {...props}
    >
      <circle strokeWidth={2} fill="none" cx="13.5" cy="13.5" r="12.5" />
      <path fill="none" d="M12.75,20h-4V7.45h9.4v6.2" />
      <path fill="none" d="M18.15,18.55v1.39h-.8" />
      <path stroke="none" d="M20.25,14.75,14.65,21h0l-1.3.6.5-1.3h0l5.5-6.2Z" />
    </svg>
  );
};

export const SearchLessonIcon = forwardRef(SvgComponent);
