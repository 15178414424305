import { CourseNavigation } from "$hooks/useCourseNavigation";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { ElementRef, MouseEventHandler, ReactNode, forwardRef } from "react";
import { tv } from "tailwind-variants";

export const ListBack = forwardRef<
  ElementRef<typeof ListItem>,
  { back: string } & Omit<ListItemProps, "children" | "displayName">
>(({ back, ...listItemProps }, ref) => {
  if (!back) {
    return null;
  }

  return (
    <>
      <ListItem ref={ref} {...listItemProps}>
        <p className="flex items-center">
          <Icon path={mdiChevronLeft} className="h-6 w-6" />
          <span>{back}</span>
        </p>
      </ListItem>
      <div className="mx-7 my-2.5 border-b border-b-carbon-400" />
    </>
  );
});
ListBack.displayName = "ListBack";

export const EmptyList = ({
  data,
  level,
}: Pick<CourseNavigation, "data" | "level">) => {
  function getEmptyListText() {
    if (!data) return "";

    const listsLength = data.reduce((acc, cur) => acc + cur.list.length, 0);
    if (listsLength === 0) {
      switch (level) {
        case "search":
          return "No Results Found";

        case "sample_courses":
        case "private_courses":
          return "No Courses Found";

        default:
          return "Empty List";
      }
    } else {
      return "";
    }
  }

  return (
    <div>
      <p className="px-8 py-2 text-base font-semibold">
        {data?.[0]?.title ?? "Results"}
      </p>
      <p className="py-1.5 pl-8 pr-4 text-sm text-carbon-800">
        {getEmptyListText()}
      </p>
    </div>
  );
};

type ListItemProps = {
  highlighted: boolean;
  displayName?: string | string[];
  searchTerm?: string;
  selected?: boolean;
  subItem?: boolean;
  href?: string;
  onClick?: MouseEventHandler;
  children?: ReactNode;
  startDecorator?: ReactNode;
};

const listItemVariants = tv({
  base: "group relative flex cursor-pointer items-center justify-start py-1.5 pl-8 pr-8 text-sm text-carbon-800 aria-disabled:text-carbon-300",
  variants: {
    highlighted: {
      true: "nav-item-highlighted bg-carbon-50",
    },
    selected: {
      true: "font-bold",
    },
    subItem: {
      true: "bg-carbon-800 text-white hover:bg-carbon-900",
    },
  },
});
export const ListItem = forwardRef<any, ListItemProps>(
  (
    {
      highlighted,
      selected,
      subItem,
      displayName,
      searchTerm,
      href,
      children,
      startDecorator,
      ...rest
    },
    ref
  ) => {
    const Comp = href ? "a" : "div";
    const extra = href ? { target: "_self" } : {};
    const BaseLabel = () => {
      if (!displayName) return null;
      return (
        <>
          {Array.isArray(displayName) ? (
            <p className="flex items-center">
              <span>{displayName[0]}</span>
              <Icon path={mdiChevronRight} size={1} />
              <span>{displayName[1]}</span>
            </p>
          ) : (
            <HighlightedText termToHighlight={searchTerm} text={displayName} />
          )}
        </>
      );
    };

    return (
      <Comp
        ref={ref}
        className={listItemVariants({ highlighted, selected, subItem })}
        href={href}
        tabIndex={-1}
        {...extra}
        {...rest}
      >
        {startDecorator}
        <BaseLabel />
        {children}
      </Comp>
    );
  }
);
ListItem.displayName = "ListItem";

interface HighlightedTextProps {
  termToHighlight: string | undefined;
  text: string;
}

export const HighlightedText = ({
  text,
  termToHighlight,
}: HighlightedTextProps) => {
  const parts = text.split(new RegExp(`(${termToHighlight})`, "gi"));

  if (!termToHighlight) return <p>{text}</p>;

  return (
    <p>
      {parts.map((part, i) =>
        part.toLowerCase() === termToHighlight.toLowerCase() ? (
          <span key={i} className="font-bold italic">
            {part}
          </span>
        ) : (
          part
        )
      )}
    </p>
  );
};
