import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

const NodeEnv = z
  .union([z.literal("development"), z.literal("production")])
  .optional();

// Not only does this give type safe access to environment variables, but it also
// checks all environment variables are defined at build time. This means that
// if you forget to define an environment variable, the build will fail.
// @see https://env.t3.gg/docs/nextjs for details on this setup
export const env = createEnv({
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
  server: {
    DATABASE_URL: z.string().url(),
    REDIS_URL: z.string().url(),
    LEARNOSITY_CLIENT_ID: z.string().min(1),
    LEARNOSITY_SECRET: z.string().min(1),
    LEARNOSITY_ORGANISATION_ID: z
      .union([
        z.literal("468"),
        z.literal("550"),
        z.literal(468),
        z.literal(550),
      ])
      .transform(Number),
    LTI_PRIVATE_KEY_PATH: z.string().min(1),
    LTI_PUBLIC_KEY_PATH: z.string().min(1),
    LTI_PRIVATE_KEY_PASS: z.string().min(1),
    LTI_KEY_ID: z.string().min(1),
    NODE_ENV: NodeEnv,
    MYSQL_LOG_DATABASE: z.string().min(1),
    TZ: z.string(),
  },
  client: {
    NEXT_PUBLIC_APP_URL: z.string().url(),
    NEXT_PUBLIC_LEARNOSITY_API_VERSION: z.string().min(1),
    NEXT_PUBLIC_NODE_ENV: NodeEnv,
  },
  // If you're using Next.js < 13.4.4, you'll need to specify the runtimeEnv manually:
  // runtimeEnv: { ... },
  // For Next.js >= 13.4.4, you only need to destructure client variables:
  experimental__runtimeEnv: {
    NEXT_PUBLIC_NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NEXT_PUBLIC_LEARNOSITY_API_VERSION:
      process.env.NEXT_PUBLIC_LEARNOSITY_API_VERSION,
  },
});
