import { Ref, forwardRef } from "react";
import { IconProps, IconWeight } from "./index";

const SvgComponent = (
  {
    title,
    weight = "regular",
    ...svgProps
  }: IconProps & { weight?: IconWeight },
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    ref={ref}
    {...svgProps}
  >
    {title && <title>{title}</title>}
    <Content weight={weight} />
    <path d="M28.8648 26.5C26.9611 23.2087 24.0273 20.8487 20.6036 19.73C22.2971 18.7218 23.6129 17.1856 24.3489 15.3572C25.0848 13.5289 25.2002 11.5095 24.6774 9.60918C24.1546 7.70887 23.0224 6.03272 21.4548 4.83814C19.8872 3.64355 17.9708 2.99658 15.9999 2.99658C14.0289 2.99658 12.1125 3.64355 10.5449 4.83814C8.97725 6.03272 7.84509 7.70887 7.32228 9.60918C6.79946 11.5095 6.91489 13.5289 7.65083 15.3572C8.38678 17.1856 9.70256 18.7218 11.3961 19.73C7.97235 20.8475 5.0386 23.2075 3.13485 26.5C3.06504 26.6138 3.01873 26.7405 2.99866 26.8725C2.97859 27.0045 2.98517 27.1392 3.01801 27.2687C3.05084 27.3981 3.10927 27.5197 3.18984 27.6262C3.27041 27.7326 3.3715 27.8219 3.48713 27.8887C3.60277 27.9555 3.73061 27.9985 3.86311 28.015C3.99561 28.0316 4.1301 28.0215 4.25863 27.9853C4.38716 27.949 4.50713 27.8874 4.61146 27.8041C4.71579 27.7207 4.80238 27.6173 4.8661 27.5C7.2211 23.43 11.3836 21 15.9999 21C20.6161 21 24.7786 23.43 27.1336 27.5C27.1973 27.6173 27.2839 27.7207 27.3882 27.8041C27.4926 27.8874 27.6125 27.949 27.7411 27.9853C27.8696 28.0215 28.0041 28.0316 28.1366 28.015C28.2691 27.9985 28.3969 27.9555 28.5126 27.8887C28.6282 27.8219 28.7293 27.7326 28.8099 27.6262C28.8904 27.5197 28.9489 27.3981 28.9817 27.2687C29.0145 27.1392 29.0211 27.0045 29.001 26.8725C28.981 26.7405 28.9347 26.6138 28.8648 26.5ZM8.99985 12C8.99985 10.6155 9.41039 9.26214 10.1796 8.11099C10.9487 6.95985 12.042 6.06264 13.3211 5.53283C14.6001 5.00301 16.0076 4.86439 17.3655 5.13449C18.7234 5.40458 19.9706 6.07127 20.9496 7.05023C21.9286 8.0292 22.5953 9.27648 22.8653 10.6344C23.1354 11.9922 22.9968 13.3997 22.467 14.6788C21.9372 15.9578 21.04 17.0511 19.8888 17.8203C18.7377 18.5894 17.3843 19 15.9999 19C14.1439 18.998 12.3646 18.2599 11.0523 16.9475C9.73997 15.6352 9.00184 13.8559 8.99985 12Z" />
  </svg>
);

const Content = ({ weight }: { weight: IconWeight }) => {
  switch (weight) {
    case "regular":
      return (
        <path d="M28.8648 26.5C26.9611 23.2087 24.0273 20.8487 20.6036 19.73C22.2971 18.7218 23.6129 17.1856 24.3489 15.3572C25.0848 13.5289 25.2002 11.5095 24.6774 9.60918C24.1546 7.70887 23.0224 6.03272 21.4548 4.83814C19.8872 3.64355 17.9708 2.99658 15.9999 2.99658C14.0289 2.99658 12.1125 3.64355 10.5449 4.83814C8.97725 6.03272 7.84509 7.70887 7.32228 9.60918C6.79946 11.5095 6.91489 13.5289 7.65083 15.3572C8.38678 17.1856 9.70256 18.7218 11.3961 19.73C7.97235 20.8475 5.0386 23.2075 3.13485 26.5C3.06504 26.6138 3.01873 26.7405 2.99866 26.8725C2.97859 27.0045 2.98517 27.1392 3.01801 27.2687C3.05084 27.3981 3.10927 27.5197 3.18984 27.6262C3.27041 27.7326 3.3715 27.8219 3.48713 27.8887C3.60277 27.9555 3.73061 27.9985 3.86311 28.015C3.99561 28.0316 4.1301 28.0215 4.25863 27.9853C4.38716 27.949 4.50713 27.8874 4.61146 27.8041C4.71579 27.7207 4.80238 27.6173 4.8661 27.5C7.2211 23.43 11.3836 21 15.9999 21C20.6161 21 24.7786 23.43 27.1336 27.5C27.1973 27.6173 27.2839 27.7207 27.3882 27.8041C27.4926 27.8874 27.6125 27.949 27.7411 27.9853C27.8696 28.0215 28.0041 28.0316 28.1366 28.015C28.2691 27.9985 28.3969 27.9555 28.5126 27.8887C28.6282 27.8219 28.7293 27.7326 28.8099 27.6262C28.8904 27.5197 28.9489 27.3981 28.9817 27.2687C29.0145 27.1392 29.0211 27.0045 29.001 26.8725C28.981 26.7405 28.9347 26.6138 28.8648 26.5ZM8.99985 12C8.99985 10.6155 9.41039 9.26214 10.1796 8.11099C10.9487 6.95985 12.042 6.06264 13.3211 5.53283C14.6001 5.00301 16.0076 4.86439 17.3655 5.13449C18.7234 5.40458 19.9706 6.07127 20.9496 7.05023C21.9286 8.0292 22.5953 9.27648 22.8653 10.6344C23.1354 11.9922 22.9968 13.3997 22.467 14.6788C21.9372 15.9578 21.04 17.0511 19.8888 17.8203C18.7377 18.5894 17.3843 19 15.9999 19C14.1439 18.998 12.3646 18.2599 11.0523 16.9475C9.73997 15.6352 9.00184 13.8559 8.99985 12Z" />
      );
    case "thin":
      return (
        <path d="M28.4327 26.7498C26.3677 23.1798 23.0577 20.7423 19.2227 19.8635C21.045 19.1169 22.5516 17.7607 23.4849 16.0266C24.4183 14.2925 24.7205 12.2881 24.3401 10.3559C23.9596 8.42363 22.92 6.68346 21.3989 5.43266C19.8778 4.18186 17.9695 3.49805 16.0002 3.49805C14.0309 3.49805 12.1226 4.18186 10.6015 5.43266C9.08042 6.68346 8.04082 8.42363 7.66034 10.3559C7.27987 12.2881 7.58213 14.2925 8.51549 16.0266C9.44885 17.7607 10.9554 19.1169 12.7777 19.8635C8.9477 20.7385 5.6327 23.1798 3.5677 26.7498C3.50792 26.864 3.49461 26.997 3.53054 27.1208C3.56647 27.2447 3.64887 27.3499 3.76052 27.4144C3.87217 27.4789 4.00444 27.4978 4.1297 27.4672C4.25496 27.4365 4.36352 27.3586 4.4327 27.2498C6.8752 23.0235 11.2027 20.4998 16.0002 20.4998C20.7977 20.4998 25.1252 23.0235 27.5677 27.2498C27.6115 27.3257 27.6746 27.3888 27.7505 27.4326C27.8264 27.4765 27.9125 27.4997 28.0002 27.4998C28.0881 27.5 28.1744 27.4767 28.2502 27.4323C28.3648 27.3659 28.4484 27.2567 28.4826 27.1288C28.5168 27.0008 28.4989 26.8645 28.4327 26.7498ZM8.5002 11.9998C8.5002 10.5164 8.94007 9.06635 9.76418 7.83298C10.5883 6.59961 11.7596 5.63832 13.1301 5.07066C14.5005 4.50301 16.0085 4.35448 17.4634 4.64387C18.9182 4.93326 20.2546 5.64756 21.3035 6.69646C22.3524 7.74535 23.0667 9.08172 23.3561 10.5366C23.6455 11.9914 23.497 13.4994 22.9293 14.8699C22.3616 16.2403 21.4003 17.4117 20.167 18.2358C18.9336 19.0599 17.4836 19.4998 16.0002 19.4998C14.0118 19.4974 12.1055 18.7065 10.6995 17.3005C9.29344 15.8945 8.50252 13.9882 8.5002 11.9998Z" />
      );
    case "light":
      return (
        <path d="M28.6489 26.6251C26.6727 23.2101 23.5702 20.8139 19.9652 19.7976C21.7179 18.9067 23.1194 17.4514 23.9438 15.6665C24.7682 13.8816 24.9675 11.871 24.5094 9.95896C24.0513 8.04694 22.9626 6.34491 21.4189 5.12732C19.8751 3.90974 17.9663 3.24756 16.0002 3.24756C14.0341 3.24756 12.1252 3.90974 10.5815 5.12732C9.03774 6.34491 7.94906 8.04694 7.49098 9.95896C7.03291 11.871 7.23214 13.8816 8.05655 15.6665C8.88096 17.4514 10.2825 18.9067 12.0352 19.7976C8.43019 20.8126 5.32769 23.2089 3.35144 26.6251C3.29717 26.7105 3.26074 26.806 3.24432 26.9058C3.2279 27.0056 3.23184 27.1077 3.25591 27.2059C3.27998 27.3042 3.32366 27.3965 3.38435 27.4775C3.44504 27.5584 3.52147 27.6262 3.60904 27.6768C3.69662 27.7274 3.79352 27.7598 3.89394 27.772C3.99435 27.7842 4.09619 27.776 4.19334 27.7478C4.29049 27.7196 4.38093 27.6721 4.45922 27.608C4.53752 27.544 4.60205 27.4648 4.64894 27.3751C7.05019 23.2264 11.2927 20.7501 16.0002 20.7501C20.7077 20.7501 24.9502 23.2264 27.3514 27.3751C27.3983 27.4648 27.4629 27.544 27.5412 27.608C27.6194 27.6721 27.7099 27.7196 27.807 27.7478C27.9042 27.776 28.006 27.7842 28.1064 27.772C28.2069 27.7598 28.3038 27.7274 28.3913 27.6768C28.4789 27.6262 28.5553 27.5584 28.616 27.4775C28.6767 27.3965 28.7204 27.3042 28.7445 27.2059C28.7685 27.1077 28.7725 27.0056 28.7561 26.9058C28.7396 26.806 28.7032 26.7105 28.6489 26.6251ZM8.75019 12.0001C8.75019 10.5662 9.17539 9.16451 9.97203 7.97226C10.7687 6.78 11.901 5.85075 13.2257 5.30201C14.5505 4.75328 16.0082 4.6097 17.4146 4.88945C18.821 5.16919 20.1128 5.85969 21.1267 6.87362C22.1406 7.88755 22.8311 9.17937 23.1109 10.5857C23.3906 11.9921 23.247 13.4498 22.6983 14.7746C22.1496 16.0994 21.2203 17.2317 20.0281 18.0283C18.8358 18.8249 17.4341 19.2501 16.0002 19.2501C14.0781 19.2478 12.2354 18.4832 10.8762 17.1241C9.51709 15.765 8.7525 13.9222 8.75019 12.0001Z" />
      );
    case "bold":
      return (
        <path d="M27.2973 24.2498C25.5835 21.242 22.9074 18.8983 19.6998 17.5961C21.2949 16.3998 22.4732 14.7319 23.0677 12.8287C23.6622 10.9255 23.6428 8.88349 23.0123 6.99193C22.3818 5.10037 21.1721 3.45514 19.5546 2.28929C17.937 1.12345 15.9937 0.496094 13.9998 0.496094C12.0059 0.496094 10.0626 1.12345 8.44506 2.28929C6.82754 3.45514 5.61784 5.10037 4.98732 6.99193C4.3568 8.88349 4.33742 10.9255 4.93193 12.8287C5.52644 14.7319 6.7047 16.3998 8.29981 17.5961C5.09221 18.8983 2.41612 21.242 0.702311 24.2498C0.596118 24.4206 0.52534 24.611 0.494186 24.8096C0.463033 25.0083 0.472138 25.2112 0.520963 25.4062C0.569787 25.6013 0.657333 25.7845 0.778396 25.9451C0.899459 26.1057 1.05157 26.2402 1.22568 26.3408C1.39979 26.4414 1.59235 26.506 1.79191 26.5307C1.99147 26.5553 2.19396 26.5397 2.38733 26.4845C2.58071 26.4294 2.76103 26.3359 2.91757 26.2097C3.07411 26.0835 3.20367 25.9271 3.29856 25.7498C5.56356 21.8348 9.56356 19.4998 13.9998 19.4998C18.4361 19.4998 22.4361 21.8361 24.7011 25.7498C24.9067 26.0805 25.2329 26.3182 25.6106 26.4128C25.9884 26.5073 26.3881 26.4513 26.7252 26.2565C27.0624 26.0617 27.3106 25.7434 27.4173 25.3689C27.5241 24.9945 27.4811 24.5932 27.2973 24.2498ZM7.49981 9.99984C7.49981 8.71426 7.88103 7.45756 8.59526 6.38864C9.30949 5.31972 10.3246 4.4866 11.5124 3.99463C12.7001 3.50266 14.007 3.37394 15.2679 3.62474C16.5288 3.87554 17.687 4.49461 18.596 5.40365C19.505 6.31269 20.1241 7.47088 20.3749 8.73176C20.6257 9.99263 20.497 11.2996 20.005 12.4873C19.5131 13.675 18.6799 14.6902 17.611 15.4044C16.5421 16.1186 15.2854 16.4998 13.9998 16.4998C12.2765 16.4979 10.6244 15.8124 9.40581 14.5938C8.18725 13.3753 7.5018 11.7231 7.49981 9.99984Z" />
      );
    case "fill":
      return (
        <path d="M28.8662 27.5C28.7784 27.652 28.6521 27.7783 28.5001 27.8661C28.348 27.9538 28.1755 28 28 28H3.99995C3.8245 27.9998 3.65219 27.9535 3.50031 27.8656C3.34843 27.7778 3.22234 27.6515 3.13469 27.4995C3.04705 27.3475 3.00093 27.1752 3.00098 26.9997C3.00102 26.8243 3.04723 26.6519 3.13495 26.5C5.0387 23.2087 7.97245 20.8487 11.3962 19.73C9.70266 18.7218 8.38688 17.1856 7.65094 15.3572C6.91499 13.5289 6.79956 11.5095 7.32238 9.60918C7.8452 7.70887 8.97735 6.03272 10.545 4.83814C12.1126 3.64355 14.029 2.99658 16 2.99658C17.9709 2.99658 19.8873 3.64355 21.4549 4.83814C23.0226 6.03272 24.1547 7.70887 24.6775 9.60918C25.2003 11.5095 25.0849 13.5289 24.349 15.3572C23.613 17.1856 22.2972 18.7218 20.6037 19.73C24.0275 20.8487 26.9612 23.2087 28.865 26.5C28.9529 26.6519 28.9993 26.8243 28.9996 26.9998C28.9998 27.1754 28.9538 27.3479 28.8662 27.5Z" />
      );
    case "duotone":
      return (
        <>
          <path
            opacity="0.2"
            d="M24 12C24 13.5823 23.5308 15.129 22.6518 16.4446C21.7727 17.7602 20.5233 18.7855 19.0615 19.391C17.5997 19.9965 15.9911 20.155 14.4393 19.8463C12.8874 19.5376 11.462 18.7757 10.3431 17.6569C9.22433 16.538 8.4624 15.1126 8.15372 13.5607C7.84504 12.0089 8.00347 10.4003 8.60897 8.93853C9.21447 7.47672 10.2398 6.22729 11.5554 5.34824C12.871 4.46919 14.4178 4 16 4C18.1217 4 20.1566 4.84285 21.6569 6.34315C23.1571 7.84344 24 9.87827 24 12Z"
          />
          <path d="M28.8648 26.5C26.9611 23.2087 24.0273 20.8487 20.6036 19.73C22.2971 18.7218 23.6129 17.1856 24.3489 15.3572C25.0848 13.5289 25.2002 11.5095 24.6774 9.60918C24.1546 7.70887 23.0224 6.03272 21.4548 4.83814C19.8872 3.64355 17.9708 2.99658 15.9999 2.99658C14.0289 2.99658 12.1125 3.64355 10.5449 4.83814C8.97725 6.03272 7.84509 7.70887 7.32228 9.60918C6.79946 11.5095 6.91489 13.5289 7.65083 15.3572C8.38678 17.1856 9.70256 18.7218 11.3961 19.73C7.97235 20.8475 5.0386 23.2075 3.13485 26.5C3.06504 26.6138 3.01873 26.7405 2.99866 26.8725C2.97859 27.0045 2.98517 27.1392 3.01801 27.2687C3.05084 27.3981 3.10927 27.5197 3.18984 27.6262C3.27041 27.7326 3.3715 27.8219 3.48713 27.8887C3.60277 27.9555 3.73061 27.9985 3.86311 28.015C3.99561 28.0316 4.1301 28.0215 4.25863 27.9853C4.38716 27.949 4.50713 27.8874 4.61146 27.8041C4.71579 27.7207 4.80238 27.6173 4.8661 27.5C7.2211 23.43 11.3836 21 15.9999 21C20.6161 21 24.7786 23.43 27.1336 27.5C27.1973 27.6173 27.2839 27.7207 27.3882 27.8041C27.4926 27.8874 27.6125 27.949 27.7411 27.9853C27.8696 28.0215 28.0041 28.0316 28.1366 28.015C28.2691 27.9985 28.3969 27.9555 28.5126 27.8887C28.6282 27.8219 28.7293 27.7326 28.8099 27.6262C28.8904 27.5197 28.9489 27.3981 28.9817 27.2687C29.0145 27.1392 29.0211 27.0045 29.001 26.8725C28.981 26.7405 28.9347 26.6138 28.8648 26.5ZM8.99985 12C8.99985 10.6155 9.41039 9.26214 10.1796 8.11099C10.9487 6.95985 12.042 6.06264 13.3211 5.53283C14.6001 5.00301 16.0076 4.86439 17.3655 5.13449C18.7234 5.40458 19.9706 6.07127 20.9496 7.05023C21.9286 8.0292 22.5953 9.27648 22.8653 10.6344C23.1354 11.9922 22.9968 13.3997 22.467 14.6788C21.9372 15.9578 21.04 17.0511 19.8888 17.8203C18.7377 18.5894 17.3843 19 15.9999 19C14.1439 18.998 12.3646 18.2599 11.0523 16.9475C9.73997 15.6352 9.00184 13.8559 8.99985 12Z" />
        </>
      );
  }
};

const UserIcon = forwardRef(SvgComponent);
UserIcon.displayName = "UserIcon";
export { UserIcon };
