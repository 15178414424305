"use client";

import { portalContainer } from "$components/features/nav/NavBar/portalContainer";
import { PrinterIcon } from "$components/shared/Icons/PrinterIcon";
import { LoadingBar } from "$components/shared/LoadingBar";
import { Tooltip } from "$components/shared/Tooltip";
import { useAppStore } from "$hooks/useAppStore";
import {
  CourseFindOutput,
  useCourse,
  useLoginVia,
} from "$hooks/useCourseNavigation/dataHooks";
import { getRootSF3URL } from "$utils/appUtil";
import { mdiChevronRight, mdiHomeOutline } from "@mdi/js";
import Icon from "@mdi/react";
import cn from "classnames";
import Image from "next/image";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { NavAccessibilityMenu } from "./Accessibility/NavAccessibilityMenu";
import { NavCourseNavigation } from "./NavCourseNavigation";
import { NavMenu } from "./NavMenu";
import { NavSearch } from "./NavSearch/NavSearch";
import { NavStudentNavigation } from "./NavStudentNavigation";
import { NavViewAsNavigation } from "./NavViewAsNavigation";
import { NavViewingAsToggle } from "./NavViewingAsToggle";
import { useOnThisPageMenuItems } from "./useOnThisPageMenuItems";

export type NavBarProps = {
  isStudent: boolean;
  loginVia?: string;
  isPreview?: boolean;
  customization?:
    | {
        hidden: boolean;
        logoUrl: string | null;
        includeSearch: boolean;
        includeCourseNav: boolean;
        includePrintNotes: boolean;
        includeNextLessonBtn: boolean;
      }
    | { hidden: true }
    | null;
  pageWithoutNavigation?: boolean;
};

const defaultCustomization = {
  hidden: false,
  logoUrl: null,
  includeSearch: true,
  includeCourseNav: true,
  includePrintNotes: true,
  includeNextLessonBtn: true,
};

const rootSF3URL = getRootSF3URL();

export const NavBar = (props: NavBarProps) => {
  const { isStudent, customization, isPreview = false } = props;
  const navContainerRef = useRef<HTMLDivElement>(null);
  const isPageLoading = useAppStore((state) => state.isPageLoading);
  const [nextLessonId, setNextLessonId] = useState<number | null>(null);
  const courseId = useAppStore((state) => state.course?.id);
  const chapterId = useAppStore((state) => state.chapter?.id);
  const lesson = useAppStore((state) => state.lesson);
  const { data: course } = useCourse({
    courseId,
  });

  const loginVia = useLoginVia(props.loginVia);
  const onThisPageMenuItems = useOnThisPageMenuItems();
  const printNotesButton = onThisPageMenuItems.find(
    (i) => i.name === "Print Notes"
  );
  const {
    hidden,
    includeSearch,
    includeCourseNav,
    includeNextLessonBtn,
    includePrintNotes,
    logoUrl,
  } = { ...defaultCustomization, ...(isStudent ? customization : null) };

  useEffect(() => {
    let nextId = null;
    if (isStudent && course && chapterId && lesson) {
      const chapter = (course as CourseFindOutput).chapters.find(
        (c) => c.id === chapterId
      );
      const sortedLessons = chapter?.lessons.sort(
        (a, b) => a.number - b.number
      );
      const currLessonIndex =
        sortedLessons?.findIndex((l) => l.id === lesson.id) ?? -1;
      nextId = sortedLessons?.[currLessonIndex + 1]?.id ?? null;
    }
    setNextLessonId(nextId);
  }, [isStudent, course, chapterId, lesson]);

  if (hidden || props.pageWithoutNavigation) {
    return isPreview ? null : (
      <div className="fixed left-0 right-0 top-0 z-30">
        <LoadingBar loading={isPageLoading} className="top-0" />
      </div>
    );
  }

  const onKeyDown = (e: KeyboardEvent) => {
    if (!!navContainerRef.current) {
      if (!(e.target as HTMLElement).classList.contains("nav-control")) {
        return;
      }
      if (e.key === "ArrowRight") {
        if (
          (e.target as HTMLElement).tagName !== "INPUT" ||
          (e.target as HTMLInputElement).value === ""
        ) {
          focusNavbarControl(navContainerRef.current, e.target, true);
        }
        e.stopPropagation();
        e.preventDefault();
      } else if (e.key === "ArrowLeft") {
        if (
          (e.target as HTMLElement).tagName !== "INPUT" ||
          (e.target as HTMLInputElement).value === ""
        ) {
          focusNavbarControl(navContainerRef.current, e.target, false);
        }
        e.stopPropagation();
        e.preventDefault();
      }
    }
  };

  return (
    <>
      {!isPreview && <div className="h-6 shrink-0" />}
      <div
        className={
          isPreview ? "pointer-events-none" : "fixed left-0 right-0 top-0 z-30"
        }
      >
        <nav
          ref={navContainerRef}
          className="relative mx-auto my-0 flex min-h-6 min-w-[768px] max-w-[1200px] flex-nowrap bg-white text-xs text-carbon-900 shadow tablet:text-sm"
          onKeyDown={onKeyDown}
        >
          {(loginVia === "lti" || isPreview) && !!logoUrl && (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src={logoUrl}
              alt="logo"
              className={cn(
                "max-h-[40px] w-[100px] shrink-0 grow-0 self-center",
                includeSearch || includeCourseNav
                  ? "border-r border-anvil-100"
                  : ""
              )}
            />
          )}
          {loginVia !== "lti" && !isPreview && (
            <a
              href={rootSF3URL}
              rel="noopener noreferrer"
              className={cn(
                "nav-control shrink-0 cursor-pointer border-r border-anvil-100 px-2"
              )}
              aria-label="home button"
            >
              <Icon className="h-6 w-5" path={mdiHomeOutline} />
            </a>
          )}

          {includeSearch && <NavSearch />}

          {includeCourseNav && <NavCourseNavigation />}

          {(!includeSearch || !includeCourseNav) && (
            <div
              className="flex-1"
              style={{
                boxShadow: "1px 0px 0px 0px #DFEDEF",
              }}
            />
          )}

          {!isStudent && <NavViewingAsToggle />}

          {isStudent ? (
            <>
              {includeNextLessonBtn && (
                <div className="border-r border-anvil-100">
                  <button
                    disabled={!nextLessonId}
                    className="nav-control flex h-full w-[118px] shrink-0 grow-0 items-center pl-2 pr-1 disabled:opacity-20"
                  >
                    {nextLessonId ? (
                      <a
                        className="flex-1 whitespace-nowrap"
                        href={`${rootSF3URL}/lesson/${nextLessonId}`}
                      >
                        Next Lesson
                      </a>
                    ) : (
                      <span className="flex-1 whitespace-nowrap">
                        Next Lesson
                      </span>
                    )}
                    <Icon path={mdiChevronRight} className="h-5 w-5" />
                  </button>
                </div>
              )}
              {includePrintNotes && (
                <Tooltip
                  title={!!printNotesButton ? "Print notes" : null}
                  size="sm"
                  portalProps={{
                    container: portalContainer(),
                  }}
                >
                  <div className="border-r border-anvil-100">
                    <button
                      disabled={!printNotesButton}
                      className={cn(
                        "nav-control flex h-full items-center justify-center px-2",
                        "disabled:opacity-20"
                      )}
                      onClick={() => printNotesButton?.onClick?.()}
                    >
                      <PrinterIcon className="h-5 w-5" />
                    </button>
                  </div>
                </Tooltip>
              )}
              <NavStudentNavigation isPreview={isPreview} />
            </>
          ) : (
            <NavViewAsNavigation />
          )}
          <NavMenu />
          {lesson || isPreview ? (
            <NavAccessibilityMenu isPreview={isPreview} />
          ) : null}
          <LoadingBar loading={isPageLoading} className="top-full" />
        </nav>
      </div>
    </>
  );
};

const focusNavbarControl = (
  container: Element,
  target: EventTarget,
  next: boolean
) => {
  const navControls = Array.from(container.querySelectorAll(".nav-control"));
  const currentIndex = navControls.findIndex((el) => el === target);
  if (next) {
    const maxIndex = navControls.length - 1;
    const nextIndex = currentIndex < maxIndex ? currentIndex + 1 : maxIndex;
    (navControls[nextIndex] as HTMLElement).focus();
  } else {
    const prevIndex = currentIndex <= 0 ? 0 : currentIndex - 1;
    (navControls[prevIndex] as HTMLElement).focus();
  }
};
