import { useAppStore } from "$hooks/useAppStore";
import { AppStore } from "$store/index";
import { useMemo } from "react";

export function useViewingAs() {
  const currentUser = useAppStore((state) => state.currentUser);
  const viewAsUser = useAppStore((state) => state.viewAsUser);
  const viewAsGroup = useAppStore((state) => state.viewAsGroup);
  const viewAsScopes = useAppStore((state) => state.viewAsScopes);
  const isViewProgress = useAppStore((state) => state.isViewingProgress);

  const viewingAs:
    | ({ isViewProgress: boolean; group: true } & AppStore["viewAsGroup"])
    | ({
        isViewProgress: boolean;
        group: false;
        groupId: number | null;
        myself: boolean;
      } & AppStore["viewAsUser"])
    | null = useMemo(() => {
    if (viewAsScopes.includes("users") && viewAsUser?.id) {
      return {
        ...viewAsUser,
        group: false,
        groupId: viewAsGroup?.id ?? null,
        isViewProgress: isViewProgress ?? true,
        myself: viewAsUser.id === currentUser.id,
      };
    }

    if (viewAsScopes.includes("groups") && viewAsGroup?.id) {
      return {
        ...viewAsGroup,
        group: true,
        isViewProgress: isViewProgress ?? true,
      };
    }

    return null;
  }, [viewAsScopes, viewAsUser, viewAsGroup, isViewProgress, currentUser.id]);

  return viewingAs;
}
