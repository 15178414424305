import { env } from "$env";

export function getRootSF3URL() {
  let root = env.NEXT_PUBLIC_APP_URL.replace("/v4", "");

  if (root?.includes("sf.hcos.test")) {
    root += "/app_dev.php";
  }

  return root;
}

export type SF4Pages = {
  base: string;
  params?: Record<string, string | number | undefined>;
} & (
  | {
      base: "/construct/quiz/[quizId]";
      params: {
        quizId: number;
        tab: "builder" | "settings" | "history";
        group?: number;
      };
    }
  | {
      base: "/construct/quiz/[quizId]/preview";
      params: {
        quizId: number;
        group?: number;
        version?: number;
      };
    }
  | { base: "/manage/quiz/bulksettings" }
  | { base: "/manage/quiz/presets" }
  | {
      base: "manage/regions/[id]";
      params: {
        id: number;
      };
    }
  | {
      base: "/construct/quiz/[quizId]/institutionsettings/[institutionId]";
      params: {
        quizId: number;
        institutionId: number;
      };
    }
);

export function getSF4URL(page: SF4Pages) {
  return env.NEXT_PUBLIC_APP_URL + getSF4Path(page);
}

export function getSF4Path(page: SF4Pages) {
  let path: string = page.base;

  const searchParams = new URLSearchParams();

  const params = page.params;
  if (!params) {
    return path;
  }

  for (const [key, value] of Object.entries(params)) {
    if (value === undefined) {
      continue;
    }

    if (path.includes(`[${key}]`)) {
      path = path.replace(`[${key}]`, String(value));
    } else {
      searchParams.append(key, String(value));
    }
  }

  const searchParamsStr = searchParams.toString();
  return path + (searchParamsStr ? `?${searchParamsStr}` : "");
}

export function getLoginUrl(forwardURL: string | null = null) {
  return (
    getRootSF3URL() +
    "/login" +
    (typeof forwardURL === "string"
      ? `?forward_after_login=${encodeURIComponent(
          process.env.NEXT_PUBLIC_APP_URL + forwardURL
        )}`
      : "")
  );
}

export function redirectToLogin(forwardURL: string | null = null) {
  return {
    redirect: {
      destination: getLoginUrl(forwardURL),
      permanent: false,
    },
  };
}

/**
 * Check if the current window is being rendered inside an iframe
 */
export function isIframe() {
  if (typeof window === "undefined") {
    return false;
  }

  return window.self !== window.top;
}

export function generateLTILink(url?: string): string {
  const rootSF3Url = getRootSF3URL();

  if (!url) {
    url = document.location.origin + document.location.pathname;
  }

  // assessment pages need groupId excluded
  url = url.replace(/(\/assess\/\d+)\/\d+$/, "$1");

  return url.replace(rootSF3Url, rootSF3Url + "/lti");
}

export async function copyToClipboard(text: string) {
  // use the clipboard API when available
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    // fallback for older browsers
    return new Promise<void>((resolve, reject) => {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      // save the current focus
      const currentFocus = document.activeElement as HTMLElement | null;
      textarea.select();
      try {
        const successful = document.execCommand("copy");
        if (!successful) {
          throw new Error("Failed to copy text to clipboard");
        }
      } catch (err) {
        reject(err);
      } finally {
        document.body.removeChild(textarea);
        // restore the focus
        currentFocus?.focus();
      }
      resolve();
    });
  }
}
