import { useAppStore } from "$hooks/useAppStore";
import type { UserSelection, UserSelectionItem } from "$hooks/useUserSelection";
import { useCombobox } from "downshift";
import { useEffect, useMemo, useState } from "react";
import { comboboxStateReducerWithShallowCopy } from "../NavCourseNavigation/lib";
import {
  BACK_ID,
  STOP_VIEWING_AS_ID,
  VIEW_AS_ENTIRE_GROUP_ID,
  VIEW_AS_MYSELF_ID,
  useViewAsExtraItems,
} from "./useViewAsExtraItems";

export function useViewAsNavigation({
  userSelection,
  searchTerm,
  setSearchTerm,
  extraItems,
  closeMenu,
}: {
  userSelection: UserSelection;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  extraItems: ReturnType<typeof useViewAsExtraItems>;
  closeMenu: () => void;
}) {
  const currentUser = useAppStore((state) => state.currentUser);
  const viewAsUser = useAppStore((state) => state.viewAsUser);
  const viewAsScopes = useAppStore((state) => state.viewAsScopes);
  const setViewAsUser = useAppStore((state) => state.setViewAsUser);
  const setViewAsGroup = useAppStore((state) => state.setViewAsGroup);
  const clearViewAs = useAppStore((state) => state.clearViewAs);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [otherOptionsOpenId, setOtherOptionsOpenId] = useState<number | null>(
    null
  );

  const {
    data: viewAsData,
    level,
    selectedLevels,
    onItemSelected,
    onBack,
  } = userSelection;

  const flattenData = useMemo(() => {
    if (!viewAsData) return [];

    let list = viewAsData.reduce((prev, cur) => {
      return [...prev, ...cur.list];
    }, [] as UserSelectionItem[]);

    if (extraItems.VIEW_AS_MYSELF.show) {
      list.unshift(extraItems.VIEW_AS_MYSELF.item);
    }
    if (extraItems.VIEW_AS_ENTIRE_GROUP.show) {
      list.unshift(extraItems.VIEW_AS_ENTIRE_GROUP.item);
    }
    if (extraItems.STOP_VIEWING_AS.show) {
      list.unshift(extraItems.STOP_VIEWING_AS.item);
    }
    if (extraItems.BACK.show) {
      list.unshift(extraItems.BACK.item);
    }

    return list;
  }, [extraItems, viewAsData]);

  const combobox = useCombobox<UserSelectionItem>({
    id: "nav-viewas-search",
    isOpen: true,
    items: flattenData,
    highlightedIndex,
    itemToString(item) {
      return item ? item.name : "";
    },
    onHighlightedIndexChange: (changes) => {
      if (
        typeof changes.highlightedIndex === "number" &&
        otherOptionsOpenId === null
      ) {
        setHighlightedIndex(changes.highlightedIndex);
      }
    },
    onInputValueChange(changes) {
      setSearchTerm(changes.inputValue ?? "");
    },
    onSelectedItemChange({ selectedItem }) {
      if (!selectedItem) return;

      switch (selectedItem.id) {
        case BACK_ID:
          onBack();
          break;

        case VIEW_AS_MYSELF_ID:
          setViewAsUser({
            user: {
              id: currentUser.id,
              name: `${currentUser.firstname} ${currentUser.lastname}`,
            },
          });
          break;

        case VIEW_AS_ENTIRE_GROUP_ID:
          if (selectedLevels.group) {
            setViewAsGroup({
              id: selectedLevels.group.id,
              name: selectedLevels.group.name,
              course_id: selectedLevels.group.course_id,
            });
          }
          break;

        case STOP_VIEWING_AS_ID:
          clearViewAs();
          break;

        default:
          if (selectedItem.type === "User") {
            setViewAsUser({
              user: { id: selectedItem.id, name: selectedItem.name },
              group: selectedLevels.group ?? null,
            });
          } else if (
            selectedItem.type === "Group" &&
            !viewAsScopes.includes("users")
          ) {
            setViewAsGroup({
              id: selectedItem.id,
              name: selectedItem.name,
              course_id: selectedItem.course_id,
            });
          } else {
            onItemSelected(selectedItem);
          }
          break;
      }

      // Make sure there is no dropdown menu opened
      setOtherOptionsOpenId(null);
    },
    onIsOpenChange(changes) {
      if (!changes.isOpen) {
        setOtherOptionsOpenId(null);
        setHighlightedIndex(-1);
        closeMenu();
      }
    },
    stateReducer: comboboxStateReducerWithShallowCopy,
  });

  const selectedItemIndex = flattenData.findIndex((item) => {
    switch (level) {
      case "partners":
        return item.id === selectedLevels.partner?.id;
      case "institutions":
        return item.id === selectedLevels.institution?.id;
      case "groups":
        return item.id === selectedLevels.group?.id;
      case "students":
        return item.type === "User" && item.id === viewAsUser?.id;
      default:
        return false;
    }
  });

  return {
    getMenuProps: combobox.getMenuProps,
    getInputProps: combobox.getInputProps,
    getItemProps: combobox.getItemProps,
    selectedItemIndex,
    viewAsData,
    totalItems: flattenData.length,
    level,
    selectedLevels,
    extraItems: {},
    highlightedIndex,
    setHighlightedIndex,
    otherOptionsOpenId,
    setOtherOptionsOpenId,
  };
}
