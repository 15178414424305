import { SearchInteractiveIcon } from "$components/shared/Icons/SearchInteractiveIcon";
import { SearchLessonIcon } from "$components/shared/Icons/SearchLessonIcon";
import { SearchQuestionIcon } from "$components/shared/Icons/SearchQuestionIcon";
import { SearchReadingIcon } from "$components/shared/Icons/SearchReadingIcon";
import { SearchVideoIcon } from "$components/shared/Icons/SearchVideoIcon";
import { LoadingBar } from "$components/shared/LoadingBar";
import { usePreviousPersistent } from "$hooks/usePreviousPersistent";
import { SearchResult, SearchResultEntityType } from "$lib/search";
import { getRootSF3URL } from "$utils/appUtil";
import cn from "classnames";
import { UseComboboxReturnValue } from "downshift";

export type SearchDropdownProps = {
  open: boolean;
  searchString: string;
  loading: boolean;
  results: SearchResult[];
  getMenuProps: UseComboboxReturnValue<SearchResult>["getMenuProps"];
  getItemProps: UseComboboxReturnValue<SearchResult>["getItemProps"];
  highlightedIndex: UseComboboxReturnValue<SearchResult>["highlightedIndex"];
};

export const SearchDropdown = ({
  open,
  searchString,
  loading,
  results,
  getMenuProps,
  getItemProps,
  highlightedIndex,
}: SearchDropdownProps) => {
  const previousSearchString = usePreviousPersistent(searchString);
  const searchStringToShow = loading ? previousSearchString : searchString;

  return (
    <div
      className={cn(
        "absolute left-0 right-0 top-full min-w-[300px] bg-white py-2 shadow-md",
        !open && "hidden"
      )}
    >
      <LoadingBar loading={loading} />
      <ul {...getMenuProps()}>
        {results.length === 0 ? (
          <p className="my-2 ml-4 mr-3 truncate text-base">
            <span>
              {searchStringToShow
                ? `No results for`
                : "Search lessons and learning objects"}
            </span>
            {searchStringToShow && (
              <>
                <br />
                <span>{` "${searchStringToShow}"`}</span>
              </>
            )}
          </p>
        ) : (
          results.map((result, index) => (
            <SearchItem
              key={result.type + result.id}
              result={result}
              index={index}
              getItemProps={getItemProps}
              highlighted={highlightedIndex === index}
            />
          ))
        )}
      </ul>
    </div>
  );
};

const SearchItem = ({
  result,
  index,
  getItemProps,
  highlighted,
}: {
  result: SearchResult;
  index: number;
  getItemProps: UseComboboxReturnValue<SearchResult>["getItemProps"];
  highlighted: boolean;
}) => {
  return (
    <li
      className={cn(
        "flex h-[52px] cursor-pointer items-center",
        highlighted && "bg-[#ECEDED]"
      )}
      {...getItemProps({ item: result, index })}
    >
      <a
        href={`${getRootSF3URL()}/${result.type}/${result.id}`}
        className="navbar-search-result flex h-full w-full items-center pr-5"
        target="_self"
        rel="noopener noreferrer"
      >
        <div className="flex w-14 shrink-0 items-center justify-center">
          <SearchItemIcon type={result.type} />
        </div>

        <div className="overflow-hidden">
          <p className="text-[10.5px] italic">{result.info}</p>
          <p className="truncate text-xs">{result.name}</p>
        </div>
      </a>
    </li>
  );
};

const SearchItemIcon = ({ type }: { type: SearchResultEntityType }) => {
  switch (type) {
    case "video":
      return <SearchVideoIcon className="text-carbon-800" width={32} />;

    case "question":
      return <SearchQuestionIcon className="text-carbon-800" width={32} />;

    case "reading":
      return <SearchReadingIcon className="text-carbon-800" width={32} />;

    case "interactive":
      return <SearchInteractiveIcon className="text-carbon-800" width={32} />;

    case "lesson":
    case "project":
    case "chapter":
    case "course":
      return <SearchLessonIcon className="text-carbon-800" width={32} />;
  }
};
