export enum STALE_TIME {
  /**
   * Data becomes stale instantly
   */
  INSTANT = 0,
  /**
   * Data becomes stale after 10 seconds
   */
  SHORT = 10 * 1000,
  /**
   * Data becomes stale after 30 seconds
   */
  MEDIUM = 30 * 1000,
  /**
   * Data becomes stale after 2 minutes
   */
  LONG = 2 * 60 * 1000,
  /**
   * Data becomes stale after 5 minutes
   */
  VERY_LONG = 5 * 60 * 1000,
  /**
   * Data never becomes stale
   */
  INFINITE = Infinity,
}
