import { SearchIcon } from "$components/shared/Icons";
import cn from "classnames";
import { ElementRef, forwardRef } from "react";

export const NavBackdrop = ({ chapterLevel }: { chapterLevel?: boolean }) => (
  <div
    className={cn(
      "pointer-events-none absolute inset-0 bg-carbon-200 opacity-50",
      chapterLevel && "ml-[12%] w-[80%]"
    )}
  />
);

export const NavSearch = forwardRef<ElementRef<"input">, {}>((props, ref) => (
  <div className="flex items-center gap-x-3 px-2 pb-1.5 pt-2">
    <SearchIcon className="h-3.4 w-3.5" />
    <input
      ref={ref}
      className="flex-1 border border-carbon-200 text-xs"
      {...props}
    />
  </div>
));
NavSearch.displayName = "NavSearch";
