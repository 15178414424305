/**
 * @param url Must include de basePath
 */
function push(url: string) {
  if (typeof window === "undefined") {
    return;
  }

  window.history.pushState(
    { ...window.history.pushState, as: url, url },
    "",
    url
  );
}

/**
 * @param url Must include de basePath
 */
function replace(url: string) {
  if (typeof window === "undefined") {
    return;
  }

  window.history.replaceState(
    { ...window.history.pushState, as: url, url },
    "",
    url
  );
}

export const nativeNavigation = {
  push,
  replace,
};
