import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    width="25"
    fill="currentColor"
    stroke="currentColor"
    ref={ref}
    {...props}
  >
    <rect
      x="1"
      y="1"
      width="23"
      height="23"
      rx="11.5"
      fill="white"
      strokeWidth="2"
    />
    <rect width="3" height="3" rx="1.5" transform="matrix(1 0 0 -1 6 14)" />
    <rect width="3" height="3" rx="1.5" transform="matrix(1 0 0 -1 11 14)" />
    <rect width="3" height="3" rx="1.5" transform="matrix(1 0 0 -1 16 14)" />
  </svg>
);

const PopoverMenuTriggerIcon = forwardRef(SvgComponent);
export { PopoverMenuTriggerIcon };
