import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 27"
      width="27"
      fill="none"
      stroke="currentColor"
      ref={ref}
      {...props}
    >
      <circle strokeWidth={2} cx="13.5" cy="13.5" r="12.5" />
      <path d="M8.2,20.6H18.8a.82.82,0,0,0,.7-1.2l-3.6-7.7V7.8h0a.79.79,0,0,0,.8-.8V6.9a.79.79,0,0,0-.8-.8H11a.79.79,0,0,0-.8.8V7a.79.79,0,0,0,.8.8h0v3.9L7.5,19.4A.82.82,0,0,0,8.2,20.6Z" />
      <path d="M9.7,15.2c1,0,1,2,1.9,2s1-2,1.9-2,1,2,1.9,2,1-2,1.9-2" />
      <circle strokeWidth={0.5} cx="12.9" cy="10.8" r="0.6" />
      <circle strokeWidth={0.5} cx="14.4" cy="13.3" r="0.6" />
    </svg>
  );
};

export const SearchInteractiveIcon = forwardRef(SvgComponent);
