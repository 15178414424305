"use client";

import { clientApi, trpcConfig } from "$lib/api";
import { queryClient } from "$lib/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import React, { ReactNode } from "react";

const trpcClient = clientApi.createClient(trpcConfig);

export const TRPCProvider = ({ children }: { children: ReactNode }) => {
  return (
    <clientApi.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </clientApi.Provider>
  );
};
