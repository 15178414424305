"use client";

import { portalContainer } from "$components/features/nav/NavBar/portalContainer";
import { CaretIcon } from "$components/shared/Icons/CaretIcon";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Content,
  Group,
  Item,
  ItemText,
  Portal,
  Label as RadixLabel,
  SelectProps as RadixSelectProps,
  Root,
  ScrollDownButton,
  ScrollUpButton,
  SelectIcon,
  Trigger,
  Value,
  Viewport,
} from "@radix-ui/react-select";
import { Ref, forwardRef, useId } from "react";
import { tv } from "tailwind-variants";
import { HelperText } from "../HelperText";
import { Label } from "../Label";

export type SelectProps = {
  options: Array<SelectOption>;
  color?: "base" | "success" | "warning" | "error";
  label?: string;
  error?: string;
  helperText?: string;
  disabled?: boolean;
  className?: string;
  id?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
} & Omit<RadixSelectProps, "onValueChange">;

type SelectOption =
  | {
      label: string;
      options: Array<{
        label: string;
        value: string;
        disabled?: boolean;
        className?: string;
      }>;
    }
  | {
      label: string;
      value: string;
      disabled?: boolean;
      className?: string;
    };

const variants = tv({
  slots: {
    root: "flex flex-col items-start",
    trigger:
      "inline-flex h-[38px] w-full items-center justify-between rounded border border-carbon-300 bg-carbon-100 px-3 text-sm text-carbon-900 outline-none" +
      " hover:border-carbon-500 hover:shadow focus:border-blue-700 data-[state=open]:border-blue-700" +
      " data-[placeholder]:text-carbon-300 [&_.select-icon]:text-steel-900 [&_.select-icon]:data-[state=open]:rotate-180",
  },
  variants: {
    color: {
      base: {
        trigger: "border-carbon-200 bg-carbon-100 shadow-inner",
      },
      error: {
        trigger: "border-red-300 bg-red-100 [&_.select-icon]:text-red-900",
      },
      warning: { trigger: "border-yellow-300 bg-yellow-100" },
      success: { trigger: "border-emerald-300 bg-emerald-50" },
    },
    disabled: {
      true: {
        trigger:
          "pointer-events-none bg-carbon-200 text-carbon-400 [&_.select-icon]:text-carbon-400",
      },
    },
    hasLabel: {
      true: { trigger: "mt-2" },
    },
    hasHelperText: {
      true: { trigger: "mb-1" },
    },
  },
});

export const Select = forwardRef(function Select(
  {
    options,
    color: propsColor = "base",
    label,
    helperText: propsHelperText,
    error,
    disabled = false,
    className,
    id: propsId,
    required,
    placeholder,
    onChange,
    ...selectProps
  }: SelectProps,
  ref: Ref<HTMLButtonElement>
) {
  const color = error ? "error" : propsColor;
  const helperText = error ?? propsHelperText;
  const styles = variants({
    disabled,
    color,
    hasLabel: !!label,
    hasHelperText: !!helperText,
  });
  const fallbackId = useId();
  const id = propsId ?? fallbackId;

  return (
    <div className={styles.root({ className })}>
      {label && (
        <Label htmlFor={id} required={required} disabled={disabled}>
          {label}
        </Label>
      )}

      <Root disabled={disabled} onValueChange={onChange} {...selectProps}>
        <Trigger ref={ref} className={styles.trigger()} id={id}>
          <Value placeholder={placeholder} />
          <SelectIcon className="select-icon">
            <CaretIcon weight="fill" />
          </SelectIcon>
        </Trigger>
        <SelectContent options={options} />
      </Root>
      <HelperText color={color} text={helperText} disabled={disabled} />
    </div>
  );
});

const selectContentVariants = tv({
  slots: {
    content:
      "z-50 overflow-hidden rounded border border-carbon-200 bg-carbon-50 shadow-md" +
      " max-h-[var(--radix-select-content-available-height)] w-[var(--radix-select-trigger-width)]",
    scroll:
      "flex h-6 cursor-default items-center justify-center bg-carbon-50 text-carbon-600",
  },
});

const SelectContent = ({ options }: { options: Array<SelectOption> }) => {
  const styles = selectContentVariants();

  return (
    <Content position="popper" sideOffset={4} className={styles.content()}>
      <ScrollUpButton className={styles.scroll()}>
        <Icon path={mdiChevronUp} size={0.8} />
      </ScrollUpButton>
      <Viewport>
        {options.map((option) => {
          if (isGroupOption(option)) {
            return (
              <Group key={option.label}>
                <RadixLabel
                  className={`py-2 pl-3 pr-4 font-sans text-xs text-carbon-600`}
                >
                  {option.label}
                </RadixLabel>

                {option.options.map((item) => (
                  <SelectItem key={item.value} option={item} groupOption />
                ))}
              </Group>
            );
          }

          return <SelectItem key={option.value} option={option} />;
        })}
      </Viewport>
      <ScrollDownButton className={styles.scroll()}>
        <Icon path={mdiChevronDown} size={0.8} />
      </ScrollDownButton>
    </Content>
  );
};

const selectItemStyle = tv({
  base: `cursor-pointer px-4 py-2 font-sans text-xs data-[highlighted]:bg-anvil-100 data-[highlighted]:outline-none`,
  variants: {
    disabled: {
      true: "cursor-default text-carbon-400",
    },
    groupItem: {
      true: "pl-8",
    },
  },
});

const SelectItem = forwardRef(function SelectItem(
  {
    option,
    groupOption,
  }: {
    option: {
      label: string;
      value: string;
      disabled?: boolean;
      className?: string;
    };
    groupOption?: boolean;
  },
  ref: Ref<HTMLDivElement>
) {
  return (
    <Item
      ref={ref}
      className={selectItemStyle({
        disabled: !!option.disabled,
        groupItem: !!groupOption,
      })}
      value={option.value}
      disabled={option.disabled}
    >
      <ItemText>{option.label}</ItemText>
    </Item>
  );
});

function isGroupOption(option: any): option is {
  label: string;
  options: Array<{
    label: string;
    value: string;
    disabled?: boolean;
    className?: string;
  }>;
} {
  return option.options !== undefined;
}
