import { SVGProps } from "react";

export { AddUserIcon } from "./AddUserIcon";
export { ArrowDownIcon } from "./ArrowDownIcon";
export { CogIcon } from "./CogIcon";
export { EditIcon } from "./EditIcon";
export { ExploreQuizzesIcon } from "./ExploreQuizzesIcon";
export { FlagTailIcon } from "./FlagTailIcon";
export { GreaterThanIcon } from "./GreaterThanIcon";
export { InfoIcon } from "./InfoIcon";
export { LessThanIcon } from "./LessThanIcon";
export { LockIcon } from "./LockIcon";
export { PopoverMenuTriggerIcon } from "./PopoverMenuTriggerIcon";
export { QuestionIcon } from "./QuestionIcon";
export { RedDotIcon } from "./RedDotIcon";
export { RedoArrowIcon } from "./RedoArrowIcon";
export { RemoveQuestionIcon } from "./RemoveQuestionIcon";
export { SearchIcon } from "./SearchIcon";
export { UndoArrowIcon } from "./UndoArrowIcon";
export { WarningIcon } from "./WarningIcon";
export { CheckIcon } from "./CheckIcon";

export type IconProps = SVGProps<SVGSVGElement> & {
  /**
   * Provides an accessible title for the icon
   */
  title?: string;
};

export type IconWeight =
  | "thin"
  | "light"
  | "regular"
  | "bold"
  | "fill"
  | "duotone";

export type IconDirection = "up" | "down" | "left" | "right";
