import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { z } from "zod";

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      displayNotification(query.meta, "error", error);
    },
    onSuccess(data, query) {
      displayNotification(query.meta, "success");
    },
  }),
  mutationCache: new MutationCache({
    onSuccess(data, variables, context, mutation) {
      displayNotification(mutation.meta, "success");
    },
    onError(error, variables, context, mutation) {
      displayNotification(mutation.meta, "error", error);
    },
  }),
});

/**
 * Display notifications on success or error
 */
function displayNotification(
  meta: Record<string, unknown> | undefined,
  status: "error" | "success",
  error?: unknown
) {
  if (meta?.preventNotification) {
    return;
  }

  // Show error notification
  if (status === "error") {
    const metaSchema = z.object({
      errorMessage: z.string(),
    });
    const errorSchema = z.object({
      message: z.string(),
      data: z.object({
        httpStatus: z.number(),
      }),
    });

    const metaSafeParse = metaSchema.safeParse(meta);
    const errorSafeParse = errorSchema.safeParse(error);

    const is5xxError =
      errorSafeParse.success &&
      Math.floor(errorSafeParse.data.data.httpStatus / 100) === 5;

    const baseMessage =
      metaSafeParse.success && !is5xxError
        ? metaSafeParse.data.errorMessage
        : "An Error Occurred";
    const apiMessage = errorSafeParse.success
      ? `: ${errorSafeParse.data.message}`
      : "";

    toast.error(baseMessage + apiMessage);
  }

  // Show success notification
  if (status === "success") {
    const metaSchema = z.object({
      successMessage: z.string(),
    });

    const metaSafeParse = metaSchema.safeParse(meta);
    if (metaSafeParse.success) {
      toast.success(metaSafeParse.data.successMessage);
    }
  }
}
