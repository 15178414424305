import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="12"
    viewBox="0 0 17 12"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      d="M8.62592 12L0.501357 -1.73152e-06L16.7505 0L8.62592 12Z"
      fill="currentColor"
    />
  </svg>
);

const ArrowDownIcon = forwardRef(SvgComponent);
export { ArrowDownIcon };
