import { QuizBreadcrumb } from "$lib/quiz";
import { Course } from "@studyforge/prisma";
import { useCallback, useEffect, useState } from "react";
import {
  useChaptersData,
  useCourseStreamsData,
  useCoursesData,
  useInitialSetup,
  useLessonsData,
  usePrivateCoursesData,
  useRegionsData,
  useSampleCoursesData,
  useSearchData,
} from "./dataHooks";
import {
  type Initial,
  type Level,
  type SelectedLevels,
  emptySelectedLevels,
  getNextLevel,
  getPreviousLevel,
} from "./lib";

type BaseCourseNavigationItem = {
  id: number;
  name: string;
  displayName: string | string[];
  subTitle?: string;
  canViewLTILinks?: boolean;
};

export type CourseNavigationItem =
  | ({
      type: "region" | "custom";
      subItems?: Array<{
        type: "course";
        id: number;
        name: string;
      }>;
    } & BaseCourseNavigationItem)
  | ({
      type: "search";
      resultType: "course" | "chapter" | "quiz";
      breadcrumbs: Partial<QuizBreadcrumb>;
    } & BaseCourseNavigationItem)
  | ({
      type: "back";
    } & BaseCourseNavigationItem)
  | ({
      type: "course_stream";
      courseType: "default";
    } & BaseCourseNavigationItem)
  | ({
      type: "course_stream";
      courseType: "sample" | "private";
      empty: boolean;
    } & BaseCourseNavigationItem)
  | ({
      type: "course";
      subItems?: Array<
        Omit<Extract<CourseNavigationItem, { type: "course" }>, "subItems"> &
          Pick<Course, "region_id" | "course_type">
      >;
      canEdit: boolean;
      streamEquivalentId?: number | null;
    } & BaseCourseNavigationItem)
  | ({
      type: "chapter";
      courseId: number;
      subItems?: Array<{
        type: "course";
        id: number;
        name: string;
      }>;
      canEdit: boolean;
    } & BaseCourseNavigationItem)
  | ({
      type: "lesson";
      subItems?: Array<{
        type: "course";
        id: number;
        name: string;
      }>;
      canEdit: boolean;
    } & BaseCourseNavigationItem)
  | ({
      type: "quiz";
      courseId: number | null;
      chapterId: number | null;
      breadcrumbs: QuizBreadcrumb;
      canEdit: boolean;
    } & BaseCourseNavigationItem)
  | ({
      type: "project";
    } & BaseCourseNavigationItem);
export type CourseNavigationData = Array<{
  title: string;
  list: CourseNavigationItem[];
}>;
export type CourseNavigation = {
  level: Level;
  selectedLevels: SelectedLevels;
  data: CourseNavigationData | null;
  onClickBack: () => void;
  onClickItem: (
    item: CourseNavigationItem,
    customLevelDestination?: Level
  ) => void;
  resetFromInitial: (newInitial: Initial) => void;
};
export type CourseNavigationArgs = {
  initial?: Initial;
  searchTerm?: string;
  onlyCoursesUserCanEdit?: boolean;
  searchInclude?: Array<"Course" | "Chapter" | "Lesson" | "Assessment">;
  onInitialDataLoaded?: (selectedLevels: SelectedLevels) => void;
};

export function useCourseNavigation({
  initial: propsInitial,
  searchTerm = "",
  onlyCoursesUserCanEdit = false,
  searchInclude = ["Course", "Chapter", "Assessment"],
  onInitialDataLoaded,
}: CourseNavigationArgs = {}): CourseNavigation {
  const [initial, setInitial] = useState<Initial | undefined>(propsInitial);
  const [level, setLevel] = useState<Level>(
    propsInitial ? propsInitial.level : "regions"
  );
  const [selectedLevels, setSelectedLevels] = useState(emptySelectedLevels);
  const previousLevel = getPreviousLevel(level);

  useEffect(() => {
    if (searchTerm.length > 0 && level !== "search") {
      setLevel("search");
    } else if (searchTerm.length === 0 && level === "search") {
      setLevel("regions");
    }
  }, [searchTerm, level]);

  useInitialSetup(
    initial,
    (selectedLevels) => {
      // if we can't figure out the selected levels from the initial data then move the level up
      if (
        level === "courses" &&
        !selectedLevels.course_streams &&
        !selectedLevels.sample_courses &&
        !selectedLevels.private_courses
      ) {
        setLevel(selectedLevels.regions ? "course_streams" : "regions");
      } else if (level === "course_streams" && !selectedLevels.regions) {
        setLevel("regions");
      }
      onInitialDataLoaded?.(selectedLevels);
      setSelectedLevels(selectedLevels);
    },
    onlyCoursesUserCanEdit
  );
  const searchData = useSearchData(
    selectedLevels,
    searchTerm,
    onlyCoursesUserCanEdit,
    searchInclude
  );
  const regionsData = useRegionsData(level);
  const courseStreamsData = useCourseStreamsData(
    level,
    selectedLevels,
    onlyCoursesUserCanEdit
  );
  const sampleCoursesData = useSampleCoursesData(
    level,
    selectedLevels,
    onlyCoursesUserCanEdit
  );
  const privateCoursesData = usePrivateCoursesData(
    level,
    selectedLevels,
    onlyCoursesUserCanEdit
  );
  const coursesData = useCoursesData(
    level,
    selectedLevels,
    onlyCoursesUserCanEdit
  );
  const chaptersData = useChaptersData(level, selectedLevels);
  const lessonsData = useLessonsData(level, selectedLevels);

  const data: CourseNavigation["data"] = (() => {
    switch (level) {
      case "search":
        return searchData;
      case "regions":
        return regionsData;
      case "course_streams":
        return courseStreamsData;
      case "sample_courses":
        return sampleCoursesData;
      case "private_courses":
        return privateCoursesData;
      case "courses":
        return coursesData;
      case "chapters":
        return chaptersData;
      case "lessons":
        return lessonsData;
      default:
        return null;
    }
  })();

  const resetFromInitial = useCallback((newInitial: Initial) => {
    setInitial(newInitial);
    setLevel(newInitial.level);
  }, []);

  const onClickItem: CourseNavigation["onClickItem"] = useCallback(
    (item, customLevelDestination) => {
      if (["project", "quiz", "custom"].includes(item.type)) return;
      if (item.type === "search") {
        if (item.resultType === "course") {
          setInitial({ level: "chapters", courseId: item.id });
          setLevel(customLevelDestination ?? "chapters");
        } else if (item.resultType === "chapter") {
          setInitial({ level: "lessons", chapterId: item.id });
          setLevel(customLevelDestination ?? "lessons");
        }
        return;
      }

      const nextLevel = getNextLevel(level, item);
      setSelectedLevels((currentSelectedIds) => ({
        ...currentSelectedIds,
        [level]: item,
      }));
      setLevel(customLevelDestination ?? nextLevel);
    },
    [level]
  );

  const onClickBack: CourseNavigation["onClickBack"] = useCallback(() => {
    if (previousLevel) {
      setLevel(previousLevel);
    }
  }, [previousLevel]);

  return {
    level,
    selectedLevels,
    data,
    onClickBack,
    onClickItem,
    resetFromInitial,
  };
}
