/**
 * Converts the first character of string to upper case.
 */
export function upperFirst(str: string) {
  return str[0].toUpperCase() + str.substring(1);
}

export function generateRandomString(
  length = 10,
  uppercase = true,
  lowercase = true,
  numbers = true
) {
  let chars = "";
  if (uppercase) {
    chars += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  }
  if (lowercase) {
    chars += "abcdefghijklmnopqrstuvwz";
  }
  if (numbers) {
    chars += "0123456789";
  }
  let str = "";
  for (let i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
}
