import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M1.967 20.965h1.286L17.05 7.153l-1.286-1.286L1.967 19.679v1.287Zm19.176-15.15-4.034-4.033L18.266.618A2.05 2.05 0 0 1 19.763 0a2.037 2.037 0 0 1 1.497.603l1.181 1.182c.385.38.57.84.558 1.38a1.923 1.923 0 0 1-.572 1.367l-1.284 1.284Zm-1.307 1.307L4.034 22.925H0V18.89L15.795 3.096l4.041 4.026Zm-3.421-.612-.651-.643 1.286 1.286-.635-.643Z"
    />
  </svg>
);

const EditIcon = forwardRef(SvgComponent);
export { EditIcon };
