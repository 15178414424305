import { createOnKeyDownHandler } from "$components/features/nav/NavBar/lib";
import {
  EmptyList,
  ListBack,
  ListItem,
} from "$components/shared/CourseNavigation/CourseNavigationComponents";
import { CourseNavigationList } from "$components/shared/CourseNavigation/CourseNavigationList";
import { SwitchIcon } from "$components/shared/Icons/SwitchIcon";
import { Tooltip } from "$components/shared/Tooltip";
import { useCourseNavigation } from "$hooks/useCourseNavigation";
import {
  CourseNavigationArgs,
  CourseNavigationItem,
} from "$hooks/useCourseNavigation/useCourseNavigation";
import { COURSE_TYPE } from "$lib/course";
import { getBackTitle, getFlatNavigationData } from "$lib/courseNavigation";
import { AppState } from "$store/index";
import { getRootSF3URL } from "$utils/appUtil";
import { Course } from "@prisma/client";
import { useCombobox } from "downshift";
import cloneDeep from "lodash.clonedeep";
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { NavBackdrop, NavSearch } from "./NavCourseNavigationComponents";
import { NavItemActions } from "./NavItemActions";
import {
  comboboxStateReducerWithShallowCopy,
  onNavItemHighlightedIndexChanged,
} from "./lib";

const CREATE_PRIVATE_COURSE: CourseNavigationItem = {
  id: -10,
  name: "+ Create New Course",
  displayName: "+ Create New Course",
  type: "custom",
};

function getCourseNavigationInitial({
  streamId,
  regionId,
  courseId,
}: {
  streamId: number | "sample" | "private" | undefined;
  regionId: number | undefined;
  courseId: number | undefined;
}): CourseNavigationArgs["initial"] {
  if (courseId) {
    return { level: "courses", courseId };
  } else if (streamId === "sample") {
    return { level: "sample_courses", regionId: regionId ?? 1 };
  } else if (streamId === "private") {
    return { level: "private_courses", regionId: regionId ?? 1 };
  } else if (streamId) {
    return { level: "courses", courseId: streamId };
  } else if (regionId) {
    return { level: "course_streams", regionId };
  } else {
    return undefined;
  }
}

/**
 * A custom "course_type" value used to indicate the correct subtitle in the other versions dropdown menu
 */
export const DEFAULT_STREAM_COURSE_TYPE = 99;

export const CourseLevelNavigation = ({
  onCourseUpdate,
  onStreamUpdate,
  onRegionUpdate,
  disabled,
  streamId,
  regionId,
  courseId,
  closeMenu,
}: {
  onCourseUpdate: (course: CourseNavigationItem | null) => void;
  onStreamUpdate: (stream: AppState["stream"]) => void;
  onRegionUpdate: (stream: AppState["region"]) => void;
  disabled: boolean;
  streamId: number | "sample" | "private" | undefined;
  regionId: number | undefined;
  courseId?: number;
  closeMenu: () => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data, level, selectedLevels, onClickItem, onClickBack } =
    useCourseNavigation({
      initial: getCourseNavigationInitial({ streamId, regionId, courseId }),
      searchTerm,
      searchInclude: ["Course"],
      onInitialDataLoaded: (selectedLevels) => {
        if (
          selectedLevels.regions?.type === "region" &&
          selectedLevels.regions.id !== regionId
        ) {
          onRegionUpdate({ id: selectedLevels.regions.id });
        }
        if (selectedLevels.course_streams?.type === "course_stream") {
          const newStreamId =
            selectedLevels.course_streams.courseType === "default"
              ? selectedLevels.course_streams.id
              : selectedLevels.course_streams.courseType;
          if (newStreamId !== streamId) {
            onStreamUpdate(
              typeof newStreamId === "string"
                ? newStreamId
                : { id: newStreamId }
            );
          }
        }
        if (
          selectedLevels.courses?.type === "course" &&
          selectedLevels.courses.streamEquivalentId !==
            selectedLevels.courses.id
        ) {
          setAlternateCourseId(selectedLevels.courses.id);
        }
      },
    });

  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [alternateCourseId, setAlternateCourseId] = useState<number | null>(
    null
  );
  const [otherOptionsOpenId, setOtherOptionsOpenId] = useState<number | null>(
    null
  );
  const [otherVersionsOpenId, setOtherVersionsOpenId] = useState<number | null>(
    null
  );
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!disabled) inputRef.current?.focus();
  }, [disabled]);

  const completeData = useMemo(() => {
    if (!data) return null;

    if (level === "courses" && !!alternateCourseId) {
      const clone = cloneDeep(data);
      // We need to replace the original course with the alternate course
      for (const session of clone) {
        session.list = session.list.map((item) => {
          if (item.type === "course") {
            const alternateCourse = item.subItems?.find(
              (i) => i.id === alternateCourseId
            );

            if (alternateCourse) {
              return {
                ...alternateCourse,
                subItems: [
                  {
                    ...item,
                    region_id: null,
                    course_type: DEFAULT_STREAM_COURSE_TYPE,
                  },
                  ...(item.subItems ?? []),
                ],
              };
            }
          }
          return item;
        });
      }
      return clone;
    }

    if (level !== "private_courses") {
      return data;
    }

    const clone = cloneDeep(data);
    // Add the "Create Private Course" item
    clone[clone.length - 1].list = [
      ...clone[clone.length - 1].list,
      CREATE_PRIVATE_COURSE,
    ];
    return clone;
  }, [data, level, alternateCourseId]);
  const back = searchTerm ? "" : getBackTitle(level, selectedLevels);
  const flattenedData = useMemo(
    () => getFlatNavigationData({ data: completeData, back }),
    [completeData, back]
  );

  const handleSelectOtherCourseVersion = (
    course: CourseNavigationItem & Pick<Course, "region_id" | "course_type">
  ) => {
    if (!data) {
      return;
    }
    let isOriginalCourse = false;

    for (const session of data) {
      for (const item of session.list) {
        if (item.id === course.id) {
          isOriginalCourse = true;
          break;
        }
      }
      if (isOriginalCourse) break;
    }

    onCourseUpdate(course);
    // If selecting from an alternate course, to the original course, reset alternateCourseId to null
    setAlternateCourseId(isOriginalCourse ? null : course.id);
  };

  const handleClickItem = (item: CourseNavigationItem | null | undefined) => {
    if (!item) return;

    if (item.type === "search") {
      onClickItem(item, "courses");
      onCourseUpdate({ ...item, type: "course", canEdit: false });
      return;
    }

    if (item.type === "back") {
      onClickBack();
    } else if (item.type === "custom" && item.id === CREATE_PRIVATE_COURSE.id) {
      window.open(`${getRootSF3URL()}/construct/course/new/edit`, "_self");
    } else if (item.type === "course") {
      onCourseUpdate(item);
    } else {
      // Negative IDs are Sample and Private streams
      if (item.type === "course_stream") {
        if (item.id > 0) {
          onStreamUpdate(item);
        } else if (item.id === -1 * COURSE_TYPE.SAMPLE) {
          onStreamUpdate("sample");
        } else if (item.id === -1 * COURSE_TYPE.PRIVATE) {
          onStreamUpdate("private");
        }
      } else if (item.type === "region") {
        onRegionUpdate({ id: item.id });
      }
      onClickItem(item);
    }
  };

  const { getInputProps, getItemProps, getMenuProps, selectedItem } =
    useCombobox<CourseNavigationItem>({
      isOpen: true,
      items: flattenedData,
      highlightedIndex,
      isItemDisabled: () => disabled,
      onSelectedItemChange(changes) {
        handleClickItem(changes.selectedItem);
      },
      onHighlightedIndexChange: (changes) =>
        onNavItemHighlightedIndexChanged<CourseNavigationItem>(
          changes,
          setHighlightedIndex,
          otherOptionsOpenId,
          otherVersionsOpenId
        ),
      onInputValueChange(changes) {
        setSearchTerm(changes.inputValue ?? "");
      },
      onIsOpenChange(changes) {
        if (!changes.isOpen) {
          closeMenu();
        }
      },
      stateReducer: comboboxStateReducerWithShallowCopy,
    });
  const selectedItemIndex = flattenedData.findIndex((item) => {
    switch (level) {
      case "regions":
        return item.id === selectedLevels.regions?.id;
      case "course_streams":
        return item.id === selectedLevels.course_streams?.id;
      case "courses":
        return item.id === selectedLevels.courses?.id;
      default:
        return false;
    }
  });

  const inputProps = getInputProps({ disabled, ref: inputRef });
  const originalOnKeyDown = inputProps.onKeyDown;
  inputProps.onKeyDown = undefined;
  const onKeyDown = createOnKeyDownHandler({
    subMenuOpen: !!otherOptionsOpenId || !!otherVersionsOpenId,
    closeSubMenu: () => {
      setOtherOptionsOpenId(null);
      setOtherVersionsOpenId(null);
    },
    closeMenu,
    onGoBack: onClickBack,
    getItemProps,
    totalItems: flattenedData.length,
    highlightedIndex,
    setHighlightedIndex,
    selectedItemIndex,
    originalOnKeyDown,
  });

  return (
    <>
      <div
        className={`nav-dropdown outline-0 ${
          disabled ? "pointer-events-none" : ""
        }`}
        aria-disabled={disabled}
        onKeyDownCapture={onKeyDown}
        tabIndex={-1}
      >
        <NavSearch {...inputProps} />
        <ul {...getMenuProps()}>
          <CourseNavigationList
            data={completeData}
            emptyListNode={<EmptyList data={data} level={level} />}
            backNode={
              back ? (
                <ListBack
                  back={back}
                  highlighted={highlightedIndex === 0}
                  {...getItemProps({
                    item: {
                      type: "back",
                      id: -1,
                      name: back,
                      displayName: back,
                    },
                    index: 0,
                  })}
                />
              ) : null
            }
            listItemNode={(item, index) => {
              const highlighted = highlightedIndex === index;

              return (
                <li className="relative">
                  <ListItem
                    displayName={item.displayName}
                    searchTerm={searchTerm}
                    highlighted={highlighted}
                    startDecorator={
                      item.id === alternateCourseId ? (
                        <Tooltip
                          title="Alternate Version of Default Stream Course"
                          size="sm"
                          contentProps={{ side: "top" }}
                        >
                          <SwitchIcon className="mr-2" width="14" height="14" />
                        </Tooltip>
                      ) : null
                    }
                    selected={
                      (item.type === "course" && item.id === courseId) ||
                      (item.type === "course_stream" &&
                        item.id === selectedLevels.course_streams?.id) ||
                      (item.type === "region" &&
                        item.id === selectedLevels.regions?.id)
                    }
                    {...getItemProps({ item, index })}
                  />
                  <NavItemActions
                    item={item}
                    level={level}
                    isHighlighted={highlighted}
                    alternateCourseId={alternateCourseId}
                    otherOptionsOpen={otherOptionsOpenId === item.id}
                    setOtherOptionsOpen={setOtherOptionsOpenId}
                    otherVersionsOpen={otherVersionsOpenId === item.id}
                    setOtherVersionsOpen={setOtherVersionsOpenId}
                    setAlternateCourse={handleSelectOtherCourseVersion}
                  />
                </li>
              );
            }}
          />
        </ul>
      </div>
      {disabled && <NavBackdrop />}
    </>
  );
};
