import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 27"
      width="27"
      fill="none"
      stroke="currentColor"
      ref={ref}
      {...props}
    >
      <circle strokeWidth={2} cx="13.5" cy="13.5" r="12.5" />
      <polygon points="13.5 20.2 6.7 18.5 6.7 7 13.5 8.6 13.5 20.2" />
      <polyline points="13.5 20.2 20.3 18.5 20.3 7 13.5 8.6 13.5 20.2" />
      <line x1="15" y1="11.2" x2="18.9" y2="10.2" />
      <line x1="15" y1="14.1" x2="18.9" y2="13.1" />
      <line x1="15" y1="16.9" x2="18.9" y2="16" />
      <line x1="12" y1="11.2" x2="8.1" y2="10.2" />
      <line x1="12" y1="14.1" x2="8.1" y2="13.1" />
      <line x1="12" y1="16.9" x2="8.1" y2="16" />
    </svg>
  );
};

export const SearchReadingIcon = forwardRef(SvgComponent);
