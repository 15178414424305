import { z } from "zod";

export const DEFAULT_ACC_SETTINGS = {
  accessibilityMode: false,
  highContrastMode: false,
  darkMode: false,
  textSize: 100,
  videoReadingPreference: "default",
} as const;

const VIDEO_READING_PREFERENCE_VALUES = [
  "default",
  "video",
  "reading",
] as const;

export type VideoReadingPreference =
  (typeof VIDEO_READING_PREFERENCE_VALUES)[number];

export const AccessibilitySettingsSchema = z.object({
  accessibilityMode: z
    .boolean()
    .default(DEFAULT_ACC_SETTINGS.accessibilityMode),
  highContrastMode: z.boolean().default(DEFAULT_ACC_SETTINGS.highContrastMode),
  darkMode: z.boolean().default(DEFAULT_ACC_SETTINGS.darkMode),
  textSize: z.number().default(DEFAULT_ACC_SETTINGS.textSize),
  videoReadingPreference: z
    .enum(VIDEO_READING_PREFERENCE_VALUES)
    .default(DEFAULT_ACC_SETTINGS.videoReadingPreference),
});

export type AccessibilitySettings = z.infer<typeof AccessibilitySettingsSchema>;
