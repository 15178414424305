import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => {
  const { fill } = props;
  
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 12" 
      width="30" 
      height="12" 
      fill={fill ?? "currentColor"}
      ref={ref}
      {...props}>
      <path d="M10.5 12C11.7783 12 12.4462 10.5221 11.6748 9.56682L11.5607 9.43998L8.12404 6.00249C10.0279 4.19828 13.0475 3.00225 16.5 3.00225C22.3774 3.00225 27 6.46832 27 10.5004C27 11.3286 27.6716 12 28.5 12C29.3284 12 30 11.3286 30 10.5004C30 4.59379 23.8774 0.00300627 16.5 0.00300627C12.29 0.00300627 8.48857 1.49804 6.00046 3.87947L2.56066 0.442236C1.6568 -0.4614 0.139229 0.111477 0.00897503 1.33223L0 1.50263V10.5004C0 11.2694 0.57906 11.9033 1.32507 11.9899L1.5 12H10.5Z" />
    </svg>
  );
};

const UndoArrowIcon = forwardRef(SvgComponent);
export { UndoArrowIcon };