import { createOnKeyDownHandler } from "$components/features/nav/NavBar/lib";
import { CourseNavigationList } from "$components/shared/CourseNavigation";
import {
  EmptyList,
  ListItem,
} from "$components/shared/CourseNavigation/CourseNavigationComponents";
import {
  CourseNavigationItem,
  useCourseNavigation,
} from "$hooks/useCourseNavigation";
import {
  addHrefOnNavigationItems,
  filterDataBySearchTerm,
  getFlatNavigationData,
} from "$lib/courseNavigation";
import { useCombobox } from "downshift";
import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { NavBackdrop, NavSearch } from "./NavCourseNavigationComponents";
import { NavItemActions } from "./NavItemActions";
import {
  comboboxStateReducerWithShallowCopy,
  onNavItemHighlightedIndexChanged,
} from "./lib";

export const ChapterLevelNavigation = ({
  courseId,
  selected,
  onChapterUpdate,
  disabled,
  closeMenu,
  onGoBack,
}: {
  courseId: number;
  selected?: { chapterId?: number; quizId?: number };
  onChapterUpdate: (item: CourseNavigationItem | null) => void;
  disabled: boolean;
  closeMenu: () => void;
  onGoBack: () => void;
}) => {
  const { data, level, selectedLevels } = useCourseNavigation({
    initial: { level: "chapters", courseId },
  });
  const [otherOptionsOpenId, setOtherOptionsOpenId] = useState<number | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState("");
  const completeData = useMemo(() => {
    const itemsWithHref = addHrefOnNavigationItems({ data, level });

    return filterDataBySearchTerm(itemsWithHref, searchTerm);
  }, [data, level, searchTerm]);
  const flattenedData = useMemo(
    () => getFlatNavigationData({ data: completeData }),
    [completeData]
  );
  const canEditCourse = (() => {
    if (selectedLevels.courses?.type === "course") {
      return selectedLevels.courses.canEdit;
    }
    return false;
  })();
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!disabled) inputRef.current?.focus();
  }, [disabled]);

  const handleChapterLevelClick = (
    item: CourseNavigationItem | null | undefined
  ) => {
    if (!item) return;

    if (item.type === "chapter") {
      onChapterUpdate(item);
    }
  };

  const { getInputProps, getItemProps, getMenuProps, selectedItem } =
    useCombobox<CourseNavigationItem>({
      isOpen: true,
      items: flattenedData,
      highlightedIndex,
      isItemDisabled: () => disabled,
      onSelectedItemChange(changes) {
        handleChapterLevelClick(changes.selectedItem);
      },
      onInputValueChange(changes) {
        setSearchTerm(changes.inputValue ?? "");
      },
      onHighlightedIndexChange: (changes) => {
        onNavItemHighlightedIndexChanged<CourseNavigationItem>(
          changes,
          setHighlightedIndex,
          otherOptionsOpenId
        );
      },
      onIsOpenChange(changes) {
        if (!changes.isOpen) {
          closeMenu();
        }
      },
      stateReducer: comboboxStateReducerWithShallowCopy,
    });
  const selectedItemIndex = flattenedData.findIndex(
    (item) => item.type === "chapter" && item.id === selected?.chapterId
  );

  const inputProps = getInputProps({ disabled, ref: inputRef });
  const originalOnKeyDown = inputProps.onKeyDown;
  inputProps.onKeyDown = undefined;
  const onKeyDown = createOnKeyDownHandler({
    subMenuOpen: !!otherOptionsOpenId,
    closeSubMenu: () => setOtherOptionsOpenId(null),
    closeMenu,
    onGoBack,
    getItemProps,
    totalItems: flattenedData.length,
    highlightedIndex,
    setHighlightedIndex,
    selectedItemIndex,
    originalOnKeyDown,
  });

  return (
    <>
      <div
        className={`nav-dropdown outline-0 ${
          disabled ? "pointer-events-none" : ""
        }`}
        aria-disabled={disabled}
        onKeyDownCapture={onKeyDown}
        tabIndex={-1}
      >
        <NavSearch {...inputProps} />
        <ul {...getMenuProps()}>
          <CourseNavigationList
            data={completeData}
            emptyListNode={<EmptyList data={data} level={level} />}
            listItemNode={(item, index) => {
              const highlighted = highlightedIndex === index;

              return (
                <li className="relative">
                  <ListItem
                    highlighted={highlighted}
                    displayName={item.displayName}
                    searchTerm={searchTerm}
                    href={(item as any).href}
                    selected={
                      (item.type === "chapter" &&
                        item.id === selected?.chapterId) ||
                      (item.type === "quiz" && item.id === selected?.quizId)
                    }
                    {...getItemProps({ item, index })}
                  />
                  <NavItemActions
                    item={item}
                    level={level}
                    isHighlighted={highlighted}
                    otherOptionsOpen={otherOptionsOpenId === item.id}
                    setOtherOptionsOpen={setOtherOptionsOpenId}
                    canEditCourse={canEditCourse}
                  />
                </li>
              );
            }}
          />
        </ul>
      </div>
      {disabled && <NavBackdrop chapterLevel />}
    </>
  );
};
