import { RouterOutputs, api } from "$lib/api";
import { COURSE_TYPE } from "$lib/course";
import { Assessment, Chapter, Course, Lesson, Project } from "@prisma/client";
import { firstBy } from "thenby";
import { z } from "zod";
import { STALE_TIME, getFindCourseArguments } from "./dataHooks";
import { CourseNavigationData, CourseNavigationItem } from "./index";

export type Level =
  | "search"
  | "regions"
  | "course_streams"
  | "sample_courses"
  | "private_courses"
  | "courses"
  | "chapters"
  | "lessons";
export type SelectedLevels = Record<
  Level,
  DistributiveOmit<CourseNavigationItem, "displayTitle"> | null
>;
export type StreamCourse = Course & { lft: number; rgt: number };
export type Initial =
  | {
      level: "course_streams" | "private_courses" | "sample_courses";
      regionId: number;
    }
  | { level: "courses"; courseId: number }
  | { level: "chapters"; courseId: number }
  | { level: "lessons"; chapterId: number };

export const emptySelectedLevels: SelectedLevels = {
  search: null,
  regions: null,
  course_streams: null,
  sample_courses: null,
  private_courses: null,
  courses: null,
  chapters: null,
  lessons: null,
};

export function buildCoursesList(
  {
    courses,
    equivalencies,
    editableCourseIds,
  }: RouterOutputs["course"]["findAll"],
  streamCourseId: number,
  canViewLTILinks?: boolean
): CourseNavigationData | null {
  const allStreamCourses = courses.filter(isStreamCourse).map((course) => {
    const ancestor = getAncestors(course, courses)[0];
    const stream_end = course.course_stream_name || course.name;
    const stream_start =
      ancestor?.course_stream_name === stream_end
        ? undefined
        : ancestor?.course_stream_name || undefined;
    return { ...course, stream_start, stream_end };
  });

  const streamCourse = allStreamCourses.find(
    (course) => course.id === streamCourseId
  );
  if (!streamCourse) return null;

  const similarStreamCourses = allStreamCourses.filter((course) => {
    return (
      course.id !== streamCourse.id &&
      course.stream_start === streamCourse.stream_start &&
      course.stream_end === streamCourse.stream_end
    );
  });

  const streamCourses = [
    ...getAncestors(streamCourse, courses, true),
    ...similarStreamCourses.flatMap((c) => getAncestors(c, courses, true)),
  ].filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  const stream = streamCourses
    .flatMap((course) => [
      {
        ...course,
        stream_lft: course.lft,
        stream_equivalent_id: null,
      },
      ...courses
        .filter((c) => equivalencies[course.id]?.includes(c.id))
        .map((c) => ({
          ...c,
          stream_lft: course.lft,
          stream_equivalent_id: course.id,
        })),
    ])
    .sort(
      firstBy("stream_lft", -1)
        .thenBy((c: Course) => (c.rgt ? -1 : 1))
        .thenBy("name")
    )
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  const baseCourses = stream.filter((c) => {
    return !c.stream_equivalent_id && c.region_id === streamCourse.region_id;
  });
  const equivalentCourses = stream.filter((c) => {
    return !isStreamCourse(c) || c.region_id !== streamCourse.region_id;
  });

  return baseCourses.reduce<CourseNavigationData>((acc, item, index, arr) => {
    function getStreamName(course: Course) {
      return course.course_stream_name || course.name;
    }

    const prevStreamName = index > 0 ? getStreamName(arr[index - 1]) : null;
    const streamName = getStreamName(item);
    const equivalents = equivalentCourses.filter(
      (eq) => (eq as any).stream_equivalent_id === item.id
    );

    const listItem = {
      type: "course",
      id: item.id,
      name: item.name,
      displayName: item.name,
      subTitle:
        item.course_type === 6 ? "Partner Course from StudyForge" : undefined,
      subItems: equivalents.map((e) => ({
        type: "course",
        id: e.id,
        name: e.name,
        displayName: e.name,
        canEdit: editableCourseIds.includes(e.id),
        canViewLTILinks: !!canViewLTILinks,
        region_id: e.region_id,
        course_type: e.course_type,
      })),
      canEdit: editableCourseIds.includes(item.id),
      canViewLTILinks: !!canViewLTILinks,
    } satisfies CourseNavigationItem;
    if (streamName !== prevStreamName) {
      acc.push({
        title: streamName,
        list: [listItem],
      });
    } else {
      acc[acc.length - 1].list.push(listItem);
    }

    return acc;
  }, []);
}

export function buildCourseStreamsList(
  courses: Course[],
  options: {
    hasSampleCourses: boolean;
    hasPrivateCourses: boolean;
  },
  regionId: number | null
): CourseNavigationData {
  const streamCourses = getLeafStreamCourses(courses, regionId);
  const courseStreams = streamCourses
    .map((course) => {
      const ancestor = getAncestors(course, courses)[0];
      const stream_end = course.course_stream_name || course.name;
      const stream_start =
        ancestor?.course_stream_name === stream_end
          ? undefined
          : ancestor?.course_stream_name || undefined;
      return { ...course, stream_start, stream_end };
    })
    .filter(
      (v, i, a) =>
        a.findIndex(
          (t) =>
            t.id === v.id ||
            (t.stream_start === v.stream_start && t.stream_end === v.stream_end)
        ) === i
    );
  const courseStreamsList = courseStreams
    .map(MapHelper.courseStreamToListItem)
    .sort(firstBy("displayName"));
  const sampleAndPrivateCourses: Extract<
    CourseNavigationItem,
    { type: "course_stream" }
  >[] = [
    {
      id: -1 * COURSE_TYPE.SAMPLE,
      type: "course_stream",
      name: "Sample Courses",
      displayName: "Sample Courses",
      courseType: "sample",
      empty: !options.hasSampleCourses,
    },
    {
      id: -1 * COURSE_TYPE.PRIVATE,
      type: "course_stream",
      name: "Private Courses",
      displayName: "Private Courses",
      courseType: "private",
      empty: !options.hasPrivateCourses,
    },
  ];

  return [
    {
      title: "Course Streams",
      list: [...courseStreamsList, ...sampleAndPrivateCourses],
    },
  ];
}

export const bySortOrder = firstBy(
  (item: Chapter | Lesson | Assessment | Project) => {
    return "sort_order" in item ? item.sort_order : item.number;
  }
);

/**
 * Returns true if the provided item is an assessment
 */
export function checkIsAssessment(item: unknown): item is Assessment {
  return typeof item === "object" && item !== null && "sort_order" in item;
}

export function isStreamCourse(
  course: Course | undefined
): course is StreamCourse {
  return !!course?.lft && !!course?.rgt;
}

function getAncestors(
  course: StreamCourse,
  allCourses: Course[],
  inclusive?: boolean
) {
  const courses = allCourses
    .filter(isStreamCourse)
    .filter((c) => c.lft < course.lft && c.rgt > course.rgt);
  if (inclusive) {
    courses.push(course);
  }
  return courses.sort(firstBy("lft"));
}

export function getDescendants(
  course: StreamCourse,
  allCourses: Course[],
  inclusive?: boolean
) {
  const courses = inclusive ? [course] : [];
  if (course.rgt - course.lft === 1) return courses;
  courses.push(
    ...allCourses
      .filter(isStreamCourse)
      .filter((c) => c.lft > course.lft && c.rgt < course.rgt)
  );
  return courses.sort(firstBy("lft", -1));
}

export function getLeafStreamCourses(
  courses: Course[],
  regionId: number | null
): StreamCourse[] {
  const streamCourses = courses.filter(isStreamCourse);
  return streamCourses.filter(
    (c) =>
      getDescendants(c, streamCourses).length === 0 && c.region_id === regionId
  );
}

export function getPreviousLevel(level: Level): Level | null {
  switch (level) {
    case "course_streams":
      return "regions";

    case "private_courses":
    case "sample_courses":
    case "courses":
      return "course_streams";

    case "chapters":
      return "courses";

    case "lessons":
      return "chapters";

    default:
      return null;
  }
}

export function getNextLevel(level: Level, item: CourseNavigationItem): Level {
  switch (level) {
    case "search": {
      if (item.type !== "search") return level;

      if (item.resultType === "course") {
        return "chapters";
      } else if (item.resultType === "chapter") {
        return "lessons";
      } else {
        return level;
      }
    }

    case "regions":
      return "course_streams";

    case "course_streams": {
      if (item.type === "course_stream" && item.courseType === "sample") {
        return "sample_courses";
      } else if (
        item.type === "course_stream" &&
        item.courseType === "private"
      ) {
        return "private_courses";
      } else {
        return "courses";
      }
    }

    case "courses":
      return "chapters";

    case "chapters":
      return "lessons";

    default:
      return level;
  }
}

export async function getInitialCourseStreamsLevels(
  trpc: ReturnType<typeof api.useUtils>,
  regionId: number
): Promise<SelectedLevels | undefined> {
  const regions = await trpc.region.all.fetch(undefined, {
    staleTime: STALE_TIME,
  });
  const region = regions.find((r) => r.id === regionId);

  if (region) {
    return {
      ...emptySelectedLevels,
      regions: {
        type: "region",
        id: region.id,
        name: region.name,
        displayName: region.name,
      },
    };
  }
}

export async function getInitialCourseLevels(
  trpc: ReturnType<typeof api.useUtils>,
  courseId: number
): Promise<SelectedLevels | undefined> {
  let streamCourse: Course | undefined;
  let equivalentCourse: Course | undefined;
  const coursePromise = trpc.course.find.fetch(
    getFindCourseArguments(courseId),
    { staleTime: STALE_TIME }
  );
  const regionsPromise = trpc.region.all.fetch(undefined, {
    staleTime: STALE_TIME,
  });
  const [course, regions] = await Promise.all([coursePromise, regionsPromise]);
  const regionId = course.region_id;
  const region = regions.find((r) => r.id === regionId);
  if (
    course.course_type === COURSE_TYPE.SAMPLE ||
    course.course_type === COURSE_TYPE.PRIVATE
  ) {
    streamCourse = course;
  } else {
    const regionCourses = regionId
      ? await trpc.course.findAll.fetch(
          { regionId, onlyCoursesUserCanEdit: false },
          { staleTime: STALE_TIME }
        )
      : undefined;
    const equivalentStreamCourses = isStreamCourse(course)
      ? [course]
      : regionCourses?.equivalencies[course.id]
        ? regionCourses.courses.filter((c): c is StreamCourse => {
            return (
              regionCourses.equivalencies[course.id].includes(c.id) &&
              isStreamCourse(c)
            );
          })
        : [];
    const leafRegionCourses = regionCourses
      ? getLeafStreamCourses(regionCourses.courses, regionId).sort(
          firstBy("lft")
        )
      : [];
    outer: for (const leafCourse of leafRegionCourses) {
      for (const eq of equivalentStreamCourses) {
        if (
          eq.id === leafCourse.id ||
          (eq.lft < leafCourse.lft && eq.rgt > leafCourse.rgt)
        ) {
          streamCourse = leafCourse;
          equivalentCourse = eq;
          break outer;
        }
      }
    }
  }

  if (!region) {
    return emptySelectedLevels;
  }

  if (!streamCourse) {
    return {
      ...emptySelectedLevels,
      regions: {
        type: "region",
        id: region.id,
        name: region.name,
        displayName: region.name,
      },
    };
  }

  const course_streams: SelectedLevels["course_streams"] =
    streamCourse.course_type === COURSE_TYPE.SAMPLE
      ? {
          type: "course_stream",
          courseType: "sample",
          id: -1 * COURSE_TYPE.SAMPLE,
          name: "Sample Courses",
          displayName: "Sample Courses",
          empty: false,
        }
      : streamCourse.course_type === COURSE_TYPE.PRIVATE
        ? {
            type: "course_stream",
            courseType: "private",
            id: -1 * COURSE_TYPE.PRIVATE,
            name: "Private Courses",
            displayName: "Private Courses",
            empty: false,
          }
        : {
            type: "course_stream",
            courseType: "default",
            id: streamCourse.id,
            name: streamCourse.name,
            displayName: streamCourse.name,
          };

  return {
    ...emptySelectedLevels,
    regions: {
      type: "region",
      id: region.id,
      name: region.name,
      displayName: region.name,
    },
    course_streams,
    courses: {
      type: "course",
      id: course.id,
      name: course.name,
      displayName: course.name,
      canEdit: false,
      streamEquivalentId: equivalentCourse?.id ?? null,
    },
  };
}

export async function getInitialChapterLevels(
  trpc: ReturnType<typeof api.useUtils>,
  courseId: number,
  onlyCoursesUserCanEdit: boolean
): Promise<SelectedLevels | undefined> {
  const coursePromise = trpc.course.find.fetch(
    getFindCourseArguments(courseId, { checkCanEdit: true }),
    { staleTime: STALE_TIME }
  );

  const regionsPromise = trpc.region.all.fetch(undefined, {
    staleTime: STALE_TIME,
  });
  const [course, regions] = await Promise.all([coursePromise, regionsPromise]);
  const regionId = course.region_id;
  const region = regions.find((r) => r.id === regionId);
  if (!region) return;

  const coursesList = await trpc.course.findAll.fetch(
    { regionId: region.id, onlyCoursesUserCanEdit },
    { staleTime: STALE_TIME }
  );

  const regionsLevel: SelectedLevels["regions"] = {
    type: "region",
    id: region.id,
    name: region.name,
    displayName: region.name,
  };
  const coursesLevel: SelectedLevels["courses"] = {
    type: "course",
    id: course.id,
    name: course.name,
    displayName: course.name,
    canEdit: !!course.canEdit,
  };

  if (!isStreamCourse(course)) {
    // If course is not a stream course, it is either a Sample course or a Private course
    if (course.course_type === COURSE_TYPE.SAMPLE) {
      return {
        ...emptySelectedLevels,
        regions: regionsLevel,
        courses: coursesLevel,
        sample_courses: {
          type: "course_stream",
          courseType: "sample",
          id: -1 * COURSE_TYPE.SAMPLE,
          name: "Sample Courses",
          displayName: "Sample Courses",
          empty: false,
        },
      };
    }
    if (course.course_type === COURSE_TYPE.PRIVATE) {
      return {
        ...emptySelectedLevels,
        regions: regionsLevel,
        courses: coursesLevel,
        private_courses: {
          type: "course_stream",
          courseType: "private",
          id: -1 * COURSE_TYPE.PRIVATE,
          name: "Private Courses",
          displayName: "Private Courses",
          empty: false,
        },
      };
    }

    return {
      ...emptySelectedLevels,
      regions: regionsLevel,
      courses: coursesLevel,
    };
  }

  const streamCourse = getLeafStreamCourses(
    getDescendants(course, coursesList.courses, true),
    regionId
  )[0];
  if (!streamCourse) return;

  return {
    ...emptySelectedLevels,
    regions: regionsLevel,
    courses: coursesLevel,
    course_streams: {
      type: "course_stream",
      courseType: "default",
      id: streamCourse.id,
      name: streamCourse.name,
      displayName: streamCourse.name,
    },
  };
}
export async function getInitialLessonLevels(
  trpc: ReturnType<typeof api.useUtils>,
  chapterId: number,
  onlyCoursesUserCanEdit: boolean
): Promise<SelectedLevels | undefined> {
  const chapter = await trpc.chapter.find.fetch(
    { chapterId },
    { staleTime: STALE_TIME }
  );
  if (!chapter.course_id) return;

  const initialChapterLevels = await getInitialChapterLevels(
    trpc,
    chapter.course_id,
    onlyCoursesUserCanEdit
  );
  if (!initialChapterLevels) return;

  return {
    ...initialChapterLevels,
    chapters: {
      type: "chapter",
      courseId: chapter.course_id,
      id: chapter.id,
      name: chapter.name,
      displayName: chapter.name,
      canEdit: false,
    },
  };
}

export const MapHelper = {
  searchResultsToListItem(
    result: RouterOutputs["search"]["searchCourses"][number]
  ): Extract<CourseNavigationItem, { type: "search" }> {
    return {
      type: "search",
      id: result.id,
      name: result.name,
      displayName: result.name,
      breadcrumbs: result.breadcrumbs,
      resultType:
        result.type === "Course"
          ? "course"
          : result.type === "Chapter"
            ? "chapter"
            : "quiz",
    };
  },

  projectToListItem(
    project: Project,
    canViewLTILinks?: boolean
  ): Extract<CourseNavigationItem, { type: "project" }> {
    return {
      type: "project",
      id: project.id,
      name: project.name,
      displayName: project.name,
      canViewLTILinks: !!canViewLTILinks,
    };
  },

  courseStreamToListItem(
    stream: Course & { stream_start: string | undefined; stream_end: string }
  ): Extract<
    CourseNavigationItem,
    { type: "course_stream"; courseType: "default" }
  > {
    const displayName =
      stream.stream_start && stream.stream_start !== stream.stream_end
        ? [stream.stream_start, stream.stream_end]
        : stream.stream_end;

    return {
      type: "course_stream",
      id: stream.id,
      name: stream.name,
      displayName,
      courseType: "default",
    };
  },

  courseToListItem(
    course: Course,
    canEdit?: boolean,
    canViewLTILinks?: boolean
  ): Extract<CourseNavigationItem, { type: "course" }> {
    return {
      type: "course",
      id: course.id,
      name: course.name,
      displayName: course.name,
      canEdit: !!canEdit,
      canViewLTILinks: !!canViewLTILinks,
    };
  },

  assessmentToListItem(
    quiz: Assessment,
    selectedLevels: SelectedLevels,
    courseEditable: boolean,
    canViewLTILinks?: boolean
  ): Extract<CourseNavigationItem, { type: "quiz" }> {
    const breadcrumbs = z
      .object({
        region: z
          .object({
            id: z.number(),
            name: z.string(),
          })
          .catch({ id: -1, name: "" }),
        course: z
          .object({
            id: z.number(),
            name: z.string(),
          })
          .catch({ id: -2, name: "" }),
        chapter: z
          .object({
            id: z.number(),
            name: z.string(),
          })
          .nullable()
          .transform((arg) => arg ?? undefined),
      })
      .parse({
        region: selectedLevels.regions,
        course: selectedLevels.courses,
        chapter: selectedLevels.chapters,
      });

    return {
      type: "quiz",
      id: quiz.id,
      name: quiz.name,
      displayName: `Quiz - ${quiz.name}`,
      breadcrumbs,
      courseId: quiz.course_id,
      chapterId: quiz.chapter_id,
      canEdit: courseEditable,
      canViewLTILinks: !!canViewLTILinks,
    };
  },
};
