import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import cn from "classnames";
import { ReactNode } from "react";

export const NavItemDropdownMenu = ({
  visible,
  open,
  toggleOpen,
  triggerNode,
  itemsNode,
}: {
  visible: boolean;
  open: boolean;
  toggleOpen: () => void;
  triggerNode: ReactNode;
  itemsNode: ReactNode[];
}) => {
  return (
    <div
      className={cn(
        "nav-item-actions absolute top-0 bottom-0 right-0 flex items-center justify-center font-normal text-carbon-500",
        visible ? "visible" : "invisible"
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <DropdownMenu.Root open={open}>
        <DropdownMenu.Trigger asChild>{triggerNode}</DropdownMenu.Trigger>
        <DropdownMenu.Content
          align="start"
          loop
          className={cn(
            "flex min-w-[80px] flex-col whitespace-nowrap bg-white text-black shadow",
            !open && "hidden"
          )}
          onInteractOutside={toggleOpen}
          onEscapeKeyDown={toggleOpen}
        >
          {itemsNode.map((itemNode, index) => (
            <DropdownMenu.Item key={index} asChild>
              {itemNode}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
};
