import { useAppStore } from "$hooks/useAppStore";
import { useViewingAs } from "$hooks/useUserSelection/useViewingAs";
import { RouterInputs, api } from "$lib/api";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import type { UserSelection } from "./useUserSelection";

export function useInitialSetup(
  highestAccessLevel: UserSelection["highestAccessLevel"],
  viewingAs: ReturnType<typeof useViewingAs>,
  setSelectedLevels: Dispatch<SetStateAction<UserSelection["selectedLevels"]>>,
  setQueryInput: Dispatch<
    SetStateAction<RouterInputs["navbar"]["loadViewAsContent"]>
  >,
  setLevel: Dispatch<SetStateAction<UserSelection["level"]>>
) {
  const [loaded, setLoaded] = useState(false);
  const viewAsScopes = useAppStore((state) => state.viewAsScopes);
  const hasUsersScope = viewAsScopes.includes("users");
  const group = useAppStore((state) => state.viewAsGroup);

  // If these dependencies change, we need to load the data again
  // The getGroup request is cached
  useEffect(() => {
    setLoaded(false);
  }, [viewAsScopes, viewingAs, highestAccessLevel]);

  if (
    loaded ||
    !viewingAs ||
    !group ||
    !group.institution ||
    !group.institution.partner
  ) {
    return;
  }

  setLoaded(true);

  setSelectedLevels({
    institution: {
      id: group.institution.id,
      name: group.institution.name,
    },
    partner: {
      id: group.institution.partner.id,
      name: group.institution.partner.name,
    },
    group: hasUsersScope
      ? {
          id: group.id,
          name: group.name,
          course_id: group.course_id,
        }
      : undefined,
  });

  const level = hasUsersScope ? "students" : "groups";
  setLevel(level);
  if (highestAccessLevel !== "groups" || level === "students") {
    setQueryInput(
      hasUsersScope
        ? { groupId: group.id }
        : { institutionId: group.institution.id }
    );
  }
}
