import { Ref, SVGProps, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g filter="url(#filter0_f_5061_875)">
      <circle cx="12" cy="12" r="8" fill="#ED1A26" />
    </g>
    <circle
      cx="12"
      cy="12"
      r="5.75"
      fill="#ED1A26"
      stroke="white"
      strokeWidth="0.5"
    />
    <defs>
      <filter
        id="filter0_f_5061_875"
        x="0"
        y="0"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_5061_875"
        />
      </filter>
    </defs>
  </svg>
);

const RedDotIcon = forwardRef(SvgComponent);
export { RedDotIcon };
