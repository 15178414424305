import { createOnKeyDownHandler } from "$components/features/nav/NavBar/lib";
import { ListItem } from "$components/shared/CourseNavigation/CourseNavigationComponents";
import { HandshakeIcon } from "$components/shared/Icons/HandshakeIcon";
import { InstitutionIcon } from "$components/shared/Icons/InstitutionIcon";
import { UserIcon } from "$components/shared/Icons/UserIcon";
import { UsersThreeIcon } from "$components/shared/Icons/UsersThreeIcon";
import { XIcon } from "$components/shared/Icons/XIcon";
import { useAppStore } from "$hooks/useAppStore";
import { UserSelection, getPreviousLevel } from "$hooks/useUserSelection";
import { upperFirst } from "$lib/string";
import { getRootSF3URL } from "$utils/appUtil";
import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { ReactNode } from "react";
import { NavSearch } from "../NavCourseNavigation/NavCourseNavigationComponents";
import { NavItemDropdownMenu } from "../NavItemDropdownMenu";
import { NavLink } from "../NavLink";
import { OtherOptionsButton } from "../OtherOptionsButton";
import { useViewAsExtraItems } from "./useViewAsExtraItems";
import { useViewAsNavigation } from "./useViewAsNavigation";

export function ViewAsDropdown({
  viewingAs,
  userSelection,
  searchTerm,
  setSearchTerm,
  closeMenu,
}: {
  viewingAs: { id: number; name: string; group: boolean } | null;
  userSelection: UserSelection;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  closeMenu: () => void;
}) {
  const currentUser = useAppStore((state) => state.currentUser);
  const back = (() => {
    const previousLevel = getPreviousLevel(
      userSelection.level,
      userSelection.higherLevel
    );
    if (!previousLevel) return null;

    return `Back to ${upperFirst(previousLevel)}`;
  })();
  const extraItems = useViewAsExtraItems({
    level: userSelection.level,
    back,
    viewingAs,
  });
  const {
    viewAsData,
    level,
    selectedLevels,
    totalItems,
    highlightedIndex,
    setHighlightedIndex,
    otherOptionsOpenId,
    setOtherOptionsOpenId,
    getMenuProps,
    getInputProps,
    getItemProps,
    selectedItemIndex,
  } = useViewAsNavigation({
    extraItems,
    userSelection,
    searchTerm,
    setSearchTerm,
    closeMenu,
  });

  const startIndex = (() => {
    return Object.values(extraItems)
      .map((obj) => (obj.show ? 1 : 0))
      .reduce<number>((sum, cur) => sum + cur, 0);
  })();

  const inputProps = getInputProps();
  const originalOnKeyDown = inputProps.onKeyDown;
  inputProps.onKeyDown = undefined;
  const onKeyDown = createOnKeyDownHandler({
    subMenuOpen: !!otherOptionsOpenId,
    closeSubMenu: () => setOtherOptionsOpenId(null),
    getItemProps,
    originalOnKeyDown,
    closeMenu,
    totalItems,
    highlightedIndex,
    setHighlightedIndex,
    selectedItemIndex,
    onGoBack: userSelection.onBack,
  });

  return (
    <div className="outline-none" tabIndex={-1} onKeyDownCapture={onKeyDown}>
      <NavSearch {...inputProps} />

      <ul {...getMenuProps()}>
        {extraItems.BACK.show && (
          <ListItem
            highlighted={highlightedIndex === extraItems.BACK.index}
            startDecorator={
              <Icon className="mr-2 h-6 w-6" path={mdiChevronLeft} />
            }
            {...getItemProps({
              item: extraItems.BACK.item,
              index: extraItems.BACK.index,
            })}
          >
            <p>{back}</p>
          </ListItem>
        )}
        {extraItems.STOP_VIEWING_AS.show && (
          <ListItem
            highlighted={highlightedIndex === extraItems.STOP_VIEWING_AS.index}
            startDecorator={
              <XIcon weight="bold" className="ml-0.5 mr-2.5 h-5 w-5" />
            }
            {...getItemProps({
              item: extraItems.STOP_VIEWING_AS.item,
              index: extraItems.STOP_VIEWING_AS.index,
            })}
          >
            <p>
              {extraItems.STOP_VIEWING_AS.item.name}
              {viewingAs?.group === false && viewingAs?.id === currentUser.id
                ? " yourself"
                : ` ${viewingAs!.name}`}
            </p>
          </ListItem>
        )}
        {extraItems.VIEW_AS_ENTIRE_GROUP.show && (
          <ListItem
            highlighted={
              highlightedIndex === extraItems.VIEW_AS_ENTIRE_GROUP.index
            }
            startDecorator={
              <UsersThreeIcon className="ml-0.5 mr-2.5 h-5 w-5" />
            }
            {...getItemProps({
              item: extraItems.VIEW_AS_ENTIRE_GROUP.item,
              index: extraItems.VIEW_AS_ENTIRE_GROUP.index,
            })}
          >
            {viewingAs?.group && viewingAs?.id === selectedLevels.group?.id ? (
              <span className="font-bold">Viewing As Entire Group</span>
            ) : (
              extraItems.VIEW_AS_ENTIRE_GROUP.item.name
            )}
          </ListItem>
        )}
        {extraItems.VIEW_AS_MYSELF.show && (
          <ListItem
            highlighted={highlightedIndex === extraItems.VIEW_AS_MYSELF.index}
            startDecorator={<UserIcon className="ml-0.5 mr-2.5 h-5 w-5" />}
            {...getItemProps({
              item: extraItems.VIEW_AS_MYSELF.item,
              index: extraItems.VIEW_AS_MYSELF.index,
            })}
          >
            {extraItems.VIEW_AS_MYSELF.item.name}
          </ListItem>
        )}
        {startIndex > 0 && (
          <div className="mx-7 my-2.5 border-b border-b-carbon-400" />
        )}
        {
          viewAsData?.reduce(
            (results, section, index) => {
              if (
                !Array.isArray(results.sections) ||
                section.list.length === 0
              ) {
                return results;
              }

              results.sections.push(
                <div key={section.title + index}>
                  <Title
                    level={level}
                    selectedLevels={selectedLevels}
                    title={section.title}
                  />

                  {section.list.map((item) => {
                    const resultIndex = results.itemIndex++;
                    const highlighted = resultIndex === highlightedIndex;
                    const toggleOpen = () =>
                      setOtherOptionsOpenId((prev) => (prev ? null : item.id));

                    return (
                      <ListItem
                        key={item.id}
                        highlighted={resultIndex === highlightedIndex}
                        displayName={item.name}
                        searchTerm={searchTerm}
                        {...getItemProps({ item, index: resultIndex })}
                        selected={selectedItemIndex === resultIndex}
                      >
                        {level === "groups" && (
                          <NavItemDropdownMenu
                            visible={highlighted}
                            open={otherOptionsOpenId === item.id}
                            toggleOpen={toggleOpen}
                            triggerNode={
                              <OtherOptionsButton onClick={toggleOpen} />
                            }
                            itemsNode={[
                              <NavLink
                                key="settings"
                                href={`${getRootSF3URL()}/manage/group/${
                                  item.id
                                }`}
                                className="py-1.5 pl-4 pr-6 text-left focus-within:bg-carbon-100 hover:bg-carbon-100"
                              >
                                Settings
                              </NavLink>,
                            ]}
                          />
                        )}
                        {level === "students" && (
                          <NavItemDropdownMenu
                            visible={highlighted}
                            open={otherOptionsOpenId === item.id}
                            toggleOpen={toggleOpen}
                            triggerNode={
                              <OtherOptionsButton onClick={toggleOpen} />
                            }
                            itemsNode={[
                              <NavLink
                                key="account-settings"
                                href={`${getRootSF3URL()}/manage/user/${
                                  item.id
                                }/profile`}
                                className="py-1.5 pl-4 pr-6 text-left focus-within:bg-carbon-100 hover:bg-carbon-100"
                              >
                                Account Settings
                              </NavLink>,
                              <NavLink
                                key="manage-enrolment"
                                href={`${getRootSF3URL()}/manage/user/${
                                  item.id
                                }/enrolment`}
                                className="py-1.5 pl-4 pr-6 text-left focus-within:bg-carbon-100 hover:bg-carbon-100"
                              >
                                Manage Enrolment
                              </NavLink>,
                            ]}
                          />
                        )}
                      </ListItem>
                    );
                  })}
                </div>
              );

              return results;
            },
            {
              sections: [] as ReactNode,
              itemIndex: startIndex,
            }
          ).sections
        }
      </ul>
    </div>
  );
}

function Title({
  level,
  selectedLevels,
  title,
}: Pick<UserSelection, "level" | "selectedLevels"> & { title: string }) {
  if (!level) {
    return null;
  }

  let titleComplement = getTitleCompliment(level, selectedLevels);

  const LabelWithIcon = () => {
    if (title === "Partner" || level === "partners") {
      return (
        <>
          <HandshakeIcon className="mr-2" width="22" />
          {"Partners"}
        </>
      );
    } else if (title === "Institution" || level === "institutions") {
      return (
        <>
          <InstitutionIcon className="mr-2" width={18} />
          {"Institutions"}
        </>
      );
    } else if (title === "Group" || level === "groups") {
      return (
        <>
          <UsersThreeIcon className="mr-2" width="22" />
          {"Groups"}
        </>
      );
    } else if (title === "User" || level === "students") {
      return (
        <>
          <UserIcon className="mr-2 h-5 w-5" weight="fill" />
          {"Students"}
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <h3 className="mb-2 mt-2.5 flex items-center px-8 text-base font-semibold text-carbon-900">
      <LabelWithIcon />
      {titleComplement ? ` of ${titleComplement}` : ""}
    </h3>
  );
}

function getTitleCompliment(
  level: UserSelection["level"],
  selectedLevels: UserSelection["selectedLevels"]
) {
  switch (level) {
    case "institutions":
      return selectedLevels.partner?.name ?? "";

    case "groups":
      return selectedLevels.institution?.name ?? "";

    case "students":
      return selectedLevels.group?.name ?? "";

    default:
      return "";
  }
}
